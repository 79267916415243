import React from 'react';
import { Dialog as KendoDialog } from '@progress/kendo-react-dialogs';
import './Dialog.scss';
import Button from '../../forms/controls/button/Button';

export type DialogProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    content: React.ReactNode;
    actions: DialogAction[];
    dialogType: DialogType;
}

export type DialogType = 'notice' | 'confirm' | 'important';

export type DialogAction = {
    identifier: string;
    onClick?: () => void;
    primary?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
    isOpen,
    onClose,
    title,
    content,
    actions,
    dialogType
}) => {
    return (
        <KendoDialog
            title={title}
            onClose={onClose}
            className={`app-dialog ${dialogType}`}
        >
            <div className="dialog-content">{content}</div>
            <div className="dialog-actions">
                {actions.map((action, index) => (
                    <Button
                        color={action.primary ? 'primary' : 'secondary'}
                        label=""
                        key={index}
                        onClick={() => action.onClick && action.onClick()}
                        className={action.primary ? 'primary-action' : 'secondary-action'}
                    >
                        {action.identifier}
                    </Button>
                ))}
            </div>
        </KendoDialog>
    );
};

export default Dialog;