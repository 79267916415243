import { showNotification } from "../../../common/components/notifications/NotificationHost";
import { getIn } from "../../../common/forms/validation/na-va-form/commonUtils";
import { UseCrudApiResult } from "../../../common/hooks/useCrudApi";
import { FIELD_EXTENSIONS, FIELD_ID } from "../../../data/field-constants/GeneralConstants";
import { NEW_ENTITY_ID } from "../../../util/EntityUtils";

export async function saveItem<T, TApi>(
    item?: T,
    spreadProperties?: {},
    api?: TApi,
    create?: UseCrudApiResult<T, TApi>[2],
    update?: UseCrudApiResult<T, TApi>[4],
): Promise<boolean> {
    try {
        if (item && api) {

            if (getIn(item, FIELD_ID) === NEW_ENTITY_ID) {
                create && await create(api, {
                    ...item,
                    ...spreadProperties ?? {},
                    id: undefined,
                    extensionData: JSON.stringify(getIn(item, FIELD_EXTENSIONS))
                });
            } else {
                update && await update(api, {
                    ...item,
                    extensionData: JSON.stringify(getIn(item, FIELD_EXTENSIONS))
                });
            }

            showNotification('Speichern erfolgreich', 'Datensatz erfolgreich aktualisiert', 'success', 3000, true, 'bottomLeft');
            return true;
        }

        return false;

    } catch (error) {
        showNotification('Speichern fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true, 'bottomLeft');
        return false;
    }
}

export async function deleteItem<T, TApi>(
    item?: T,
    api?: TApi,
    delete_?: UseCrudApiResult<T, TApi>[5]
): Promise<boolean> {
    try {
        if (item && api) {

            if (getIn(item, FIELD_ID) === NEW_ENTITY_ID) {
                // do nothing / use refetch to remove item
            } else {
                delete_ && await delete_(api, item);
            }

            showNotification('Löschen erfolgreich', 'Datensatz gelöscht', 'warning', 5000, true, 'bottomLeft');
            return true;
        }

        return false;

    } catch (error) {
        showNotification('Löschen fehlgeschlagen', 'Fehler beim Löschen aufgetreten', 'error', undefined, true, 'bottomLeft');
        return false;
    }
}