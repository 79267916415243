import { useCallback, useEffect, useRef } from "react";
import { getIn } from "../../../../../common/forms/validation/na-va-form/commonUtils";
import { FIELD_EXTENSIONS, FIELD_ID } from "../../../../../data/field-constants/GeneralConstants";
import usePlmControlContext from "../plm-control-context/usePlmControlContext";
import { ActionColumn, ActionColumnActionType, AttachmentColumn, CellRender, Column, PlmControlBaseProps, PlmControlColumn, PlmControlMethods, PlmControlSection, SelectionColumn } from "./PlmControlComponents";
import classNames from "classnames";
import IconButton from "../../../../../common/components/icon/IconButton";
import { FIELD_EXTENSION_DATA, NEW_ENTITY_ID, getExtensionValue } from "../../../../../util/EntityUtils";
import useMethodContext from "../../../../../module-components/method-context/useMethodContext";
import { Modules } from "../../../../../module-components/Modules";
import { TemplateConfig } from "../../types";
import { parseTemplateValueString } from "../../../../../common/components/template-input/TemplateInput";
import { parseTemplateString } from '../../../../../common/components/template-input/TemplateField';
import _, { isArray } from "lodash";
import useKeyCombination from "../../../../../common/hooks/useKeyCombination";
import ShortcutKey from "../../../../../common/components/shortcut-key/ShortcutKey";
import useKeyDown from "../../../../../common/hooks/useKeyDown";
import { AttachmentDto } from "../../../../../client/http";

export type PlmControlLevel = 0 | 1 | 2 | 3 | 4 | 5;
export type PlmColumnDataDisplayProps<T> = {
    item: T;
    section: PlmControlSection;
    keyField?: string;
    level: PlmControlLevel;
    className?: string;
    hideActionColumn?: boolean;
    expandable?: boolean;
    showAttachments?: boolean;
    attachments?: AttachmentDto[];
    onLocalDispatch?: (args: Parameters<PlmControlBaseProps['dispatch']>[0]) => void;
    onChange?: (col: PlmControlColumn, value: any) => void;
    onAction?: (action: ActionColumnActionType, section: PlmControlSection, key: string, item: any) => void;
}

export const PlmColumnDataDisplay: React.FC<PlmColumnDataDisplayProps<any>> = ({ item, section, keyField = FIELD_ID, level, className,
    hideActionColumn = false, expandable, showAttachments, attachments, onLocalDispatch, onChange, onAction, ...baseProps }) => {

    const { controlState, columnConfig, selectable, readOnly, dispatch } = usePlmControlContext();

    const isSelected = item && !!controlState[section].selected?.find(x => x.key === getIn(item, keyField));
    const isExpanded = item && !!controlState[section].expanded?.find(x => x.key === getIn(item, keyField));
    const isEditing = (item && controlState.isEditing?.section === section && controlState.isEditing.key === getIn(item, keyField)) ?? false;

    const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {

        onLocalDispatch && onLocalDispatch(args);

        dispatch(args);
    }, [dispatch, onLocalDispatch]);

    const handleAction = useCallback((action: ActionColumnActionType) => {
        if (readOnly) return;

        switch (action) {
            case 'edit':
                localDispatch({
                    type: 'isEditing/changed',
                    payload: {
                        section, key: getIn(item, keyField), item, value: true
                    }
                });
                break;
            default:
                break;
        }

        onAction && onAction(action, section, getIn(item, keyField), item);
    }, [localDispatch, item, keyField, section, readOnly, onAction]);

    const { addMethod } = useMethodContext();

    useEffect(() => {

        console.log("adding method, item is ", item);

        item && addMethod(PlmControlMethods.getTemplateFieldNames, Modules.sedotecPlm, false, ((fields: TemplateConfig[]) => {

            if (!item || !fields) return;

            getExtensionValue(item, '');

            let templateStrings = [] as string[];

            Object.entries(item).map(([key, val]) => {
                if (key === FIELD_EXTENSION_DATA) return null;

                const templateItems = parseTemplateValueString(val as any)?.filter(x => x.type === 'template').map(i => i.value);
                if (templateItems?.length) {
                    templateStrings = _.uniq([...templateStrings, ...templateItems]);
                }
                return null;
            });

            const extensions = getIn(item, FIELD_EXTENSIONS);
            if (extensions) {
                Object.entries(extensions).map(([key, val]) => {
                    if (isArray(val)) return null;

                    const templateItems = parseTemplateValueString(val as any)?.filter(x => x.type === 'template').map(i => i.value);
                    if (templateItems?.length) {
                        templateStrings = _.uniq([...templateStrings, ...templateItems]);
                    }
                    return null;
                });
            }

            fields = [...fields, ...templateStrings.map(i => ({ ...parseTemplateString(i), entityType: section }) as TemplateConfig)];

            return _.uniqBy(fields, (v) => `${v.name}_${v.entityType}`);
        }), `${section}_${getIn(item, FIELD_ID)}`);
    }, [addMethod, item, section]);

    const handleSave = useCallback(() => {
        if (isEditing) {
            onAction && onAction('save', section, getIn(item, keyField), item);
        };
    }, [isEditing, section, item, keyField, onAction]);

    const controlRef = useRef<HTMLDivElement | null>(null);
    const [isCtrlDown] = useKeyDown('Control');
    useKeyCombination('s', 'ctrl', handleSave);

    return (
        <div className={classNames(className, 'item-row', `level-${level}`,
            { 'in-edit': isEditing, 'selected': isSelected, 'has-action': !hideActionColumn, 'unsaved': getIn(item, FIELD_ID) === NEW_ENTITY_ID })}
            ref={controlRef}>
            {item ?
                <>
                    {expandable && <div className="btn__expand level-1">
                        <IconButton name={isExpanded ? 'icon-expand-arrow' : 'icon-forward'} size="2rem"
                            onClick={() => localDispatch({
                                type: 'expanded/changed',
                                payload: { section, key: getIn(item, keyField), item, value: !isExpanded }
                            })} />
                    </div>}
                    {selectable && <SelectionColumn isSelected={isSelected ?? false}
                        selectionChanged={(val, mod) => localDispatch({
                            type: 'selected/changed',
                            payload: { section, key: getIn(item, FIELD_ID), item, value: val, options: { modifierKeys: mod } }
                        })} />}
                    {controlState[section].showAttachments &&
                        <AttachmentColumn attachments={attachments} section={section} />
                    }
                    {columnConfig[section].map(c => {
                        return (<Column $columnCount={columnConfig[section].length} $level={4} key={c?.name}
                            $error={
                                c?.error !== undefined && (
                                    typeof c.error === 'boolean' ?
                                        c.error :
                                        c.error(item))}
                        >
                            <CellRender columnConfig={c} value={c.isExtensionField ? getExtensionValue(item, c?.name) : getIn(item, c?.name)}
                                onChange={(v) => onChange && onChange(c, v)} isEditMode={isEditing} />
                        </Column>)
                    })}
                    {!hideActionColumn &&
                        <ActionColumn isEditMode={isEditing} readOnly={readOnly} onAction={handleAction} {...baseProps} />
                    }
                    <ShortcutKey shortcutKey={(isCtrlDown && isEditing) ? 'S' : undefined} style={{ left: '0.9rem', opacity: '100%' }} />
                </>
                : null}
        </div>
    )
}
