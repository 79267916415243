export const getMimeType = (extension: string) => {
    const mimeTypes: Record<string, string> = {
        xml: 'application/xml',
        dxf: 'image/vnd.dxf',
        ini: 'text/plain',
        pdf: 'application/pdf',
        doc: 'application/msword',
        png: 'image/png',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        tiff: 'image/tiff',
        svg: 'image/svg+xml',
        eps: 'application/postscript',
        bmp: 'image/bmp',
        txt: 'text/plain',
        html: 'text/html',
        htm: 'text/html'
    };

    // Convert extension to lowercase to handle case insensitivity
    const lowerCaseExt = extension.toLowerCase();

    return mimeTypes[lowerCaseExt] || null;
};

export const getFileExtension = (filePath: string): string | null => {
    // Find the position of the last dot in the string
    const lastDotIndex = filePath.lastIndexOf('.');

    // If no dot is found or it's the first character, return null
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return null;
    }

    // Extract the substring after the last dot
    const extension = filePath.substring(lastDotIndex + 1);

    // Return null if there is no extension after the dot
    return extension.length > 0 ? extension : null;
}