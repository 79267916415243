import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AttachmentApi, AttachmentDto, JobApi, JobDto, SdtJobAttachmentApi } from '../../../../client/http';
import { selectPlmControlSortProperty, PlmDataControlProps, isTemplateFilter, RowSkeletons, selectPlmControlTemplateSortProperty, RefetchPlmAttachments, RefetchPlmAttachmentsEvent } from './components/PlmControlComponents';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import { NEW_ENTITY_ID } from '../../../../util/EntityUtils';
import classNames from 'classnames';
import _ from 'lodash';
import { FIELD_DESIGNATION, FIELD_ID, FIELD_IS_TEMPLATE } from '../../../../data/field-constants/GeneralConstants';
import PlmJobTaskList from './PlmJobTaskControl';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import { JobDefaultValues } from '../../data/entityDefaults';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem } from 'primereact/menuitem';
import { FileExplorerDialog } from '../../../../common/components/file-explorer-dialog/FileExplorerDialog';
import { useFileExplorerDialog } from '../../../../common/components/file-explorer-dialog/useFileExplorerDialog';
import useApi from '../../../../common/hooks/useApi';
import backendClassNames from '../../../../data/field-constants/BackendClassNames';
import { getMimeType, getFileExtension } from '../../../../util/FileUtils';
import { showNotification } from '../../../../common/components/notifications/NotificationHost';
import { log } from '../../../../util/LoggingUtils';

export type PlmJobControlProps = PlmDataControlProps & PlmJobListProps & {
    job: JobDto;
    attachments: AttachmentDto[];
};

const PlmJobControl: React.FC<PlmJobControlProps> = React.memo(({ job, constrainToList, attachments, refetch }) => {

    const { controlState, showTemplates } = usePlmControlContext();

    const [internalJob, , handleChange, handleAction] = useInternalPlmControlData<JobDto, JobApi>(
        job,
        { [FIELD_IS_TEMPLATE]: showTemplates === true },
        'job',
        c => new JobApi(c),
        c => c.apiServicesAppJobCreatePost,
        c => c.apiServicesAppJobUpdatePut,
        c => c.apiServicesAppJobDeleteDelete,
        refetch
    );

    const isExpanded = internalJob && !!controlState.job.expanded?.find(x => x.key === getIn(internalJob, FIELD_ID));

    // Attachment logic
    const [, sdtAttachmentApi] = useApi<AttachmentDto, SdtJobAttachmentApi>(c => new SdtJobAttachmentApi(c));
    const { id: jobId, serviceConnectionId, serviceProvider } = job;
    const handleSelectFile = useCallback((path: string | null) => {

        if (!path || path.length === 0) {
            showNotification('Fehler beim Erstellen eines Anhangs', `Der Pfad "${path}" ist ungültig.`, 'error', undefined, true, 'bottomLeft');
            return;
        }

        const extension = getFileExtension(path);

        if (!extension || extension.length === 0) {
            showNotification('Fehler beim Erstellen eines Anhangs', `Die Dateierweiterung von "${path}" wird nicht unterstützt.`, 'error', undefined, true, 'bottomLeft');
            return;
        }

        const mime = getMimeType(extension);
        if (!mime || mime.length === 0) {
            showNotification('Fehler beim Erstellen eines Anhangs', `Der Dateityp von "${path}" wird nicht unterstützt.`, 'error', undefined, true, 'bottomLeft');
            return;
        }

        sdtAttachmentApi?.apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost({
            attachedToId: jobId,
            targetUrl: path,
            propagateToTasks: false,
            mimeType: mime,
            serviceConnectionId: serviceConnectionId,
            serviceProvider: serviceProvider
        }).then(d => {
            showNotification("Anhang hinzugefügt", path ?? '', 'success', 5000, true, 'bottomLeft');
        });

        abp.event.trigger(RefetchPlmAttachments, { section: 'job', id: jobId } as RefetchPlmAttachmentsEvent);
    }, [serviceConnectionId, serviceProvider, jobId, sdtAttachmentApi]);

    const {
        dialogProps,
        showDialog: showFileExplorer,
    } = useFileExplorerDialog('http://plm/Zeichnungen', handleSelectFile);

    const cm = useRef<ContextMenu>(null);
    const jobContextMenuItems = useMemo(() => [{
        label: "Anhang hinzufügen",
        command: () => showFileExplorer()
    }] as MenuItem[], [showFileExplorer]);

    // const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {

    //     // fetch items on expand
    //     if (args.type === 'expanded/changed' && args.payload.value === true) {
    //         fetchJobTasks();
    //     }
    // }, [fetchJobTasks]);    

    return <div className={classNames({ 'unsaved': getIn(internalJob, FIELD_ID) === NEW_ENTITY_ID })} onContextMenu={(e) => cm.current?.show(e)}>
        <PlmColumnDataDisplay item={internalJob} section="job" level={constrainToList ? 0 : 1} className="plm-job-item job-row" expandable={!constrainToList}
            onChange={handleChange} onAction={handleAction} attachments={attachments} showAttachments={true}
        />
        <ContextMenu model={jobContextMenuItems} ref={cm} breakpoint="767px" />
        {/* Only render on visible, to reset state when hidden */}
        {dialogProps.visible && <FileExplorerDialog title={`Anhang auswählen für ${job.designation}`} {...dialogProps}></FileExplorerDialog>}
        {isExpanded && <PlmJobTaskList jobId={getIn(internalJob, FIELD_ID)} jobNumber={getIn(internalJob, FIELD_DESIGNATION)} readOnly={getIn(internalJob, FIELD_ID) === NEW_ENTITY_ID} />}
    </div>
})

export type PlmJobListProps = {
    constrainToList?: boolean;
}

const PlmJobList: React.FC<PlmJobListProps> = ({ constrainToList }) => {

    const { controlState, readOnly, showTemplates } = usePlmControlContext();

    const internalJobControlStateFilters = controlState.job.filter.internalFilters;
    const internalJobFilters = useMemo(() => [
        ...internalJobControlStateFilters,
        ...isTemplateFilter(showTemplates),
    ], [internalJobControlStateFilters, showTemplates]);

    const externalJobFilters = controlState.job.filter.externalFilters;

    const getAll = useCallback((c: JobApi) => {
        return c.apiServicesAppJobGetAllGet(
            abp.setting.get(Settings_ProviderName),
            abp.setting.getInt(Settings_ServiceConnectionId),
            JSON.stringify(internalJobFilters),
            JSON.stringify(externalJobFilters))
    },
        [internalJobFilters, externalJobFilters]);

    const [jobs, fetchJobs, addRow, isLoading] = useInternalPlmListData<JobDto, JobApi>(
        c => new JobApi(c),
        getAll,
        'job',
        {
            ...JobDefaultValues,
        });

    // Attachment logic
    const [, attachmentApi] = useApi<AttachmentDto, AttachmentApi>(c => new AttachmentApi(c));
    const [attachments, setAttachments] = useState<AttachmentDto[]>([]);
    const fetchAttachments = useCallback(() => {
        if (jobs && jobs.length > 0) {
            attachmentApi?.apiServicesAppAttachmentGetForAllEntitiesGet({ entityType: backendClassNames.Job, entityIds: jobs.map(j => j.id!) }).then(d => setAttachments((d as any)?.result));
        }
    }, [jobs, attachmentApi]);

    const fetchAttachmentsOnEvent = useCallback((event: RefetchPlmAttachmentsEvent) => {
        if (event.section === 'job' && jobs?.find(j => j.id === event.id)) {
            setTimeout(() => fetchAttachments(), 2000);
            log('Refetch attachments called for ', event.section, event.id);
        } else {
            log('Refetch attachments ignored, ', event.section, event.id, ' did not match');
        }
    }, [jobs, fetchAttachments]);

    useEffect(() => {
        fetchAttachments();
    }, [fetchAttachments]);

    useEffect(() => {
        abp.event.on(RefetchPlmAttachments, fetchAttachmentsOnEvent);

        return () => abp.event.off(RefetchPlmAttachments, fetchAttachmentsOnEvent);
    }, [fetchAttachmentsOnEvent])

    return <div className="plm-job-list">

        <PlmColumnHeaderDisplay section='job' level={constrainToList ? 0 : 1} headersLevel={1} onAction={addRow} readOnly={readOnly} />

        {
            _.orderBy(jobs ?? [], [selectPlmControlTemplateSortProperty, selectPlmControlSortProperty])?.map(job => {
                return <PlmJobControl job={job} refetch={fetchJobs} key={getIn(job, FIELD_ID)} constrainToList={constrainToList} attachments={attachments.filter(a => a.attachedToId === job.id)} />
            })
        }

        {isLoading && <RowSkeletons level={2} />}
    </div>
}

export default PlmJobList;