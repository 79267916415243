import React, { useCallback, useState } from 'react';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import Switch from '../../../common/forms/validation/controls/switch/Switch';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import { L } from '../../../abp/utils';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { TemplateDataGridCellRender } from '../../../common/components/api-grid/TemplateDataGridCell';
import { GridCellProps } from '@progress/kendo-react-grid';
import { CellRenderType } from '../../../common/components/api-grid/ApiGrid';
import styled from 'styled-components';
import PlmJobList from './plm-job-list/PlmControl';
import { defaultColumnConfig } from '../data/columnConfigurations';
import { defaultPlmControlState } from './plm-job-list/components/PlmControlComponents';

const StyledTemplateList = styled.div.attrs({ className: 'mt-2 px-4' })`
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    padding-bottom: 2rem;
`

const ApiGrid = styled(ModuleComponentProvider)`
    flex: 1;        
    min-height: 0;
    display: flex;
    flex-direction: column;     
`;

const TemplateList: React.FC = () => {

    const [{ values: filterValues }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const templateGridCell = useCallback((enterEditMode: (value: any) => void): CellRenderType => (td: any, props: GridCellProps) => {
        return <TemplateDataGridCellRender originalProps={props} enterEdit={enterEditMode} editField="inEdit" td={td} />
    }, []);

    return <StyledTemplateList>
        <div className="filters row my-3">
            <h5 className="mb-3">Vorlagenart:</h5>
            <NaVaForm
                initialValues={{ selectJobTemplates: true }}
                onSubmit={() => { }}>
                <NaVaFormStatePuller onStateChanged={setFormState} />
                <div className="d-flex align-items-center">
                    <h5 className="mx-3">{L('Item')}</h5>
                    <Switch name="selectJobTemplates" disabled={true} />
                    <h5 className="mx-3">{L('Job')}</h5>
                </div>
            </NaVaForm>
        </div>

        <h5 className="mb-3">Bitte wählen Sie die Vorlage aus, die Sie einsehen möchten:</h5>

        {/* <ApiGrid appPartName={AppParts.ServiceEntities.Grid.Job} getSingleComponent={true}
            props={{
                isEditable: true,
                cellRender: templateGridCell,
                // cells: {
                //     edit:
                //     {
                //         text: TemplateInputGridCell
                //     }
                //     , data: TemplateDataGridCell
                // },
                selectable: {
                    enabled: false,
                    mode: 'single'
                },
                internalFilters: [filter("IsTemplate").equals(true)]
            } as JobGridProps} /> */}

        <PlmJobList columnConfig={defaultColumnConfig} controlStateConfig={defaultPlmControlState} readOnly={false} selectable={true} showTemplates />

        {/* <PlmJobList columnConfig={defaultColumnConfig} showTemplates={true} /> */}

        {/* <PlmJobList columnConfig={defaultColumnConfig} controlStateConfig={{
            ...defaultPlmControlState,
            job: {
                ...defaultPlmControlState.job,
                filter: {
                    ...defaultPlmControlState.job.filter,
                    externalFilters: [...defaultPlmControlState.job.filter?.externalFilters ?? [], filter('number').contains('V110R')]
                }
            }
        }} readOnly={true} selectable={false} /> */}
    </StyledTemplateList>
}

export default TemplateList;