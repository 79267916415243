import React, { useMemo, useState } from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router';
import FunctionalFormNavigationConfiguration from '../../common/forms/validation/configuration/functional/FormNavigationConfiguration';
import FunctionalFormValidationConfiguration from '../../common/forms/validation/configuration/functional/FormValidationConfiguration';
import Header from './header/Header';
import SideNav from './side-nav/SideNav';
import './ProtectedArea.scss';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { L } from '../../abp/utils';
import constants from '../../abp/constants';
import { AuthInfo, useAuthenticator } from '../../authentication/authenticator/Authenticator';
import Dashboard from '../dashboard/Dashboard';
import Settings from '../settings/Settings';
import TimeTrackingSessions from '../time-tracking/sessions/TimeTrackingSessions';
import TimeEntities from '../time-tracking/time-entities/TimeEntities';
import { getAuthorizedUserConfiguration } from '../../abp/getUserConfiguration';
import _ from 'lodash';
import LoadingIndicator from '../../common/components/loading-indicator/LoadingIndicator';
import PlanningEntities from '../time-planning/planning-entities/PlanningEntities';
import ReportManagement from '../reporting/ReportManagement';
import ReportWizard from '../reporting/ReportWizard';
import ReportEditor from '../reporting/ReportEditor';
import ReportList from '../reporting/ReportList';
import ReportOverview from '../reporting/ReportOverview';
import ModuleFileComponentProvider from '../../module-components/ModuleFileComponentProvider';
import { useModuleArrayData } from '../../module-components/ModuleDataProvider';
import RewindCoreAdministration from '../administration/RewindCoreAdministration';
import NotificationHost from '../../common/components/notifications/NotificationHost';
import { useMediaQuery } from 'usehooks-ts';

const App: React.FC = () => {
  return <div className="app">
    <Navigate to="/app/home" />
    <Outlet></Outlet>
  </div>
}

const Home: React.FC = () => {
  return (<div className="home">
    <Navigate to="/app/home/overview" />
    <Outlet></Outlet>
  </div>)
}

const ProtectedArea: React.FC = () => {

  const { user } = useAuthenticator();
  // const navigationConfig = useMemo(() => FunctionalFormNavigationConfiguration((abp as any).custom.FormNavigation), []);
  // const validationConfig = useMemo(() => FunctionalFormValidationConfiguration({}, (abp as any).custom.FormValidation), []);

  // Reset Page Title
  const location = useLocation();
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    if (document.title !== constants.appTitle) {
      document.title = constants.appTitle;
    }
  }, [location]);

  // Refetching User Configuration using the user's token to acquire user specific data
  useEffect(() => {
    user?.tokenInfo?.token &&
      getAuthorizedUserConfiguration(user.tokenInfo.token)
        .then(result => {
          _.merge(abp, result.data.result);
          setConfigLoaded(true);
        }).catch(r => console.log("Refetching user configuration failed: ", r));
  }, [user])

  const { data: routeData } = useModuleArrayData("navigation/routeItems.json");

  const renderModuleRouteData = useCallback((groupName?: string) => {
    if (!routeData || routeData.length < 1)
      return null;

    const routes = routeData.filter(r => r.group === groupName).map((d, i) => {
      if (d.defineGroup) {
        // create a parent route
        return <Route path={d.path} key={i} element={d.appPartName ? <ModuleFileComponentProvider modulePath="navigation" appPartName={d.appPartName} /> : undefined} >
          {renderModuleRouteData(d.defineGroup)}
        </Route>;
      } else if (d.index) {
        if (d.navigateTo) {
          // create index route with redirection
          return <Route index key={i} element={<Navigate to={d.navigateTo} />} />;
        }

        // create index route with element
        return <Route index key={i} element={<ModuleFileComponentProvider modulePath="navigation" appPartName={d.appPartName} />} />;
      }
      else {
        // create a child route
        return <Route path={d.path} key={i} element={<ModuleFileComponentProvider modulePath="navigation" appPartName={d.appPartName} />} />;
      }
    }
    );

    return routes;
  }, [routeData])

  // 280px is the target size for the side nav
  // It's 2 columns wide in the 12 column bootstrap layout
  // So the total width of the viewport must be [width]*6
  const sideNavMinWidth = 280 * 6;
  const hasMinWidth = useMediaQuery(`(min-width:${sideNavMinWidth}px)`);
  const collapsedWidth = '5.5rem';
  const [sideNavCollapsed, setSideNavCollapsed] = useState(!hasMinWidth);

  useEffect(() => { !hasMinWidth && setSideNavCollapsed(true) }, [hasMinWidth]);

  return (
    <>
      {configLoaded ? (
        <>
          <SideNav collapsed={sideNavCollapsed} shouldCollapse={!hasMinWidth} collapsedWidth={collapsedWidth} onCollapsedChanged={c => setSideNavCollapsed(c)} />
          {/* Offset of 2 because sidebar is position fixed */}
          <main className={`${sideNavCollapsed ? 'col' : 'offset-2 col-10'} d-flex flex-column`} style={sideNavCollapsed ? { marginLeft: collapsedWidth } : undefined}>
            <Header ></Header>

            <Routes>
              <Route path="/">
                <Route path="home/">
                  <Route path="overview" element={<Dashboard></Dashboard>} />
                  <Route path="calendar" element={<Dashboard></Dashboard>} />
                  {renderModuleRouteData("home/")}
                  <Route index element={<Navigate to="/app/home/overview" />} />
                </Route>
                <Route path="time-planning/">
                  <Route path="entity" element={<PlanningEntities />} />
                  <Route path="job" element={<></>} />
                  {renderModuleRouteData("time-planning/")}
                  <Route index element={<Navigate to="/app/time-planning/entity" />} />
                </Route>
                <Route path="time-tracking/">
                  <Route path="entities" element={<TimeEntities />} />
                  <Route path="sessions" element={<TimeTrackingSessions />} />
                  {renderModuleRouteData("time-tracking/")}
                  <Route index element={<Navigate to="/app/time-tracking/entities" />} />
                </Route>
                <Route path="reporting/">
                  <Route path="create" element={<ReportWizard />} />
                  <Route path="edit" element={<ReportOverview />} >
                    <Route path=":reportId" element={<ReportEditor />} />
                    <Route index element={<ReportList />} />
                  </Route>
                  <Route path="management" element={<ReportManagement />} />
                  {renderModuleRouteData("reporting/")}
                  <Route index element={<Navigate to="/app/reporting/create" />} />
                </Route>
                <Route path="administration/" >
                  <Route path="core" element={<RewindCoreAdministration />} />
                  {renderModuleRouteData("administration/")}
                  <Route index element={<Navigate to="/app/administration/core" />} />
                </Route>
                <Route path="settings" element={<Settings />} />
                {renderModuleRouteData()}
                <Route index element={<Navigate to="/app/home" />} />
              </Route>
            </Routes>
          </main>
          <NotificationHost position='bottomRight'/>
          <NotificationHost position='bottomLeft'/>
        </>
      ) : <LoadingIndicator />
      }

      {/* <div id="dialog-container" className="position-relative" style={{ zIndex: 100 }} /> */}
    </>
  );
};

export default ProtectedArea;
