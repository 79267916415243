// hooks/useFileExplorerDialog.ts
import { useState, useCallback, useMemo } from 'react';
import { FileExplorerDialogProps } from './FileExplorerDialogTypes';
import useVisibility from '../../hooks/useVisibility';

export interface UseFileExplorerDialogResult {
    showDialog: (baseUrl?: string, initialPath?: string) => void;
    hideDialog: () => void;
    reset: () => void;
    selectedPath: string | null;
    dialogProps: FileExplorerDialogProps
}

export const useFileExplorerDialog = (baseUrl: string, onSelect?: FileExplorerDialogProps['onSelect'], onClose?: FileExplorerDialogProps['onClose']): UseFileExplorerDialogResult => {
    const [dialogVisible, showDialog, hideDialog] = useVisibility();
    const [internalBaseUrl, setInternalBaseUrl] = useState<string>(baseUrl);
    const [selectedPath, setSelectedPath] = useState<string | null>(null);
    const [initialPath, setInitialPath] = useState<string | undefined>();

    const handleOnClose = useCallback(() => {
        onClose && onClose();
        setSelectedPath(null);
        hideDialog();
    }, [hideDialog, onClose]);
    
    const handleOnSelect = useCallback((path: string | null) => {
        onSelect && onSelect(path);
        setSelectedPath(path);
        hideDialog();
    }, [hideDialog, onSelect]);

    const dialogProps = useMemo(() => ({
        baseUrl: internalBaseUrl,
        visible: dialogVisible,
        onClose: onClose ?? handleOnClose,
        onSelect: onSelect ?? handleOnSelect,
        initialPath: initialPath
    } as FileExplorerDialogProps), [internalBaseUrl, initialPath, dialogVisible, onClose, handleOnClose, onSelect, handleOnSelect]);

    const showDialogInternal = useCallback((newBaseUrl?: string, newInitialPath?: string) => {
        newBaseUrl && setInternalBaseUrl(newBaseUrl);
        setInitialPath(newInitialPath);
        setSelectedPath(null);
        showDialog();
    }, [showDialog]);

    const reset = useCallback(() => {
        setSelectedPath(null);
        setInternalBaseUrl('');
        setInitialPath(undefined);
        hideDialog();
    }, [hideDialog]);

    return {
        showDialog: showDialogInternal,
        hideDialog,
        selectedPath,
        reset,
        dialogProps
    };
};