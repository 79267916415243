import React from 'react';
import IconButton from '../../icon/IconButton';
import { L } from '../../../../abp/utils';

interface NavigationToolbarProps {
    onBack: () => void;
    onForward: () => void;
    onUp: () => void;
    canGoBack: boolean;
    canGoForward: boolean;
    currentPath: string;
}

export const NavigationToolbar: React.FC<NavigationToolbarProps> = ({
    onBack,
    onForward,
    onUp,
    canGoBack,
    canGoForward,
    currentPath
}) => {
    return (<>
        <IconButton
            name="icon-circled-left"
            disabled={!canGoBack}
            onClick={onBack}
            title={L('Back')} />
        <IconButton
            name="icon-circled-right"
            disabled={!canGoForward}
            onClick={onForward}
            title={L('Forward')}
        />
        <IconButton
            name="icon-circled-chevron-up"
            disabled={true}
            onClick={onUp}
            title="Up one level"
        />
        <span className="k-toolbar-text ms-3">
            {currentPath}
        </span>
    </>
    );
};