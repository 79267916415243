import React, { useMemo } from 'react';
import { Breadcrumb } from '@progress/kendo-react-layout';

interface BreadcrumbType {
    name: string;
    path: string;
}

interface BreadcrumbNavigationProps {
    paths: BreadcrumbType[];
    onNavigate: (path: string) => void;
    disabled?: boolean;
}

const disabledStyle = {
    textDecoration: 'none'
} as React.CSSProperties;

export const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({
    paths,
    onNavigate,
    disabled
}) => {
    const items = useMemo(() => ([{ id: 'root', text: '/', onClick: () => { } },
    ...paths.map(path => ({
        text: path.name,
        id: path.path,
        onClick: () => onNavigate(path.path)
    }))]), [paths, onNavigate]);

    return (
        <Breadcrumb
            data={items}
            disabled={disabled}
            style={{
                padding: '.5rem',
                ...disabled ? disabledStyle : {}
            }}
        />
    );
};