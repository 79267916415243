import _ from "lodash";
import { L } from "../../../abp/utils";
import { FIELD_AMOUNT, FIELD_CATEGORY_ID, FIELD_CUSTOMER_ID, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_DESIGNATION, FIELD_END_DATE, FIELD_END_TIME, FIELD_ID, FIELD_JOB_ID, FIELD_JOB_TASK_ID, FIELD_JOB_TYPE_CODE, FIELD_JOB_TYPE_ID, FIELD_LINE_NUMBER, FIELD_POSTING_TYPE, FIELD_START_DATE, FIELD_START_TIME } from "../../../data/field-constants/GeneralConstants";
import { c } from "../../../util/ClassUtils";
import { PlmControlColumn, PlmItemColumnConfig } from "../template-management/plm-job-list/components/PlmControlComponents";
import { FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION, FIELD_UNIT_OF_MEASURE_CODE, FIELD_UNIT_OF_MEASURE_ID } from "../../rewind-bc-delux/field-constants/BcConstants";
import { getExtensionValue } from "../../../util/EntityUtils";
import { getIn } from "../../../common/forms/validation/na-va-form/commonUtils";

export class PlmControlColumnBuilder {
    col: PlmControlColumn;

    constructor(name: string) {
        this.col = {
            name,
            type: 'string',
            displayName: L(_.upperFirst(name))
        }
    }

    string() {
        this.col.type = 'string'
        return this;
    }

    number() {
        this.col.type = 'number'
        return this;
    }

    date() {
        this.col.type = 'date'
        return this;
    }

    boolean() {
        this.col.type = 'boolean'
        return this;
    }

    selectionData(selectionData: PlmControlColumn['selectionData']) {
        this.col.type = 'selection';
        this.col.selectionData = selectionData;
    }

    error(error: boolean | ((item: any) => boolean)) {
        this.col.error = error;
        return this;
    }

    required() {
        this.col.error = (item: any) => !(this.col.isExtensionField ? getExtensionValue(item, this.col.name) : getIn(item, this.col.name));
    }

    readonly() {
        this.col.readOnly = true;
        return this;
    }

    extension() {
        this.col.isExtensionField = true;
        return this;
    }

    displayName(displayName: string) {
        this.col.displayName = displayName;
    }

    group(group: string) {
        this.col.group = group;
        return this;
    }
}

export const makeCol = (name: string): PlmControlColumnBuilder => {
    return new PlmControlColumnBuilder(name);
}

export const col = (name: string, type?: PlmControlColumn['type'], displayName?: string, selectionData?: PlmControlColumn['selectionData'], group?: string): PlmControlColumn => {
    return {
        name,
        type: type ?? 'string',
        displayName: displayName ?? L(_.upperFirst(name)),
        selectionData,
        group
    }
}

export const colRo = (name: string, type?: PlmControlColumn['type'], displayName?: string, selectionData?: PlmControlColumn['selectionData'], group?: string): PlmControlColumn => {
    return {
        ...col(name, type, displayName, selectionData, group),
        readOnly: true
    }
}

export const colEx = (name: string, type?: PlmControlColumn['type'], displayName?: string, selectionData?: PlmControlColumn['selectionData'], group?: string): PlmControlColumn => {
    return {
        ...col(name, type, displayName, selectionData, group),
        isExtensionField: true
    }
}

export const colExRo = (name: string, type?: PlmControlColumn['type'], displayName?: string, selectionData?: PlmControlColumn['selectionData'], group?: string): PlmControlColumn => {
    return {
        ...colEx(name, type, displayName, selectionData, group),
        readOnly: true
    }
}

export const withoutIds = (columns: PlmControlColumn[]) => {
    return columns.filter(c => !c.name.toLowerCase().includes('id'));
}

export const asLocalizedSelectionValues = (values: string[]) => values.map(v => ({ key: v, value: L(v) }));

const chooseColumns = (names: string[], source: PlmControlColumn[]) => names.map(v => source.find(x => x.name === v));

export const jobStatusTypes = ['Planning', 'Quote', 'Open', 'Completed'];

export const jobColumns = [
    col(FIELD_ID),  //"5d111323-4b6f-ee11-8def-0022480e7700"
    col(FIELD_DESIGNATION),  //number "1TSA090024R0004.H"
    col(FIELD_DESCRIPTION1),  //displayName "SCHLOSS 5MM DOPPELBART   IP20-54"
    col(FIELD_DESCRIPTION2),  //displayname2 "Aufbrauchen! Ersatz = V436P0067"
    colEx('templateDescription'),
    colEx('searchDescription'),  //"SCHLOSS 5MM DOPPELBART   IP20-54"
    colEx('orderClass'),  //""
    colEx('projectManager'),  //""
    colEx('salesPersonCode'),  //""
    colEx('personResponsible'),  //""
    col('accountableId'),  // personResponsibleId "00000000-0000-0000-0000-000000000000"
    colEx('personResponsibleJT'),  //""
    colEx('personResponsibleIdJT'),  //"00000000-0000-0000-0000-000000000000"
    colEx('personResponsibleJPL'),  //""
    colEx('personResponsibleIdJPL'),  //"00000000-0000-0000-0000-000000000000"
    colEx('personResponsibleSales'),  //""
    colEx('personResponsibleIdSales'),  //"00000000-0000-0000-0000-000000000000"
    colEx('personResponsiblePurchase'),  //""
    colEx('personResponsibleIdPurchase'),  //"00000000-0000-0000-0000-000000000000"
    colEx('organisationAttributeCode'),  //"20"
    colEx('organisationAttributeId'),  //"56785dee-2fbf-ed11-9a88-00224874f112"
    colEx('attachedToItemNumber'),  //"1TSA090024R0004.H"
    colEx('attachedToItemId'),  //"181b7608-776e-ee11-8def-6045bd177f82"
    colEx('creationDate', 'date'),  //"2023-10-20"
    col('customState', 'selection', L('JobState'), asLocalizedSelectionValues(jobStatusTypes)),  //status "Planning"
    colEx('statusPlanningDate', 'date'),  //"2023-11-06"
    colEx('jobType'),  //""
    col(FIELD_START_DATE, 'date'),  //startingDate "2023-11-26"
    col(FIELD_END_DATE, 'date'),  //endingDate "2024-12-31"
    colEx('sellToCustomerId'),  //"dfb9a353-1543-ee11-be6d-000d3aea3095"
    colEx('sellToCustomerNumber'),  //"99999"
    colEx('sellToCustomerName'),  //"Vorlage Projekt"
    colEx('sellToCustomerName2'),  //""
    colEx('sellToAddress'),  //""
    colEx('sellToAddress2'),  //""
    colEx('sellToPostCode'),  //""
    colEx('sellToCity'),  //""
    colEx('sellToCounty'),  //""
    colEx('sellToCountryRegionCode'),  //""
    col(FIELD_CUSTOMER_ID),  //billToCustomerId "dfb9a353-1543-ee11-be6d-000d3aea3095"
    colEx('billToCustomerNumber'),  //"99999"
    colEx('billToName'),  //"Vorlage Projekt"
    colEx('billToName2'),  //""
    colEx('billToAddress'),  //""
    colEx('billToAddress2'),  //""
    colEx('billToPostCode'),  //""
    colEx('billToCity'),  //""
    colEx('billToCounty'),  //""
    colEx('billToCountryRegionCode'),  //""
    colEx('shipToCode'),  //""
    colEx('shipToName'),  //"Vorlage Projekt"
    colEx('shipToName2'),  //""
    colEx('shipToAddress'),  //""
    colEx('shipToAddress2'),  //""
    colEx('shipToPostCode'),  //""
    colEx('shipToCity'),  //""
    colEx('shipToCounty'),  //""
    colEx('shipToCountryRegionCode'),  //""
    colEx('allowScheduleContractLines', 'boolean'),  //true
    colEx('blockJobJournalEditing', 'boolean'),  //false
    colEx('blockJobJournalPosting', 'boolean'),  //false
    colEx('applyUsageLink', 'boolean'),  //true
    colEx('copiedFromJobNumber'),  //""
    colEx('copiedFromJobId'),  //"00000000-0000-0000-0000-000000000000"
    colEx('jobTasksCopied', 'boolean')  //false
];

export const jobTaskStates = ['Blank',
    'Pending',
    'In Process',
    'Done',
    'Waiting for intern',
    'Delayed',
    'Delivery not allowed',
    'Waiting for extern',
    'To discuss intern',
    'To discuss extern',
    'Not forseen',
    'For free',
    'To be done by third part',
    'Reactivate',
    'In Planning',
    'Waiting for ICP',
    'To be done by ICP',
    'Waiting for delivery by ICP',
    'Delivery done by ICP',
    'Waiting for delivery by third part',
    'Delivery done by third part',
    'Released for delivery',
    'Released for invoicing'
];

export const jobTaskTypes = [
    'Posting', 'Heading', 'Total', 'Begin-Total', 'End-Total'
];

export const jobTaskSearchDescriptions1 = [
    '01_MATERIALBEREITSTELLUNG',
    '02_KOMMISSIONIERUNG',
    '03 BEREITSTELLUNG FERT. UND UNF. ERZEUGNIS',
    '06_EINKAUFSBESTELLUNG FÜR FREMDDL',
    '07_VERSAND AN FREMDDL',
    '08_EINLAGERUNG NACH RÜCKSENDUNG VON FREMDDL',
    '09_FREMDDL VERBRAUCH',
    '10_AUSFÜHRUNG DER PRODUKTION/MONTAGE ARBEIT',
    '15_BEREITSTELLUNG FÜR TRANSPORT',
    '70_QS PRÜFUNG NEUTEIL REV. MASCHINENWECHSEL',
    '75_QS WSK UND CHECKLISTE',
    '80_FERTIGMELDUNG_ART ZUGANG BUCHEN',
    '83_ABLADEN',
    '85_UMLAGERUNGSAUFTRAG BUCHEN',
    '90_EINLAGERN BUCHEN'
];

export const jobTaskUseQuantityTypes = [
    'Fix Quantity', 'Multiplier (Quantity)', 'Fix Quantity with Multiplier'
];

export const jobTaskAutoTransferOnStatusChangeTypes = [
    'Blank', 'CreateTransferOrder', 'CreateTransferOrderAndPost', 'CreateItemReclassJournal', 'CreateItemReclassJournalAndPost'
]

export const delegatedToICPTypes = ['WICAM', 'PUNCHPRO']

export const jobTaskColumns = [
    col(FIELD_ID),  //"15cb9d22-dd98-ee11-be38-002248d479d2"
    col('jobNumber'),  //"V110R0001.C"
    col(FIELD_JOB_ID),  //"ba91256c-5964-ee11-8def-0022480c80c6"
    col(FIELD_DESIGNATION),  //jobTaskNumber "00000_8001_203000000"
    col(FIELD_DESCRIPTION1),  //displayName "MONTAGE MI DES ARTIKELS: von"
    col(FIELD_DESCRIPTION2),  //displayName2  ""
    colEx('jobTaskNumber2'),
    colEx('searchDescription1CategoryCode', 'selection', undefined, asLocalizedSelectionValues(jobTaskSearchDescriptions1)),  //""
    colEx('searchDescription1CategoryId'),  //"00000000-0000-0000-0000-000000000000"
    makeCol(FIELD_JOB_TYPE_CODE).extension().readonly().error(i => !!getExtensionValue(i, FIELD_JOB_TYPE_ID) && !getExtensionValue(i, FIELD_JOB_TYPE_CODE)).col,  //""
    colExRo(FIELD_JOB_TYPE_ID),  //"00000000-0000-0000-0000-000000000000"
    col('salesPersonCode'),  //""
    col('personResponsible'),  //""
    colEx('planningDate', 'date'),  //"2023-11-26"
    colEx('toFinishUntil', 'date'),  //"2024-12-31"
    col('accountableFk'),  //taskPersonResponsible ""
    col('accountableId'),  //taskPersonResponsibleId "00000000-0000-0000-0000-000000000000"
    col('executiveFk'),  //taskPersonDelegated "01550APLA"
    col('executiveFk'),  //taskPersonDelegatedId "00000000-0000-0000-0000-000000000000"
    colEx('delegatedToICP', 'selection', undefined, asLocalizedSelectionValues(delegatedToICPTypes)),
    colEx('taskPersonDelegated'), // taskPersonDelegated "01550APLA"    
    colEx('taskPersonDelegatedId'), // taskPersonDelegatedId "00000000-0000-0000-0000-000000000000"    
    colEx('taskDelegatedAt', 'date'),  //"0001-01-01"
    colEx('jobJournalPostingBlock', 'boolean'),  //false
    colEx('jobTaskType', 'selection', undefined, asLocalizedSelectionValues(jobTaskTypes)),  //"Begin-Total"
    colEx('globalDimension1Code'),  //""
    colEx('globalDimension2Code'),  //""
    colEx('status'),  //""
    col('taskStatus', 'selection', undefined, asLocalizedSelectionValues(jobTaskStates)),  //customState "Planning"
    colEx('processingStatus', 'selection', undefined, asLocalizedSelectionValues(jobTaskStates)),  //" "
    colEx('setupTimeConnectionCode'),  //""
    colEx('hide', 'boolean'),  //false
    colEx('defaultWorkTypeFilter'),  //""
    colEx('defaultWorkTypeCode'),  //""
    colEx('internalComment'),  //""
    colEx('externalComment'),  //""
    colEx('quantityMultiplier', 'number'),  //1
    colEx('transferFromLocationCode', undefined, undefined, undefined, 'jobTaskTransfer'),  //""
    colEx('transferFromLocationId', undefined, undefined, undefined, 'jobTaskTransfer'),  //"00000000-0000-0000-0000-000000000000"
    colEx('transferToLocationCode', undefined, undefined, undefined, 'jobTaskTransfer'),  //""
    colEx('transferToLocationId', undefined, undefined, undefined, 'jobTaskTransfer'),  //"00000000-0000-0000-0000-000000000000"
    colEx('inTransitLocationCode', undefined, undefined, undefined, 'jobTaskTransfer'),  //""
    colEx('inTransitLocationId', undefined, undefined, undefined, 'jobTaskTransfer'),  //"00000000-0000-0000-0000-000000000000"
    colEx('directTransfer', 'boolean', undefined, undefined, 'jobTaskTransfer'),  //false
    colEx('useQuantity', 'selection', undefined, asLocalizedSelectionValues(jobTaskUseQuantityTypes), 'jobTaskTransfer'),  //"Fix Quantity"
    colEx('autoTransferOnStatusChange', 'selection', undefined, asLocalizedSelectionValues(jobTaskAutoTransferOnStatusChangeTypes), 'jobTaskTransfer'),  //" "
    colEx('autoTransferForStatus', 'selection', undefined, asLocalizedSelectionValues(jobTaskStates), 'jobTaskTransfer'),
    colEx('attachedToPositiveItemAdjmt'),  //""
    colEx('attachedToPositiveItemAdjmtId'),  //"00000000-0000-0000-0000-000000000000"
    colEx('attachedToNegativeItemAdjmt'),  //""
    colEx('attachedToNegativeItemAdjmtId'),  //"00000000-0000-0000-0000-000000000000"
    colRo('origBlanketResQty', 'number'),  //0
    colRo('resQtySchedule', 'number'),  //0
    colRo('resQtyUsage', 'number'),  //0
    colRo('resQtySale', 'number'),  // 0
    colEx('itemReclassJnlTemplateName', undefined, undefined, undefined, 'jobTaskTransfer'),   // : "",
    colEx('itemReclassJnlTemplateId', undefined, undefined, undefined, 'jobTaskTransfer'),   // : "00000000-0000-0000-0000-000000000000",
    colEx('itemReclassJnlBatchName', undefined, undefined, undefined, 'jobTaskTransfer'),   // : "",
    colEx('itemReclassJnlBatchId', undefined, undefined, undefined, 'jobTaskTransfer'),   // : "00000000-0000-0000-0000-000000000000",
];

export const jobTypeType = ['Blank', 'Lump-sum-settlem.', 'Time and Material', 'Intern', 'Blanket']
export const jobTypeJobJournalLineLedgerEntryType = ['No Job Ledger Entry', 'Usage', 'Output'];
export const jobTypeResourceJournalLineLedgerEntryType = ['No Ledger Entry', 'Usage'];
export const jobTypeItemJournalLineLedgerEntryType = ['No Ledger Entry', 'Negative Adjmt.'];

export const jobTypeColumns = [
    col(FIELD_ID, undefined, undefined, undefined, 'data'),  //"a5fabba7-54d1-ee11-9078-0022485b7717",
    col(FIELD_DESIGNATION, undefined, undefined, undefined, 'data'),  //code "",
    col(FIELD_DESCRIPTION1, undefined, undefined, undefined, 'data'),  //displayName "",
    col(FIELD_DESCRIPTION2, undefined, undefined, undefined, 'data'),  //displayName2"",
    col('type', 'selection', undefined, asLocalizedSelectionValues(jobTypeType), 'general'),  //"Lump-sum-settlem.",
    col('allowBlankJobJournalLine', 'boolean', undefined, undefined, 'jobTypeJobJournalLine'),  //true,
    col('allowBudgetJobJournalLine', 'boolean', undefined, undefined, 'jobTypeJobJournalLine'),  //false,
    col('allowBillableJobJournalLine', 'boolean', undefined, undefined, 'jobTypeJobJournalLine'),  //true,
    col('allowBudgetBillableJobJournalLine', 'boolean', undefined, undefined, 'jobTypeJobJournalLine'),  //false,
    col('resourceJobJournalLineJobLedgerEntryType', 'selection', undefined, asLocalizedSelectionValues(jobTypeJobJournalLineLedgerEntryType), 'jobTypeLedgerTypes'),  //"Usage",
    col('itemJobJournalLineJobLedgerEntryType', 'selection', undefined, asLocalizedSelectionValues(jobTypeJobJournalLineLedgerEntryType), 'jobTypeLedgerTypes'),  //"Usage",
    col('glAccountJobJournalLineJobLedgerEntryType', 'selection', undefined, asLocalizedSelectionValues(jobTypeJobJournalLineLedgerEntryType), 'jobTypeLedgerTypes'),  //"Usage",
    col('resourceJobJournalLineLedgerEntryType', 'selection', undefined, asLocalizedSelectionValues(jobTypeResourceJournalLineLedgerEntryType), 'jobTypeLedgerTypes'),  //"Usage",
    col('itemJobJournalLineLedgerEntryType', 'selection', undefined, asLocalizedSelectionValues(jobTypeItemJournalLineLedgerEntryType), 'jobTypeLedgerTypes'),  //"Negative Adjmt.",
    col('allowBudgetJobPlanningLine', 'boolean', undefined, undefined, 'jobTypeJobPlanningLine'),  //true,
    col('allowBillableJobPlanningLine', 'boolean', undefined, undefined, 'jobTypeJobPlanningLine'),  //true,
    col('allowBudgetBillableJobPlanningLine', 'boolean', undefined, undefined, 'jobTypeJobPlanningLine'),  //true,
    col('allowBillableJobPlanningLineInsertion', 'boolean', undefined, undefined, 'jobTypeJobPlanningLine'),  //false,
    col('blockPlanning', 'boolean', undefined, undefined, 'general'),  //false,
    col('salesQuoteToOrder', 'boolean', undefined, undefined, 'general'),  //true,
    col('blockOrderPosting', 'boolean', undefined, undefined, 'general'),  //false,
    col('autoAdjustmentForStatus', 'selection', undefined, asLocalizedSelectionValues(jobTaskStates), 'general')  //" "
];

export const jobTypeItemAdjustmentType = ['ItemJnlAutomatically', 'Open Item Journal', 'JobJnlAutomatically', 'Open Job Journal'];
export const jobTypeUseDimensionsType = ['Dimensions from Job Task', 'Dimensions from Item Journal'];

const tempJobTypeAdjColumns = [
    col('itemAdjustment', 'selection', undefined, asLocalizedSelectionValues(jobTypeItemAdjustmentType), 'data'),   //" ",
    col('itemJournalTemplateName', undefined, undefined, undefined, 'jobTypeItemJournal'),   //"",
    col('itemJournalTemplateId', undefined, undefined, undefined, 'jobTypeItemJournal'),   //"00000000-0000-0000-0000-000000000000",
    col('itemJournalBatchName', undefined, undefined, undefined, 'jobTypeItemJournal'),   //"",
    col('itemJournalBatchId', undefined, undefined, undefined, 'jobTypeItemJournal'),   //"00000000-0000-0000-0000-000000000000",
    col('jobJournalTemplateName', undefined, undefined, undefined, 'jobTypeJobJournal'),   //"",
    col('jobJournalTemplateId', undefined, undefined, undefined, 'jobTypeJobJournal'),   //"00000000-0000-0000-0000-000000000000",
    col('jobJournalBatchName', undefined, L('AdjJobJournalBatchName'), undefined, 'jobTypeJobJournal'),   //"",
    col('jobJournalBatchId', undefined, undefined, undefined, 'jobTypeJobJournal'),   //"00000000-0000-0000-0000-000000000000",
    col('useDimensions', 'selection', undefined, asLocalizedSelectionValues(jobTypeUseDimensionsType), 'general'),   //"Dimensions from Job Task",
    col('priceCalculation', 'boolean', undefined, undefined, 'general'),   //false,
    col('createJobLedgerEntry', 'boolean', undefined, undefined, 'general'),   //false,
    col('negativeJobLedgerEntry', 'boolean', undefined, undefined, 'general'),   //false,
    col('useScrap', 'boolean', undefined, undefined, 'general'),   //false,
    col('useSetupTime', 'boolean', undefined, undefined, 'general'),   //false
]

export const jobTypeAdjColumns = [
    ...tempJobTypeAdjColumns.map(x => ({
        ...x,
        name: "positive" + _.upperFirst(x.name),
        displayName: x.displayName === x.name ? "positive" + _.upperFirst(x.name) : x.displayName,
    })),
    ...tempJobTypeAdjColumns.map(x => ({
        ...x,
        name: "negative" + _.upperFirst(x.name),
        displayName: x.displayName === x.name ? "negative" + _.upperFirst(x.name) : x.displayName,
    }))
]

export const jobTypeLineUseQuantityType = ['Fix Quantity', 'Multiplier (Quantity)', 'Fix Quantity with Multiplier'];
export const jobTypeLinePostingType = ['Positive Adjmt.', 'Negative Adjmt.'];

export const jobTypeLinesColumns = [
    col(FIELD_ID),  //"fafca9fe-20a7-ee11-be38-002248e508b5",
    col('jobTypeCode'),  //"V110R0008_3044280",
    col('jobTypeId'),  //"1fc23678-af7f-ee11-817b-6045bd1828a1",
    colRo(FIELD_POSTING_TYPE, 'selection', undefined, asLocalizedSelectionValues(jobTypeLinePostingType)),  //"Positive Adjmt.",
    col('itemNumber'),  //"V110R0008.D",
    col('itemId'),  //"deace6cf-c347-ee11-be6f-0022485b757e",
    col(FIELD_DESCRIPTION1),  // displayName "Gerüst 2000x850x625",
    col(FIELD_DESCRIPTION2),  // displayName2 "mit Rückwand montiert",
    col('useItemSubstitution'),  //false,
    col('useQuantity', 'selection', undefined, asLocalizedSelectionValues(jobTypeLineUseQuantityType)),  //"Multiplier (Quantity)",
    col('quantity', 'number'),  //0,
    col('locationCode'),  //"30442",
    col('locationId'),  //"76d4c2fb-68de-ed11-a7c9-00224875207a",
    col('binCode'),  //"",
    col('binId'),  //"00000000-0000-0000-0000-000000000000",
    col('resourceNumber'),  //"",
    col('resourceId'),  //"00000000-0000-0000-0000-000000000000",
    col('resourceUnitOfMeasureCode'),  //"",
    col('resourceUnitOfMeasureId'),  //"00000000-0000-0000-0000-000000000000",
    col('setupTime', 'number'),  //0
]

export const jobPlanningLineCalculateQuantityType = [' ', 'Time', 'Quantity'];
export const jobPlanningLineLineType = ['Budget', 'Billable', 'Both Budget and Billable'];
export const jobPlanningLineType = ['Resource', 'Item', 'G/L Account', 'Text'];

export const jobPlanningLinesColumns = [
    col(FIELD_ID),   // : "3b6e4f3b-f9ae-ee11-a568-6045bd179dfb",
    col('jobNumber'),   // : "24_20_ACM_001",
    col(FIELD_JOB_ID),   // : "04ab3761-f7ae-ee11-a568-6045bd179dfb",
    col('jobTaskNumber'),   // : "01001_8601_203044410",
    col(FIELD_JOB_TASK_ID),   // : "356e4f3b-f9ae-ee11-a568-6045bd179dfb",
    colEx(FIELD_LINE_NUMBER, 'number'),   // : 10000,
    colEx('lineType', 'selection', undefined, asLocalizedSelectionValues(jobPlanningLineLineType)),   // : "Budget",
    colEx('type', 'selection', undefined, asLocalizedSelectionValues(jobPlanningLineType)),   // : "Resource",
    colEx('number'),   // : "30_24449",
    colEx('itemId'),   // : "00000000-0000-0000-0000-000000000000",
    col('resourceId'),   // : "5ec8d329-2b4b-ee11-be6d-6045bd16a00c",
    colEx(FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION),   // : "",
    col(FIELD_CATEGORY_ID),   // workTypeId : "00000000-0000-0000-0000-000000000000",
    col('workTypeCode'),   // workTypeCode : "",
    col(FIELD_DESCRIPTION1),   // displayName : "Eig.leist Ma Gerüstbau II",
    col(FIELD_DESCRIPTION2),   // displayName2 : "",
    col('calculateQuantity', 'selection',),   // : " ",
    col(FIELD_AMOUNT, 'number'),   //quantity : 8.53,
    colEx('quantityMultiplier', 'number'),   // : 1,
    //col('startingTime'),   // : "00:00:00",
    //col('endingTime'),   // : "00:00:00",
    col(FIELD_START_TIME, 'date', L('PlanningDate')),   //planningDate : "2024-01-16",
    col(FIELD_END_TIME, 'date'),   //toFinishUntil : "2024-01-16",
    col('statusTimeScheduling'),   // : " ",
    col('statusJpl'),   // : "Order",
    colEx('unitPrice', 'number'),   // : 1.272,
    colEx('directCost', 'number'),   // directUnitCost : 0.645,
    col('unitCost', 'number'),   // : 0.65,
    colEx('lineAmount', 'number'),   // : 10.85,
    col('jobContractEntryNumber'),   // : 282744,
    colEx(FIELD_UNIT_OF_MEASURE_ID),   // : "0311c1e0-24b8-ed11-9a88-00224874f10d",
    colEx(FIELD_UNIT_OF_MEASURE_CODE),   // : "MIN",
    colEx('shipToCode'),   // : "",
    colEx('shipToId'),   // : "00000000-0000-0000-0000-000000000000",
    col(FIELD_CUSTOMER_ID),   // : "ed7e091c-6e64-ee11-8def-0022480c80c6",
    colEx('customerNumber'),   // : "D00342"
    colEx('locationCode'),   // : "MI",
    colEx('locationId'),   // : "00000000-0000-0000-0000-000000000000",
    colEx('binCode'),   // : "L-01-01",
    colEx('binId'),   // : "00000000-0000-0000-0000-000000000000"
]

export const jobTaskTransferLinesColumns = [
    col(FIELD_ID),   // : "ef9f09f5-0eb5-ee11-a568-0022485b0040",
    col('jobNumber'),   // : "",
    col(FIELD_JOB_ID),   // : "00000000-0000-0000-0000-000000000000",
    col('jobTaskNumber'),   // : "",
    col(FIELD_JOB_TASK_ID),   // : "00000000-0000-0000-0000-000000000000",
    col('itemNumber'),   // : "",
    col('itemId'),   // : "00000000-0000-0000-0000-000000000000",
    col(FIELD_DESCRIPTION1),   // displayName : "",
    col(FIELD_DESCRIPTION2),   // displayName2 : "",
    col('transferFromBinCode'),   // : "",
    col('transferFromBinId'),   // : "00000000-0000-0000-0000-000000000000",
    col('transferToBinCode'),   // : "",
    col('transferToBinId'),   // : "00000000-0000-0000-0000-000000000000",
    col('quantity', 'number'),   // : 1
]

export const defaultColumnConfig = {
    job: chooseColumns([
        'templateDescription',
        FIELD_DESIGNATION,
        'organisationAttributeCode',
        FIELD_DESCRIPTION1,
        FIELD_DESCRIPTION2,
        'attachedToItemNumber',
        'sellToCustomerNumber',
        // 'statusPlanningDate',
        // 'creationDate',
        'startDate',
        'endDate',
        // 'allowScheduleContractLines',
        'blockJobJournalEditing',
        'blockJobJournalPosting',
        'applyUsageLink',
        'copiedFromJobNumber',
    ], jobColumns),
    jobTask: chooseColumns([
        // 'jobNumber',
        FIELD_DESIGNATION,
        'jobTaskType',
        FIELD_DESCRIPTION1,
        'searchDescription1CategoryCode',
        'quantityMultiplier',
        'taskPersonDelegated',
        FIELD_JOB_TYPE_CODE,
        'attachedToPositiveItemAdjmt',
        'jobTaskNumber2',
        'processingStatus',
        'delegatedToICP',
        // 'setupTimeConnectionCode',
        'planningDate',
        'toFinishUntil'
    ], jobTaskColumns),
    jobTaskTransfer: chooseColumns([
        'transferFromLocationCode',
        'transferToLocationCode',
        // 'inTransitLocationCode',
        'directTransfer',
        'useQuantity',
        'autoTransferOnStatusChange',
        'autoTransferForStatus',
        'itemReclassJnlTemplateName',
        'itemReclassJnlBatchName'
    ], jobTaskColumns),
    jobTaskTransferLines: chooseColumns([
        // 'jobNumber',
        // 'jobTaskNumber',
        'itemNumber',
        // 'itemId',
        FIELD_DESCRIPTION1,
        FIELD_DESCRIPTION2,
        'transferFromBinCode',
        'transferToBinCode',
        'quantity',
    ], jobTaskTransferLinesColumns),
    jobType: chooseColumns([
        FIELD_DESIGNATION,
        FIELD_DESCRIPTION1,
        FIELD_DESCRIPTION2,
        'type',
        // 'allowBlankJobJournalLine',
        // 'allowBudgetJobJournalLine',
        // 'allowBillableJobJournalLine',
        // 'allowBudgetBillableJobJournalLine',
        // 'resourceJobJournalLineJobLedgerEntryType',
        // 'itemJobJournalLineJobLedgerEntryType',
        // 'glAccountJobJournalLineJobLedgerEntryType',
        // 'resourceJobJournalLineLedgerEntryType',
        // 'itemJobJournalLineLedgerEntryType',
        // 'allowBudgetJobPlanningLine',
        // 'allowBillableJobPlanningLine',
        // 'allowBudgetBillableJobPlanningLine',
        // 'allowBillableJobPlanningLineInsertion',
        // 'blockPlanning',
        // 'salesQuoteToOrder',
        // 'blockOrderPosting',
        'autoAdjustmentForStatus',
    ], jobTypeColumns),
    jobTypeLines: chooseColumns([
        'jobTypeCode',
        FIELD_POSTING_TYPE,
        'itemNumber',
        FIELD_DESCRIPTION1,
        FIELD_DESCRIPTION2,
        'useItemSubstitution',
        'useQuantity',
        'quantity',
        'locationCode',
        'binCode',
        'resourceNumber',
        'resourceUnitOfMeasureCode',
        'setupTime',
    ], jobTypeLinesColumns),
    jobTypePosAdj: chooseColumns([
        'positiveItemAdjustment',
        'positiveItemJournalTemplateName',
        'positiveItemJournalBatchName',
        'positiveJobJournalTemplateName',
        'positiveJobJournalBatchName',
        'positiveUseDimensions',
        'positivePriceCalculation',
        'positiveCreateJobLedgerEntry',
        'positiveNegativeJobLedgerEntry',
        'positiveUseScrap',
        'positiveUseSetupTime'
    ], jobTypeAdjColumns),
    jobTypeNegAdj: chooseColumns([
        'negativeItemAdjustment',
        'negativeItemJournalTemplateName',
        'negativeItemJournalBatchName',
        'negativeJobJournalTemplateName',
        'negativeJobJournalBatchName',
        'negativeUseDimensions',
        'negativePriceCalculation',
        'negativeCreateJobLedgerEntry',
        'negativeNegativeJobLedgerEntry',
        'negativeUseScrap'
    ], jobTypeAdjColumns),
    jobPlanningLines: chooseColumns([
        // 'jobNumber',
        // 'jobTaskNumber',
        'lineNumber',
        'lineType',
        'type',
        'number',
        // 'itemId',
        // 'resourceId',
        FIELD_AMOUNT,
        FIELD_START_TIME,
        // 'quantityMultiplier'
        'locationCode',
        'binCode'
    ], jobPlanningLinesColumns)
} as PlmItemColumnConfig;

