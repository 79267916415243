import { Fade, Slide } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup, NotificationProps } from '@progress/kendo-react-notification';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { arrayBuffer } from 'stream/consumers';
import AppEvents from '../../../module-components/AppEvents';
import { log } from '../../../util/LoggingUtils';
import { DateTime } from 'luxon';
import './NotificationHost.scss';

export type AnimationStyle = 'fade' | 'slide';

export type NotificationItem = NotificationProps & {
    timeout?: number;
    animationStyle?: AnimationStyle;
    position: keyof typeof positions;
}

const positions = {
    topLeft: {
        top: '.5rem',
        left: '.5rem',
        alignItems: "flex-start",
    },
    topCenter: {
        top: '.5rem',
        left: "50%",
        transform: "translateX(-50%)",
    },
    topRight: {
        top: '.5rem',
        right: '.5rem',
        alignItems: "flex-end",
    },
    bottomLeft: {
        bottom: '.5rem',
        left: '.5rem',
        alignItems: "flex-start",
    },
    bottomCenter: {
        bottom: '.5rem',
        left: "50%",
        transform: "translateX(-50%)",
    },
    bottomRight: {
        bottom: '.5rem',
        right: '.5rem',
        alignItems: "flex-end",
    },
};

const ItemWrapper: React.FC<PropsWithChildren<{ animationStyle?: AnimationStyle, timeout?: number }>> = ({ animationStyle, timeout, children }) => {

    const [remainingTime, setRemainingTime] = useState(timeout);

    const updateProgress = useCallback(() => {
        setRemainingTime(t => Math.max(0, t! - 100));
    }, [setRemainingTime]);

    useEffect(() => {
        if (timeout && timeout > 0) {
            const interval = setInterval(function () { updateProgress(); }, 100);

            return () => { clearInterval(interval); }
        }
    }, [timeout, updateProgress]);

    // if (animationStyle === 'fade') {
    //     return <Fade>
    //         {children}
    //     </Fade>
    // }

    // if (animationStyle === 'slide') {
    //     return <Slide direction="up">
    //         {children}
    //     </Slide>
    // }

    return <div className="notification-wrapper">{children}
        {timeout && <div className="notification-progress"
            style={{
                right: `${(100.00 - ((remainingTime ?? 1) / (timeout ?? 1) * 100)).toFixed(2)}%`,
                borderBottomRightRadius: (remainingTime ?? 1) < (timeout ?? 1) ? '0px' : '16px'
            }} />}
    </div>;
}

export const showNotificationFromItem = (item: NotificationItem) => {
    abp.event.trigger(AppEvents.Notification_Published, item);
};

export const showNotification = (title?: string, content?: string, style?: 'none' | 'success' | 'error' | 'warning' | 'info'
    , timeoutMs?: number, closable: boolean = true, position: keyof typeof positions = 'bottomRight') => {
    abp.event.trigger(AppEvents.Notification_Published, {
        children: <>
            {title && <h5 className="mb-2">{title}</h5>}
            {content && <span>{content}</span>}
            {(style === 'error' || style === 'warning') && <div className="text-muted mt-2">{DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>}
        </>,
        type: { style },
        timeout: timeoutMs,
        closable,
        position
    } as NotificationItem);
};


const NotificationHost: React.FC<{ position?: keyof typeof positions, style?: React.CSSProperties }> = ({ position = 'bottomRight', style = {} }) => {

    const [items, setItems] = useState<NotificationItem[]>([]);

    const removeNotification = useCallback((item: NotificationItem) => {
        setItems(i => {
            const index = i.indexOf(item);
            const newItems = i.slice();
            if (index > -1) {
                newItems.splice(index, 1);
            }

            return newItems;
        });
    }, [setItems]);

    const handleNotificationEvent = useCallback((item: NotificationItem) => {
        log("Notification ", item, position);

        // Ignore elements from other positions
        if (item.position !== position) return;

        setItems(i => [...i, item]);
        if (item.timeout) {
            setTimeout(() => removeNotification(item), item.timeout);
        }
    }, [position, setItems, removeNotification]);

    useEffect(() => {
        abp.event.on(AppEvents.Notification_Published, handleNotificationEvent);

        return () => { abp.event.off(AppEvents.Notification_Published, handleNotificationEvent); }
    }, [handleNotificationEvent]);

    return <div className="notification-host">
        <NotificationGroup style={{ ...positions[position], ...style }}>
            {items.map((item, i) => {
                const { animationStyle, timeout, onClose, ...notificationProps } = item;
                return <ItemWrapper animationStyle={animationStyle} timeout={timeout} key={i}>
                    <Notification {...notificationProps} onClose={(e) => {
                        onClose && onClose(e);
                        removeNotification(item);
                    }} />
                </ItemWrapper>
            })}
        </NotificationGroup>
    </div>
}

export default NotificationHost;