import React, { useCallback, useMemo } from 'react';
import { selectPlmControlSortProperty, PlmDataControlProps, isTemplateFilter, RowSkeletons } from './components/PlmControlComponents';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_ID, FIELD_IS_TEMPLATE, FIELD_JOB_ID, FIELD_JOB_TASK_ID } from '../../../../data/field-constants/GeneralConstants';
import { JobTaskTransferLineApi, JobTaskTransferLineDto } from '../../../../client/http';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import filter from '../../../../util/FilterUtils';
import _ from 'lodash';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import { NEW_ENTITY_ID } from '../../../../util/EntityUtils';

export type PlmJobTaskTransferLineListProps = {
    jobNumber: string;
    jobTaskNumber: string;
    jobId: string;
    jobTaskId: string;
}

export type PlmJobTaskTransferLineControlProps = PlmDataControlProps & {
    jobId: string;
    jobTaskId: string;
    jobTaskTransferLine: JobTaskTransferLineDto;
}


const PlmJobTaskTransferLineControl: React.FC<PlmJobTaskTransferLineControlProps> = ({ jobId, jobTaskId, jobTaskTransferLine, refetch }) => {

    const { dispatch, showTemplates } = usePlmControlContext();

    const [internalJobTaskTransferLine, , handleChange, handleAction] = useInternalPlmControlData<JobTaskTransferLineDto, JobTaskTransferLineApi>(
        jobTaskTransferLine,
        {
            [FIELD_JOB_ID]: jobId,
            [FIELD_JOB_TASK_ID]: jobTaskId,
            [FIELD_IS_TEMPLATE]: showTemplates === true
        },
        'jobTaskTransferLines',
        c => new JobTaskTransferLineApi(c),
        c => c.apiServicesModulesSdtPlmJobTaskTransferLineCreatePost,
        c => c.apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut,
        c => c.apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete,
        refetch
    );

    // const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {

    //     // fetch items on expand
    //     if (args.type === 'expanded/changed' && args.payload.value === true) {
    //         // fetchJobTasks();
    //     }
    //     dispatch(args);
    // }, [dispatch]);

    return <PlmColumnDataDisplay item={internalJobTaskTransferLine} section="jobTaskTransferLines" level={4} className="plm-job-type-line-item"
        onChange={handleChange} onAction={handleAction} />

}

const PlmJobTaskTransferLineList: React.FC<PlmJobTaskTransferLineListProps> = ({ jobNumber, jobTaskNumber, jobId, jobTaskId }) => {

    const { controlState, fetchFromAllProviders, readOnly, showTemplates } = usePlmControlContext();


    const internalJobTaskTransferLineControlStateFilters = controlState?.jobTaskTransferLines?.filter?.internalFilters;
    const internalJobTaskTransferLineFilters = useMemo(() => [
        ...internalJobTaskTransferLineControlStateFilters,
        ...isTemplateFilter(showTemplates),
        filter(FIELD_JOB_TASK_ID).equals(jobTaskId)
    ], [internalJobTaskTransferLineControlStateFilters, showTemplates, jobTaskId]);

    const externalJobTaskTransferLineFilters = controlState?.jobTaskTransferLines?.filter?.externalFilters;

    const getAll = useCallback((c: JobTaskTransferLineApi) => c.apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(
        fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
        fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
        JSON.stringify(internalJobTaskTransferLineFilters),
        JSON.stringify(externalJobTaskTransferLineFilters)
    ), [fetchFromAllProviders, internalJobTaskTransferLineFilters, externalJobTaskTransferLineFilters]);

    const [jobTaskTransferLines, fetchJobTaskTransferLines, addRow, isLoading] = useInternalPlmListData<JobTaskTransferLineDto, JobTaskTransferLineApi>(
        c => new JobTaskTransferLineApi(c),
        getAll,
        'jobTaskTransferLines',
        {
            'jobNumber': jobNumber,
            'jobTaskNumber': jobTaskNumber
        }
    );

    return <>
        <PlmColumnHeaderDisplay section="jobTaskTransferLines" level={3} onAction={addRow} readOnly={readOnly || jobId === NEW_ENTITY_ID || jobTaskId === NEW_ENTITY_ID}
            hasNoData={!isLoading && !jobTaskTransferLines?.length} noDataLabel="Keine Umlagerungszeilen vorhanden" />

        {jobTaskTransferLines?.map((jpl) => {
            return (
                <PlmJobTaskTransferLineControl jobId={jobId} jobTaskId={jobTaskId} jobTaskTransferLine={jpl}
                    refetch={fetchJobTaskTransferLines} key={getIn(jpl, FIELD_ID)} />
            )
        })}

        {isLoading && <RowSkeletons level={4} />}
    </>
}

export default PlmJobTaskTransferLineList;