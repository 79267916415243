import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { FileExplorerDialogProps, FilterState, PreviewState, SortState } from './FileExplorerDialogTypes';
import FileSystemManager, { FileSystemEntry } from 'orgavision-rewind365-sdt-fsmanager';
import { NavigationToolbar } from './components/NavigationToolbar';
import { BreadcrumbNavigation } from './components/BreadcrumbNavigation';
import { FilterToolbar } from './components/FIlterToolbar';
import { Splitter } from '@progress/kendo-react-layout';
import { FileSystemGrid } from './components/FileSystemGrid';
import { FilePreviewPane } from './components/FilePreviewPane';

import './FileExplorerDialog.scss';

export const FileExplorerDialog: React.FC<FileExplorerDialogProps> = ({
    baseUrl,
    visible,
    onClose,
    onSelect,
    initialPath,
    allowedExtensions,
    title = 'File Explorer',
    width = '80vw',
    height = '80vh'
}) => {

    // State management    
    const [filterState, setFilterState] = useState<FilterState>({
        nameFilter: '',
        extensionFilter: null
    });


    const [sortState, setSortState] = useState<SortState>({
        field: 'name',
        direction: 'asc'
    });

    const [previewState, setPreviewState] = useState<PreviewState>({
        visible: true,
        file: null
    });

    // Initialize FileSystemManager with useMemo
    const fsManager = useMemo(() => new FileSystemManager(
        baseUrl
    ), [baseUrl]);

    const [results, setResults] = useState<FileSystemEntry[]>([]);

    // Error handling state
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    // Handle filtering and sorting

    const applyFilters = useCallback((filterState: FilterState) => {
        if (filterState.nameFilter) {
            fsManager.search(filterState.nameFilter);
        }
        if (filterState.extensionFilter) {
            console.log(filterState);
            fsManager.filterByExtension(filterState.extensionFilter);
        }
    }, [fsManager]);

    const applySorting = useCallback((sortState: SortState) => {
        switch (sortState.field) {
            case 'date':
                fsManager.sortByDate(sortState.direction === 'asc');
                break;
            case 'name':
                fsManager.sortByName(sortState.direction === 'asc');
                break;
            case 'size':
                fsManager.sortBySize(sortState.direction === 'asc');
                break;
            default:
                break;
        }
    }, [fsManager])

    const setFilter = useCallback((filterState: FilterState) => {
        console.log(filterState)
        fsManager.resetData();
        applyFilters(filterState);
        applySorting(sortState)
        setFilterState(filterState);
        setResults(fsManager.getResults());
    }, [sortState, fsManager, applyFilters, applySorting]);

    const setSorting = useCallback((sortState: SortState) => {
        fsManager.resetData();
        applyFilters(filterState);
        applySorting(sortState);
        setSortState(sortState);
        setResults(fsManager.getResults());
    }, [filterState, fsManager, applyFilters, applySorting]);

    // Initialize file system
    useEffect(() => {
        const initializeFileSystem = async () => {
            if (visible) {
                setLoading(true);
                try {
                    await fsManager.initialize(initialPath);
                    setResults(fsManager.getResults());
                    setError(null);
                } catch (err) {
                    setError('Failed to initialize file system');
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            }
        };

        initializeFileSystem();
    }, [visible, fsManager, initialPath]);

    // Navigation handlers
    const handleNavigate = useCallback(async (path: string) => {
        setLoading(true);
        try {
            await fsManager.navigateTo(path);
            setResults(fsManager.getResults());
            setError(null);
        } catch (err) {
            setError('Navigation failed');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [fsManager]);

    const handleAction = useCallback(async (action: ((fsManager: FileSystemManager) => Promise<FileSystemEntry[] | null>)) => {
        setLoading(true);
        try {
            await action(fsManager);
            setResults(fsManager.getResults());
            setError(null);
        } catch (err) {
            setError('Navigation failed');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [fsManager]);

    const handleSelect = useCallback((path: string | null) => {
        if (path && allowedExtensions) {
            const extension = path.split('.').pop()?.toLowerCase();
            if (!extension || !allowedExtensions.includes(`.${extension}`)) {
                setError('File type not allowed');
                return;
            }
        }
        onSelect(path);
        onClose();
    }, [allowedExtensions, onSelect, onClose]);

    return (
        visible ?
            <Dialog
                title={title}
                onClose={onClose}
                height={height}
                width={width}
            >
                <div className="dialog-content">
                    <div className="dialog-header">

                        <NavigationToolbar
                            onBack={() => handleAction((fsm) => fsm.navigateBack())}
                            onForward={() => handleAction((fsm) => fsm.navigateForward())}
                            onUp={() => handleAction((fsm) => fsm.navigateUp())}
                            canGoBack={fsManager.canGoBack()}
                            canGoForward={fsManager.canGoForward()}
                            currentPath={fsManager.getCurrentPath()}
                        />

                        <BreadcrumbNavigation
                            paths={fsManager.getBreadcrumbPaths()}
                            onNavigate={() => { }}
                            disabled={true}
                        />

                        <FilterToolbar
                            filterState={filterState}
                            onFilterChange={setFilter}
                            availableExtensions={fsManager.getStatistics().fileTypes}
                        />
                    </div>

                    <div className="dialog-data">
                        {previewState.visible ?
                            <Splitter style={{ height: '100%' }}>
                                <FileSystemGrid
                                    entries={results}
                                    sortState={sortState}
                                    onSortChange={setSorting}
                                    onSelect={handleSelect}
                                    onPreview={file => setPreviewState(prev => ({ ...prev, visible: true, file }))}
                                    onNavigate={handleNavigate}
                                    loading={loading}
                                    error={error}
                                />
                                <FilePreviewPane
                                    file={previewState.file}
                                    onClose={() => setPreviewState(prev => ({ ...prev, visible: false }))}
                                />
                            </Splitter>
                            : <FileSystemGrid
                                entries={results}
                                sortState={sortState}
                                onSortChange={setSortState}
                                onSelect={handleSelect}
                                onPreview={file => setPreviewState(prev => ({ ...prev, visible: true, file }))}
                                onNavigate={handleNavigate}
                                loading={loading}
                                error={error}
                            />
                        }
                    </div>
                </div>
            </Dialog>
            : <></>
    );
};