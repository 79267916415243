import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Icon, { IconName } from '../../../common/components/icon/Icon';
import './SideNav.scss';
import SideNavEntry from './SideNavEntry';
import ServiceProviderSelection from './service-provider-selection/ServiceProviderSelection';
import { useModuleArrayData } from '../../../module-components/ModuleDataProvider';
import RewindLogo from '../../../modules/rewind-core/application/RewindLogo';
import AppVersion from '../../../common/components/app-version/AppVersion';
import { AppParts } from '../../../modules/AppParts';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { L } from '../../../abp/utils';
import { Features } from '../../../data/field-constants/Features';
import { Permissions } from '../../../data/field-constants/Permissions';
import IconButton from '../../../common/components/icon/IconButton';
import { max } from 'lodash';
import { useOnClickOutside } from 'usehooks-ts';
import classNames from 'classnames';

export type Action = {
  iconClassName?: IconName,
  caption: string,
  destination: string,
  children?: Action[],
  keepActiveOnChildren?: boolean,
  feature?: string,
  permission?: string,
}

export const NavActions = (localize: (key: string, sourceName?: string) => string): Action[] => [
  {
    iconClassName: 'icon-smart-home-checked',
    caption: localize("Home"),
    destination: '/app/home',
    feature: Features.Application.Homepage,
    children: [{
      caption: localize("MyOverview"),
      destination: '/app/home/overview'
    }]
  },
  {
    iconClassName: 'icon-today',
    caption: localize("TimePlanning"),
    destination: '/app/time-planning',
    feature: Features.TimeManagement.TimePlanning,
    children: [{
      caption: localize("AppointmentPlanning"),
      destination: '/app/time-planning/entity'
    },
    {
      caption: localize("ProjectPlanning"),
      destination: '/app/time-planning/job'
    }]
  },
  {
    iconClassName: 'icon-clock',
    caption: localize("TimeTracking"),
    destination: '/app/time-tracking',
    feature: Features.TimeManagement.TimeTracking,
    children: [
      //   {
      //   caption: localize("SessionOverview"),
      //   destination: '/app/time-tracking/sessions'
      // },
      {
        caption: localize("TimeEntities"),
        destination: '/app/time-tracking/entities'
      }]
  },
  {
    iconClassName: 'icon-graph-report',
    caption: localize("Reports"),
    destination: '/app/reporting',
    feature: Features.Reporting.Feature,
    children: [
      {
        caption: localize("CreateReport"),
        destination: '/app/reporting/create'
      },
      {
        caption: localize("EditReport"),
        destination: '/app/reporting/edit',
        keepActiveOnChildren: true
      },
      // {
      //   caption: localize("ReportManagement"),
      //   destination: '/app/reporting/management'
      // }
    ]
  },
  {
    iconClassName: 'icon-vertical-settings-mixer',
    caption: localize("Settings"),
    destination: '/app/settings',
    feature: Features.SettingsManagement.Feature
  },
  {
    iconClassName: 'icon-test-partial-passed',
    caption: localize("Administration"),
    destination: '/app/administration',
    feature: Features.Administration.Feature,
    permission: Permissions.Administration.Permission,
    children: [
      {
        caption: localize("Application"),
        destination: '/app/administration/core'
      }
    ]
  }
];

export const mergeNavActions = (navActions: Action[], moduleActions: Action[]) => {
  if (!moduleActions || moduleActions.length < 1)
    return navActions;

  for (const moduleAction of moduleActions) {
    const existingAction = navActions.find(a => a.caption === moduleAction.caption);
    if (existingAction) {
      if (moduleAction.children && moduleAction.children.length > 0) {
        const newChildren = moduleAction.children.filter(c => !existingAction.children?.find(x => x.caption === c.caption));
        existingAction.children = existingAction.children ? [...existingAction.children, ...newChildren] : [...moduleAction.children]
      }
    } else {
      navActions = [...navActions, moduleAction];
    }
  }

  return navActions;
}

const SideNav: React.FC<{ collapsed?: boolean, shouldCollapse?: boolean, collapsedWidth?: string, onCollapsedChanged?: (collapsed: boolean) => void }> = ({ collapsed = false, shouldCollapse = false, collapsedWidth = '100px', onCollapsedChanged }) => {
  const { pathname } = useLocation();
  const { data } = useModuleArrayData('navigation/navItems.json');
  // const [collapsed, setCollapsed] = useState(shouldCollapse);
  const asideRef = useRef<HTMLElement>(null);

  const onClickOutside = useCallback((e: any) => {
    // Do not close when selecting a service connection
    const elementClasses = [...(e?.target.classList ?? [])]
    const targetParentClasses = [...(e?.target?.parentElement?.classList ?? [])];

    if ((elementClasses && elementClasses.includes && elementClasses.includes('service-provider')) ||
      (targetParentClasses && targetParentClasses.includes && targetParentClasses.includes('service-connection'))) {
      return;
    }

    if (!collapsed && shouldCollapse) {
      onCollapsedChanged && onCollapsedChanged(true);
    }
  }, [collapsed, shouldCollapse, onCollapsedChanged]);
  useOnClickOutside(asideRef, onClickOutside);

  const navActions = mergeNavActions([...NavActions(L)], [...data]);
  const renderPathRecursive = useCallback((a: Action, i: any, isTopLevel = false) => {

    if (a.feature) {
      const featureEnabled = abp.features.getValue(a.feature) === "true";
      if (!featureEnabled) {
        return null;
      }
    }

    if (a.permission) {
      const hasPermission = abp.auth.isGranted(a.permission);
      if (!hasPermission) {
        return null;
      }
    }

    const selected = pathname.includes(a.destination);
    return (<SideNavEntry action={a} index={i} active={a.destination === pathname || (pathname.startsWith(a.destination) && a.keepActiveOnChildren === true)}
      selected={selected} isTopLevel={isTopLevel} navCollapsed={collapsed} key={i}>
      {a.children && a.children.map((action, index) => renderPathRecursive(action, `${i}.${index}`))}
    </SideNavEntry>);
  }, [pathname, collapsed])

  return (
    <aside className={`${!collapsed ? 'col-2 ' : ''}side-nav${!collapsed && shouldCollapse ? ' expanded' : ''}`} style={collapsed ? { width: collapsedWidth } : (shouldCollapse ? { width: 'min(100%, 350px)' } : undefined)} ref={asideRef}>
      <div className="side-nav-outter">
        <div className="side-nav-wrapper">


          <nav className={`${collapsed ? 'collapsed' : ''}`}>
            <div className="top">
              {/* <header onClick={() => navigate('/')}>
          {constants.customer === "ZWF" ?
          :
          <Rewind365Logo />
          }
          <h1>Zeitmanagement</h1>
        </header> */}
              {collapsed ?
                <ModuleComponentProvider appPartName={AppParts.Application.CollapsedLogo} getSingleComponent={true} /> :
                <ModuleComponentProvider appPartName={AppParts.Application.MainLogo} getSingleComponent={true} />}
              <ul className="nav sidenav flex-column">
                {navActions.map((a, i) => renderPathRecursive(a, i.toString(), true))}
              </ul>
            </div>
            <div className="bottom">
              <ServiceProviderSelection className={collapsed ? 'visually-hidden' : ''} />
              <div className={classNames("col", "footer-wrapper")}>
                <AppVersion className={collapsed ? 'visually-hidden' : ''} />
                {collapsed ? <div onClick={() => onCollapsedChanged && onCollapsedChanged(false)} className="d-flex justify-content-center">
                  <Icon name="icon-double-right" size="2rem" />
                </div> :
                  <div onClick={() => onCollapsedChanged && onCollapsedChanged(true)} className="d-flex">
                    <Icon name="icon-double-right" style={{ transform: 'scaleX(-1)' }} size="2rem" />
                  </div>}
              </div>

            </div>
          </nav>
        </div>
      </div>
    </aside >
  );
};

export default SideNav;
