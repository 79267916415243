import React, { useMemo } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { FilterState } from '../FileExplorerDialogTypes';
import IconButton from '../../icon/IconButton';

interface FilterToolbarProps {
    filterState: FilterState;
    onFilterChange: (state: FilterState) => void;
    availableExtensions: Record<string, number>;
}

export const FilterToolbar: React.FC<FilterToolbarProps> = ({
    filterState,
    onFilterChange,
    availableExtensions
}) => {
    const extensionOptions = useMemo(() => {
        return [
            { text: 'All Files', value: null },
            ...Object.entries(availableExtensions).map(([ext, count]) => ({
                text: `${ext.toUpperCase()} (${count})`,
                value: ext
            }))
        ];
    }, [availableExtensions]);

    return (
        <div className="dialog-filters">
            <Input
                placeholder="Filter by name..."
                value={filterState.nameFilter}
                onChange={(e) => onFilterChange({
                    ...filterState,
                    nameFilter: e.value
                })}
                style={{ width: '200px' }}
            />
            <DropDownList
                data={extensionOptions}
                textField="text"
                dataItemKey="value"
                value={extensionOptions.find(o => o.value === filterState.extensionFilter)}
                onChange={(e) => onFilterChange({
                    ...filterState,
                    extensionFilter: e.value.value
                })}
                style={{ width: '150px' }}
            />
            <IconButton
                name="icon-trash"
                onClick={() => onFilterChange({
                    nameFilter: '',
                    extensionFilter: null
                })}
                disabled={!filterState.nameFilter && !filterState.extensionFilter}
                title="Clear filters"
            />
        </div>
    );
};