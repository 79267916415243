//@ts-nocheck
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Rewind365 API
 * Rewind365
 *
 * OpenAPI spec version: v1
 * Contact: info@mail.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AdministrationDataDto
 */
export interface AdministrationDataDto {
    /**
     * 
     * @type {Array<TenantDataDto>}
     * @memberof AdministrationDataDto
     */
    tenants?: Array<TenantDataDto>;
    /**
     * 
     * @type {EmailConfiguration}
     * @memberof AdministrationDataDto
     */
    emailConfiguration?: EmailConfiguration;
}
/**
 * 
 * @export
 * @interface ApplicationInfoDto
 */
export interface ApplicationInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationInfoDto
     */
    version?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationInfoDto
     */
    releaseDate?: Date;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ApplicationInfoDto
     */
    features?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface Assembly
 */
export interface Assembly {
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof Assembly
     */
    definedTypes?: Array<TypeInfo>;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Assembly
     */
    exportedTypes?: Array<Type>;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    codeBase?: string;
    /**
     * 
     * @type {MethodInfo}
     * @memberof Assembly
     */
    entryPoint?: MethodInfo;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    imageRuntimeVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    isDynamic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    location?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    reflectionOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    isFullyTrusted?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Assembly
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    escapedCodeBase?: string;
    /**
     * 
     * @type {Module}
     * @memberof Assembly
     */
    manifestModule?: Module;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Assembly
     */
    modules?: Array<Module>;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    globalAssemblyCache?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Assembly
     */
    hostContext?: number;
    /**
     * 
     * @type {SecurityRuleSet}
     * @memberof Assembly
     */
    securityRuleSet?: SecurityRuleSet;
}
/**
 * 
 * @export
 * @interface AttachFileToJobInput
 */
export interface AttachFileToJobInput {
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachFileToJobInput
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    attachedToId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    binaryObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    targetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    mimeType?: string;
    /**
     * 
     * @type {Date}
     * @memberof AttachFileToJobInput
     */
    validTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof AttachFileToJobInput
     */
    accessRules?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachFileToJobInput
     */
    propagateToTasks?: boolean;
}
/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    attachedToType?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    attachedToId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    binaryObjectId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentDto
     */
    isBinaryObject?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    targetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    mimeType?: string;
    /**
     * 
     * @type {Date}
     * @memberof AttachmentDto
     */
    validTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    accessRules?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    extensionData?: string;
}
/**
 * 
 * @export
 * @interface AttachmentDtoPagedResultDto
 */
export interface AttachmentDtoPagedResultDto {
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof AttachmentDtoPagedResultDto
     */
    items?: Array<AttachmentDto>;
    /**
     * 
     * @type {number}
     * @memberof AttachmentDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface AuthenticateModel
 */
export interface AuthenticateModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateModel
     */
    userNameOrEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateModel
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticateModel
     */
    rememberClient?: boolean;
}
/**
 * 
 * @export
 * @interface AuthenticateResultModel
 */
export interface AuthenticateResultModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResultModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResultModel
     */
    encryptedAccessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateResultModel
     */
    refreshToken?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateResultModel
     */
    expireInSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateResultModel
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface AuthorizeApiResult
 */
export interface AuthorizeApiResult {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeApiResult
     */
    location?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CallingConventions {
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_32 = <any> 32,
    NUMBER_64 = <any> 64
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof Category
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    color?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof Category
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    extensionData?: string;
    /**
     * 
     * @type {Array<TimeTrackingSession>}
     * @memberof Category
     */
    assignedSessions?: Array<TimeTrackingSession>;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    color?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof CategoryDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    extensionData?: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ChangeUiThemeInput
 */
export interface ChangeUiThemeInput {
    /**
     * 
     * @type {string}
     * @memberof ChangeUiThemeInput
     */
    theme: string;
}
/**
 * 
 * @export
 * @interface ChangeUserLanguageDto
 */
export interface ChangeUserLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserLanguageDto
     */
    languageName: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ChartingTimeInterval {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_9 = <any> 9,
    NUMBER_10 = <any> 10,
    NUMBER_11 = <any> 11,
    NUMBER_12 = <any> 12
}
/**
 * 
 * @export
 * @interface ConstructorInfo
 */
export interface ConstructorInfo {
    /**
     * 
     * @type {string}
     * @memberof ConstructorInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof ConstructorInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ConstructorInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConstructorInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof ConstructorInfo
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof ConstructorInfo
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof ConstructorInfo
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof ConstructorInfo
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof ConstructorInfo
     */
    memberType?: MemberTypes;
}
/**
 * 
 * @export
 * @interface CreateAttachmentDto
 */
export interface CreateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    attachedToType?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    attachedToId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    binaryObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    targetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    mimeType?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateAttachmentDto
     */
    validTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentDto
     */
    accessRules?: string;
}
/**
 * 
 * @export
 * @interface CreateFromTemplateInputDto
 */
export interface CreateFromTemplateInputDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFromTemplateInputDto
     */
    templateJobId?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateFromTemplateInputDto
     */
    replacementValues?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface CreateMultiMappingInputDto
 */
export interface CreateMultiMappingInputDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMultiMappingInputDto
     */
    serviceProviderName?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMultiMappingInputDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMultiMappingInputDto
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMultiMappingInputDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMultiMappingInputDto
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMultiMappingInputDto
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMultiMappingInputDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface CreateReportInput
 */
export interface CreateReportInput {
    /**
     * 
     * @type {number}
     * @memberof CreateReportInput
     */
    templateId: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateReportInput
     */
    reportDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateReportInput
     */
    reportTitle: string;
    /**
     * 
     * @type {number}
     * @memberof CreateReportInput
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateReportInput
     */
    serviceProviderName?: string;
    /**
     * 
     * @type {ReportDataEntryDto}
     * @memberof CreateReportInput
     */
    reportData: ReportDataEntryDto;
}
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    normalizedName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    grantedPermissions?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateTenantDto
 */
export interface CreateTenantDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    tenancyName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    adminEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    connectionString?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTenantDto
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    emailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserDto
     */
    roleNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface CreateUserInput
 */
export interface CreateUserInput {
    /**
     * 
     * @type {string}
     * @memberof CreateUserInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInput
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInput
     */
    emailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserInput
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserInput
     */
    roleNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInput
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInput
     */
    mappingName?: string;
    /**
     * 
     * @type {Array<CreateMultiMappingInputDto>}
     * @memberof CreateUserInput
     */
    mappingInputs?: Array<CreateMultiMappingInputDto>;
}
/**
 * 
 * @export
 * @interface CustomAttributeData
 */
export interface CustomAttributeData {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeData
     */
    attributeType?: Type;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof CustomAttributeData
     */
    constructor?: ConstructorInfo;
    /**
     * 
     * @type {Array<CustomAttributeTypedArgument>}
     * @memberof CustomAttributeData
     */
    constructorArguments?: Array<CustomAttributeTypedArgument>;
    /**
     * 
     * @type {Array<CustomAttributeNamedArgument>}
     * @memberof CustomAttributeData
     */
    namedArguments?: Array<CustomAttributeNamedArgument>;
}
/**
 * 
 * @export
 * @interface CustomAttributeNamedArgument
 */
export interface CustomAttributeNamedArgument {
    /**
     * 
     * @type {MemberInfo}
     * @memberof CustomAttributeNamedArgument
     */
    memberInfo?: MemberInfo;
    /**
     * 
     * @type {CustomAttributeTypedArgument}
     * @memberof CustomAttributeNamedArgument
     */
    typedValue?: CustomAttributeTypedArgument;
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeNamedArgument
     */
    memberName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomAttributeNamedArgument
     */
    isField?: boolean;
}
/**
 * 
 * @export
 * @interface CustomAttributeTypedArgument
 */
export interface CustomAttributeTypedArgument {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeTypedArgument
     */
    argumentType?: Type;
    /**
     * 
     * @type {ModelObject}
     * @memberof CustomAttributeTypedArgument
     */
    value?: ModelObject;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof Customer
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name2?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    countryRegionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    contactPhoneNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    registrationNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    registryNo?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    tenantId?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof Customer
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {Date}
     * @memberof Customer
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    creatorUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    name2?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    countryRegionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    contactPhoneNo?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    registrationNo?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    registryNo?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    tenantId?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof CustomerDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {Date}
     * @memberof CustomerDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    creatorUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface DataExchangeEntry
 */
export interface DataExchangeEntry {
    /**
     * 
     * @type {number}
     * @memberof DataExchangeEntry
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    entityType?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    entityReference?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    exportState?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    importState?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    externalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeEntry
     */
    externalError?: string;
    /**
     * 
     * @type {number}
     * @memberof DataExchangeEntry
     */
    dataExchangeProtocolId?: number;
    /**
     * 
     * @type {DataExchangeProtocol}
     * @memberof DataExchangeEntry
     */
    dataExchangeProtocolFk?: DataExchangeProtocol;
    /**
     * 
     * @type {number}
     * @memberof DataExchangeEntry
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface DataExchangeFeedbackInput
 */
export interface DataExchangeFeedbackInput {
    /**
     * 
     * @type {number}
     * @memberof DataExchangeFeedbackInput
     */
    protocolId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DataExchangeFeedbackInput
     */
    wasSuccessful?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeFeedbackInput
     */
    entityExchangeState?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeFeedbackInput
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface DataExchangeProtocol
 */
export interface DataExchangeProtocol {
    /**
     * 
     * @type {number}
     * @memberof DataExchangeProtocol
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof DataExchangeProtocol
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof DataExchangeProtocol
     */
    creatorUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    no?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    externalSystemName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    externalReference?: string;
    /**
     * 
     * @type {Date}
     * @memberof DataExchangeProtocol
     */
    requestStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataExchangeProtocol
     */
    requestEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    requestMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    responseMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    userFriendlyResponseMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    exchangeState?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    exchangeType?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    entityType?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    comment?: string;
    /**
     * 
     * @type {Array<DataExchangeEntry>}
     * @memberof DataExchangeProtocol
     */
    dataExchangeEntries?: Array<DataExchangeEntry>;
    /**
     * 
     * @type {ModelObject}
     * @memberof DataExchangeProtocol
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeProtocol
     */
    extensionData?: string;
    /**
     * 
     * @type {number}
     * @memberof DataExchangeProtocol
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface DataExchangeProtocolPagedResultDto
 */
export interface DataExchangeProtocolPagedResultDto {
    /**
     * 
     * @type {Array<DataExchangeProtocol>}
     * @memberof DataExchangeProtocolPagedResultDto
     */
    items?: Array<DataExchangeProtocol>;
    /**
     * 
     * @type {number}
     * @memberof DataExchangeProtocolPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface DataExchangeStateDto
 */
export interface DataExchangeStateDto {
    /**
     * 
     * @type {number}
     * @memberof DataExchangeStateDto
     */
    exchangeProtocolId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeStateDto
     */
    exchangeNo?: string;
    /**
     * 
     * @type {Date}
     * @memberof DataExchangeStateDto
     */
    exchangeCreationTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeStateDto
     */
    exchangeState?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeStateDto
     */
    entitiesState?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExchangeStateDto
     */
    exchangeComment?: string;
    /**
     * 
     * @type {Array<ReportDto>}
     * @memberof DataExchangeStateDto
     */
    includedReports?: Array<ReportDto>;
}
/**
 * 
 * @export
 * @interface DataExchangeStateDtoPagedResultDto
 */
export interface DataExchangeStateDtoPagedResultDto {
    /**
     * 
     * @type {Array<DataExchangeStateDto>}
     * @memberof DataExchangeStateDtoPagedResultDto
     */
    items?: Array<DataExchangeStateDto>;
    /**
     * 
     * @type {number}
     * @memberof DataExchangeStateDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface DataMapping
 */
export interface DataMapping {
    /**
     * 
     * @type {number}
     * @memberof DataMapping
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    mappingName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    setId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    sourceValue?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    targetValue?: string;
    /**
     * 
     * @type {number}
     * @memberof DataMapping
     */
    sourceServiceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof DataMapping
     */
    sourceServiceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    sourceServiceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    sourceType?: string;
    /**
     * 
     * @type {number}
     * @memberof DataMapping
     */
    targetServiceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof DataMapping
     */
    targetServiceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    targetServiceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    targetType?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMapping
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof DataMapping
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof DataMapping
     */
    userFk?: User;
    /**
     * 
     * @type {number}
     * @memberof DataMapping
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface DataMappingDto
 */
export interface DataMappingDto {
    /**
     * 
     * @type {number}
     * @memberof DataMappingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    mappingName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    setId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    sourceValue?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    targetValue?: string;
    /**
     * 
     * @type {number}
     * @memberof DataMappingDto
     */
    sourceServiceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    sourceServiceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    sourceType?: string;
    /**
     * 
     * @type {number}
     * @memberof DataMappingDto
     */
    targetServiceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    targetServiceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    targetType?: string;
    /**
     * 
     * @type {string}
     * @memberof DataMappingDto
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof DataMappingDto
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof DataMappingDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface DataMappingDtoPagedResultDto
 */
export interface DataMappingDtoPagedResultDto {
    /**
     * 
     * @type {Array<DataMappingDto>}
     * @memberof DataMappingDtoPagedResultDto
     */
    items?: Array<DataMappingDto>;
    /**
     * 
     * @type {number}
     * @memberof DataMappingDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface EmailConfiguration
 */
export interface EmailConfiguration {
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    defaultFromAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    defaultFromDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    smtpPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    smtpHost?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    smtpPort?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    smtpUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    smtpEnableSsl?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfiguration
     */
    smtpUseDefaultCredentials?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EventAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_512 = <any> 512,
    NUMBER_1024 = <any> 1024
}
/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof EventInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof EventInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof EventInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {EventAttributes}
     * @memberof EventInfo
     */
    attributes?: EventAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    addMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    removeMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    raiseMethod?: MethodInfo;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    isMulticast?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    eventHandlerType?: Type;
}
/**
 * 
 * @export
 * @interface ExternalAuthenticateModel
 */
export interface ExternalAuthenticateModel {
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateModel
     */
    authProvider: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateModel
     */
    providerKey: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateModel
     */
    providerAccessCode: string;
}
/**
 * 
 * @export
 * @interface ExternalAuthenticateResultModel
 */
export interface ExternalAuthenticateResultModel {
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateResultModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthenticateResultModel
     */
    encryptedAccessToken?: string;
    /**
     * 
     * @type {number}
     * @memberof ExternalAuthenticateResultModel
     */
    expireInSeconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalAuthenticateResultModel
     */
    waitingForActivation?: boolean;
}
/**
 * 
 * @export
 * @interface ExternalLoginProviderInfoModel
 */
export interface ExternalLoginProviderInfoModel {
    /**
     * 
     * @type {string}
     * @memberof ExternalLoginProviderInfoModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLoginProviderInfoModel
     */
    clientId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_16 = <any> 16,
    NUMBER_32 = <any> 32,
    NUMBER_64 = <any> 64,
    NUMBER_128 = <any> 128,
    NUMBER_256 = <any> 256,
    NUMBER_512 = <any> 512,
    NUMBER_1024 = <any> 1024,
    NUMBER_4096 = <any> 4096,
    NUMBER_8192 = <any> 8192,
    NUMBER_32768 = <any> 32768,
    NUMBER_38144 = <any> 38144
}
/**
 * 
 * @export
 * @interface FieldInfo
 */
export interface FieldInfo {
    /**
     * 
     * @type {string}
     * @memberof FieldInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof FieldInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof FieldInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof FieldInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {FieldAttributes}
     * @memberof FieldInfo
     */
    attributes?: FieldAttributes;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    fieldType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isInitOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isLiteral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isNotSerialized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isPinvokeImpl?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {RuntimeFieldHandle}
     * @memberof FieldInfo
     */
    fieldHandle?: RuntimeFieldHandle;
}
/**
 * 
 * @export
 * @interface FlatPermissionDto
 */
export interface FlatPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof FlatPermissionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FlatPermissionDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof FlatPermissionDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum GenericParameterAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_8 = <any> 8,
    NUMBER_16 = <any> 16,
    NUMBER_28 = <any> 28
}
/**
 * 
 * @export
 * @interface GetAllActionsItemDto
 */
export interface GetAllActionsItemDto {
    /**
     * 
     * @type {string}
     * @memberof GetAllActionsItemDto
     */
    providerName?: string;
    /**
     * 
     * @type {Array<IServiceAction>}
     * @memberof GetAllActionsItemDto
     */
    serviceActions?: Array<IServiceAction>;
}
/**
 * 
 * @export
 * @interface GetCurrentLoginInformationsOutput
 */
export interface GetCurrentLoginInformationsOutput {
    /**
     * 
     * @type {ApplicationInfoDto}
     * @memberof GetCurrentLoginInformationsOutput
     */
    application?: ApplicationInfoDto;
    /**
     * 
     * @type {UserLoginInfoDto}
     * @memberof GetCurrentLoginInformationsOutput
     */
    user?: UserLoginInfoDto;
    /**
     * 
     * @type {TenantLoginInfoDto}
     * @memberof GetCurrentLoginInformationsOutput
     */
    tenant?: TenantLoginInfoDto;
}
/**
 * 
 * @export
 * @interface GetForAllEntitiesInput
 */
export interface GetForAllEntitiesInput {
    /**
     * 
     * @type {string}
     * @memberof GetForAllEntitiesInput
     */
    entityType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetForAllEntitiesInput
     */
    entityIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetRoleForEditOutput
 */
export interface GetRoleForEditOutput {
    /**
     * 
     * @type {RoleEditDto}
     * @memberof GetRoleForEditOutput
     */
    role?: RoleEditDto;
    /**
     * 
     * @type {Array<FlatPermissionDto>}
     * @memberof GetRoleForEditOutput
     */
    permissions?: Array<FlatPermissionDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRoleForEditOutput
     */
    grantedPermissionNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface GroupBasedPredictionResultDto
 */
export interface GroupBasedPredictionResultDto {
    /**
     * 
     * @type {string}
     * @memberof GroupBasedPredictionResultDto
     */
    resultIdentifier?: string;
    /**
     * 
     * @type {Array<TimeEntity>}
     * @memberof GroupBasedPredictionResultDto
     */
    entities?: Array<TimeEntity>;
    /**
     * 
     * @type {Date}
     * @memberof GroupBasedPredictionResultDto
     */
    validUntil?: Date;
    /**
     * 
     * @type {TimeEntity}
     * @memberof GroupBasedPredictionResultDto
     */
    originalEvent?: TimeEntity;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GroupBasedPredictionResultDto
     */
    resourceMappings?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ICustomAttributeProvider
 */
export interface ICustomAttributeProvider {
}
/**
 * 
 * @export
 * @interface ILocalizableString
 */
export interface ILocalizableString {
}
/**
 * 
 * @export
 * @interface IServiceAction
 */
export interface IServiceAction {
    /**
     * 
     * @type {string}
     * @memberof IServiceAction
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IServiceAction
     */
    displayName?: string;
    /**
     * 
     * @type {LocalizableString}
     * @memberof IServiceAction
     */
    localizableDisplayName?: LocalizableString;
    /**
     * 
     * @type {string}
     * @memberof IServiceAction
     */
    category?: string;
    /**
     * 
     * @type {ObjectAction}
     * @memberof IServiceAction
     */
    action?: ObjectAction;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IServiceAction
     */
    args?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ISettingClientVisibilityProvider
 */
export interface ISettingClientVisibilityProvider {
}
/**
 * 
 * @export
 * @interface ISettingValue
 */
export interface ISettingValue {
    /**
     * 
     * @type {string}
     * @memberof ISettingValue
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ISettingValue
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface Int64EntityDto
 */
export interface Int64EntityDto {
    /**
     * 
     * @type {number}
     * @memberof Int64EntityDto
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface IntPtr
 */
export interface IntPtr {
}
/**
 * 
 * @export
 * @interface IsTenantAvailableInput
 */
export interface IsTenantAvailableInput {
    /**
     * 
     * @type {string}
     * @memberof IsTenantAvailableInput
     */
    tenancyName: string;
}
/**
 * 
 * @export
 * @interface IsTenantAvailableOutput
 */
export interface IsTenantAvailableOutput {
    /**
     * 
     * @type {TenantAvailabilityState}
     * @memberof IsTenantAvailableOutput
     */
    state?: TenantAvailabilityState;
    /**
     * 
     * @type {number}
     * @memberof IsTenantAvailableOutput
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface ItemDto
 */
export interface ItemDto {
}
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof Job
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    description2?: string;
    /**
     * 
     * @type {JobState}
     * @memberof Job
     */
    jobState?: JobState;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    customState?: string;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    accountableId?: string;
    /**
     * 
     * @type {Resource}
     * @memberof Job
     */
    accountableFk?: Resource;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    accountableReference?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    customerId?: string;
    /**
     * 
     * @type {Category}
     * @memberof Job
     */
    customerFk?: Category;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    customerReference?: string;
    /**
     * 
     * @type {Array<JobTask>}
     * @memberof Job
     */
    jobTasks?: Array<JobTask>;
    /**
     * 
     * @type {ModelObject}
     * @memberof Job
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    extensionData?: string;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    templateId?: string;
    /**
     * 
     * @type {Job}
     * @memberof Job
     */
    templateFk?: Job;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    templateReference?: string;
}
/**
 * 
 * @export
 * @interface JobData
 */
export interface JobData {
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    jobNo?: string;
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    jobTaskBaseNo?: string;
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    jobTaskNo?: string;
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    jobTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    categoryNo?: string;
    /**
     * 
     * @type {string}
     * @memberof JobData
     */
    categoryId?: string;
    /**
     * 
     * @type {number}
     * @memberof JobData
     */
    sourceServiceConnectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof JobData
     */
    targetServiceConnectionId?: number;
}
/**
 * 
 * @export
 * @interface JobDto
 */
export interface JobDto {
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    description2?: string;
    /**
     * 
     * @type {JobState}
     * @memberof JobDto
     */
    jobState?: JobState;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    customState?: string;
    /**
     * 
     * @type {Date}
     * @memberof JobDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JobDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    accountableId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    templateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof JobDto
     */
    extensionData?: string;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof JobDto
     */
    attachments?: Array<AttachmentDto>;
}
/**
 * 
 * @export
 * @interface JobJournalBatchDto
 */
export interface JobJournalBatchDto {
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobJournalBatchDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    etag?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    journalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    noSeries?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    templateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    orvJobNo?: string;
    /**
     * 
     * @type {string}
     * @memberof JobJournalBatchDto
     */
    orvDefaultResourceNo?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum JobState {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4
}
/**
 * 
 * @export
 * @interface JobTask
 */
export interface JobTask {
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTask
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof JobTask
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    description2?: string;
    /**
     * 
     * @type {JobTaskState}
     * @memberof JobTask
     */
    jobTaskState?: JobTaskState;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    customState?: string;
    /**
     * 
     * @type {Date}
     * @memberof JobTask
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JobTask
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    accountableId?: string;
    /**
     * 
     * @type {Resource}
     * @memberof JobTask
     */
    accountableFk?: Resource;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    accountableReference?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    executiveId?: string;
    /**
     * 
     * @type {Resource}
     * @memberof JobTask
     */
    executiveFk?: Resource;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    executiveReference?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    defaultCategoryId?: string;
    /**
     * 
     * @type {Category}
     * @memberof JobTask
     */
    defaultCategoryFk?: Category;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    defaultCategoryReference?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    jobId?: string;
    /**
     * 
     * @type {Job}
     * @memberof JobTask
     */
    jobFk?: Job;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    jobReference?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTask
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    extensionData?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTask
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobTask
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    templateId?: string;
    /**
     * 
     * @type {JobTask}
     * @memberof JobTask
     */
    templateFk?: JobTask;
    /**
     * 
     * @type {string}
     * @memberof JobTask
     */
    templateReference?: string;
}
/**
 * 
 * @export
 * @interface JobTaskDto
 */
export interface JobTaskDto {
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTaskDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    description2?: string;
    /**
     * 
     * @type {JobTaskState}
     * @memberof JobTaskDto
     */
    jobTaskState?: JobTaskState;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    customState?: string;
    /**
     * 
     * @type {Date}
     * @memberof JobTaskDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JobTaskDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    accountableId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    executiveId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    defaultCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    templateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTaskDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTaskDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof JobTaskDto
     */
    extensionData?: string;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof JobTaskDto
     */
    attachments?: Array<AttachmentDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum JobTaskState {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_9 = <any> 9,
    NUMBER_10 = <any> 10
}
/**
 * 
 * @export
 * @interface JobTaskTransferLine
 */
export interface JobTaskTransferLine {
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTaskTransferLine
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof JobTaskTransferLine
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    jobNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    jobTaskNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    jobTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    itemId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    description2?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    transferFromBinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    transferFromBinId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    transferToBinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    transferToBinId?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTaskTransferLine
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof JobTaskTransferLine
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobTaskTransferLine
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    templateId?: string;
    /**
     * 
     * @type {JobTaskTransferLine}
     * @memberof JobTaskTransferLine
     */
    templateFk?: JobTaskTransferLine;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    templateReference?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLine
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTaskTransferLine
     */
    extensions?: ModelObject;
}
/**
 * 
 * @export
 * @interface JobTaskTransferLineDto
 */
export interface JobTaskTransferLineDto {
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTaskTransferLineDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    jobNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    jobTaskNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    jobTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    itemId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    description2?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    transferFromBinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    transferFromBinId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    transferToBinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    transferToBinId?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTaskTransferLineDto
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof JobTaskTransferLineDto
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobTaskTransferLineDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    templateId?: string;
    /**
     * 
     * @type {JobTaskTransferLine}
     * @memberof JobTaskTransferLineDto
     */
    templateFk?: JobTaskTransferLine;
    /**
     * 
     * @type {string}
     * @memberof JobTaskTransferLineDto
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTaskTransferLineDto
     */
    extensions?: ModelObject;
}
/**
 * 
 * @export
 * @interface JobType
 */
export interface JobType {
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobType
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof JobType
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    description2?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    autoAdjustmentForStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBlankJobJournalLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBudgetJobJournalLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBillableJobJournalLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBudgetBillableJobJournalLine?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    resourceJobJournalLineJobLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    itemJobJournalLineJobLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    glAccountJobJournalLineJobLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    resourceJobJournalLineLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    itemJobJournalLineLedgerEntryType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBudgetJobPlanningLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBillableJobPlanningLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBudgetBillableJobPlanningLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    allowBillableJobPlanningLineInsertion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    blockPlanning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    salesQuoteToOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    blockOrderPosting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveDesignation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveItemAdjustment?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveItemJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveItemJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveItemJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveItemJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveJobJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveJobJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveJobJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveJobJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    positiveUseDimensions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    positivePriceCalculation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    positiveCreateJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    positiveNegativeJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    positiveUseScrap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    positiveUseSetupTime?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeDesignation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeItemAdjustment?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeItemJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeItemJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeItemJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeItemJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeJobJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeJobJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeJobJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeJobJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    negativeUseDimensions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    negativePriceCalculation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    negativeCreateJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    negativeNegativeJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    negativeUseScrap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    negativeUseSetupTime?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JobType
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobType
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    templateId?: string;
    /**
     * 
     * @type {JobType}
     * @memberof JobType
     */
    templateFk?: JobType;
    /**
     * 
     * @type {Array<JobTypeLine>}
     * @memberof JobType
     */
    jobTypeLines?: Array<JobTypeLine>;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    templateReference?: string;
    /**
     * 
     * @type {string}
     * @memberof JobType
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobType
     */
    extensions?: ModelObject;
}
/**
 * 
 * @export
 * @interface JobTypeDto
 */
export interface JobTypeDto {
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    description2?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    autoAdjustmentForStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBlankJobJournalLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBudgetJobJournalLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBillableJobJournalLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBudgetBillableJobJournalLine?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    resourceJobJournalLineJobLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    itemJobJournalLineJobLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    glAccountJobJournalLineJobLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    resourceJobJournalLineLedgerEntryType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    itemJobJournalLineLedgerEntryType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBudgetJobPlanningLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBillableJobPlanningLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBudgetBillableJobPlanningLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    allowBillableJobPlanningLineInsertion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    blockPlanning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    salesQuoteToOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    blockOrderPosting?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JobTypeDto
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    templateId?: string;
    /**
     * 
     * @type {JobType}
     * @memberof JobTypeDto
     */
    template?: JobType;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTypeDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveDesignation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveItemAdjustment?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveItemJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveItemJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveItemJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveItemJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveJobJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveJobJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveJobJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveJobJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    positiveUseDimensions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    positivePriceCalculation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    positiveCreateJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    positiveNegativeJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    positiveUseScrap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    positiveUseSetupTime?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeDesignation?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeItemAdjustment?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeItemJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeItemJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeItemJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeItemJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeJobJournalTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeJobJournalTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeJobJournalBatchName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeJobJournalBatchId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeDto
     */
    negativeUseDimensions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    negativePriceCalculation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    negativeCreateJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    negativeNegativeJobLedgerEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    negativeUseScrap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeDto
     */
    negativeUseSetupTime?: boolean;
}
/**
 * 
 * @export
 * @interface JobTypeLine
 */
export interface JobTypeLine {
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLine
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof JobTypeLine
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    jobTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    jobTypeId?: string;
    /**
     * 
     * @type {JobType}
     * @memberof JobTypeLine
     */
    jobType?: JobType;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    postingType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    itemId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    description2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeLine
     */
    useItemSubstitution?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    useQuantity?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLine
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    binCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    binId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    resourceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    resourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    resourceUnitOfMeasureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    resourceUnitOfMeasureId?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLine
     */
    setupTime?: number;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLine
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeLine
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    templateId?: string;
    /**
     * 
     * @type {JobTypeLine}
     * @memberof JobTypeLine
     */
    templateFk?: JobTypeLine;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    templateReference?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLine
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTypeLine
     */
    extensions?: ModelObject;
}
/**
 * 
 * @export
 * @interface JobTypeLineDto
 */
export interface JobTypeLineDto {
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLineDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    jobTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    jobTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    postingType?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    itemId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    description2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeLineDto
     */
    useItemSubstitution?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    useQuantity?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLineDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    binCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    binId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    resourceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    resourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    resourceUnitOfMeasureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    resourceUnitOfMeasureId?: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLineDto
     */
    setupTime?: number;
    /**
     * 
     * @type {number}
     * @memberof JobTypeLineDto
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobTypeLineDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    templateId?: string;
    /**
     * 
     * @type {JobTypeLine}
     * @memberof JobTypeLineDto
     */
    templateFk?: JobTypeLine;
    /**
     * 
     * @type {string}
     * @memberof JobTypeLineDto
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof JobTypeLineDto
     */
    extensions?: ModelObject;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LayoutKind {
    NUMBER_0 = <any> 0,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 * 
 * @export
 * @interface LocalizableString
 */
export interface LocalizableString {
    /**
     * 
     * @type {string}
     * @memberof LocalizableString
     */
    sourceName?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalizableString
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface MappedWorkItem
 */
export interface MappedWorkItem {
    /**
     * 
     * @type {WorkItem}
     * @memberof MappedWorkItem
     */
    item?: WorkItem;
    /**
     * 
     * @type {JobData}
     * @memberof MappedWorkItem
     */
    jobData?: JobData;
}
/**
 * 
 * @export
 * @interface MemberInfo
 */
export interface MemberInfo {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MemberInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MemberInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MemberInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MemberInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberInfo
     */
    metadataToken?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MemberTypes {
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_4 = <any> 4,
    NUMBER_8 = <any> 8,
    NUMBER_16 = <any> 16,
    NUMBER_32 = <any> 32,
    NUMBER_64 = <any> 64,
    NUMBER_128 = <any> 128,
    NUMBER_191 = <any> 191
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_16 = <any> 16,
    NUMBER_32 = <any> 32,
    NUMBER_64 = <any> 64,
    NUMBER_128 = <any> 128,
    NUMBER_256 = <any> 256,
    NUMBER_512 = <any> 512,
    NUMBER_1024 = <any> 1024,
    NUMBER_2048 = <any> 2048,
    NUMBER_4096 = <any> 4096,
    NUMBER_8192 = <any> 8192,
    NUMBER_16384 = <any> 16384,
    NUMBER_32768 = <any> 32768,
    NUMBER_53248 = <any> 53248
}
/**
 * 
 * @export
 * @interface MethodBase
 */
export interface MethodBase {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodBase
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MethodBase
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodBase
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodBase
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodBase
     */
    metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodBase
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodBase
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodBase
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodBase
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    isSecurityTransparent?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodImplAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_8 = <any> 8,
    NUMBER_16 = <any> 16,
    NUMBER_32 = <any> 32,
    NUMBER_64 = <any> 64,
    NUMBER_128 = <any> 128,
    NUMBER_256 = <any> 256,
    NUMBER_512 = <any> 512,
    NUMBER_4096 = <any> 4096,
    NUMBER_65535 = <any> 65535
}
/**
 * 
 * @export
 * @interface MethodInfo
 */
export interface MethodInfo {
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodInfo
     */
    attributes?: MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodInfo
     */
    methodImplementationFlags?: MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodInfo
     */
    callingConvention?: CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isConstructor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFinal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isHideBySig?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFamilyAndAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isFamilyOrAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isConstructedGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isGenericMethod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isGenericMethodDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodInfo
     */
    methodHandle?: RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {ParameterInfo}
     * @memberof MethodInfo
     */
    returnParameter?: ParameterInfo;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    returnType?: Type;
    /**
     * 
     * @type {ICustomAttributeProvider}
     * @memberof MethodInfo
     */
    returnTypeCustomAttributes?: ICustomAttributeProvider;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Assembly}
     * @memberof Module
     */
    assembly?: Assembly;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    fullyQualifiedName?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    mdStreamVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    moduleVersionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    scopeName?: string;
    /**
     * 
     * @type {ModuleHandle}
     * @memberof Module
     */
    moduleHandle?: ModuleHandle;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Module
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    metadataToken?: number;
}
/**
 * 
 * @export
 * @interface ModuleHandle
 */
export interface ModuleHandle {
    /**
     * 
     * @type {number}
     * @memberof ModuleHandle
     */
    mdStreamVersion?: number;
}
/**
 * 
 * @export
 * @interface ObjectAction
 */
export interface ObjectAction {
    /**
     * 
     * @type {ModelObject}
     * @memberof ObjectAction
     */
    target?: ModelObject;
    /**
     * 
     * @type {MethodInfo}
     * @memberof ObjectAction
     */
    method?: MethodInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ParameterAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_4 = <any> 4,
    NUMBER_8 = <any> 8,
    NUMBER_16 = <any> 16,
    NUMBER_4096 = <any> 4096,
    NUMBER_8192 = <any> 8192,
    NUMBER_16384 = <any> 16384,
    NUMBER_32768 = <any> 32768,
    NUMBER_61440 = <any> 61440
}
/**
 * 
 * @export
 * @interface ParameterInfo
 */
export interface ParameterInfo {
    /**
     * 
     * @type {ParameterAttributes}
     * @memberof ParameterInfo
     */
    attributes?: ParameterAttributes;
    /**
     * 
     * @type {MemberInfo}
     * @memberof ParameterInfo
     */
    member?: MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof ParameterInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof ParameterInfo
     */
    parameterType?: Type;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isLcid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isOptional?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isOut?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    isRetval?: boolean;
    /**
     * 
     * @type {ModelObject}
     * @memberof ParameterInfo
     */
    defaultValue?: ModelObject;
    /**
     * 
     * @type {ModelObject}
     * @memberof ParameterInfo
     */
    rawDefaultValue?: ModelObject;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    hasDefaultValue?: boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ParameterInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    metadataToken?: number;
}
/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {number}
     * @memberof PermissionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface PermissionDtoListResultDto
 */
export interface PermissionDtoListResultDto {
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof PermissionDtoListResultDto
     */
    items?: Array<PermissionDto>;
}
/**
 * 
 * @export
 * @interface PlmDataExchangeStateDto
 */
export interface PlmDataExchangeStateDto {
    /**
     * 
     * @type {number}
     * @memberof PlmDataExchangeStateDto
     */
    exchangeProtocolId?: number;
    /**
     * 
     * @type {string}
     * @memberof PlmDataExchangeStateDto
     */
    exchangeNo?: string;
    /**
     * 
     * @type {Date}
     * @memberof PlmDataExchangeStateDto
     */
    exchangeCreationTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof PlmDataExchangeStateDto
     */
    exchangeState?: string;
    /**
     * 
     * @type {string}
     * @memberof PlmDataExchangeStateDto
     */
    entitiesState?: string;
    /**
     * 
     * @type {string}
     * @memberof PlmDataExchangeStateDto
     */
    exchangeComment?: string;
    /**
     * 
     * @type {JobDto}
     * @memberof PlmDataExchangeStateDto
     */
    job?: JobDto;
    /**
     * 
     * @type {ItemDto}
     * @memberof PlmDataExchangeStateDto
     */
    item?: ItemDto;
}
/**
 * 
 * @export
 * @interface PlmDataExchangeStateDtoPagedResultDto
 */
export interface PlmDataExchangeStateDtoPagedResultDto {
    /**
     * 
     * @type {Array<PlmDataExchangeStateDto>}
     * @memberof PlmDataExchangeStateDtoPagedResultDto
     */
    items?: Array<PlmDataExchangeStateDto>;
    /**
     * 
     * @type {number}
     * @memberof PlmDataExchangeStateDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface PredictionServiceConfigurationDto
 */
export interface PredictionServiceConfigurationDto {
    /**
     * 
     * @type {number}
     * @memberof PredictionServiceConfigurationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PredictionServiceConfigurationDto
     */
    predictionServiceType?: string;
    /**
     * 
     * @type {string}
     * @memberof PredictionServiceConfigurationDto
     */
    predictionInfoProviderType?: string;
    /**
     * 
     * @type {string}
     * @memberof PredictionServiceConfigurationDto
     */
    predictionDataProviderType?: string;
    /**
     * 
     * @type {number}
     * @memberof PredictionServiceConfigurationDto
     */
    dataProviderServiceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PredictionServiceConfigurationDto
     */
    infoServiceProvider?: string;
    /**
     * 
     * @type {number}
     * @memberof PredictionServiceConfigurationDto
     */
    infoServiceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PredictionServiceConfigurationDto
     */
    dataProviderServiceProvider?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PredictionServiceConfigurationDto
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PredictionServiceConfigurationDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface PredictionServiceConfigurationDtoPagedResultDto
 */
export interface PredictionServiceConfigurationDtoPagedResultDto {
    /**
     * 
     * @type {Array<PredictionServiceConfigurationDto>}
     * @memberof PredictionServiceConfigurationDtoPagedResultDto
     */
    items?: Array<PredictionServiceConfigurationDto>;
    /**
     * 
     * @type {number}
     * @memberof PredictionServiceConfigurationDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectState {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_MINUS_2 = <any> -2,
    NUMBER_MINUS_1 = <any> -1
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectVisibility {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_MINUS_1 = <any> -1
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PropertyAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_512 = <any> 512,
    NUMBER_1024 = <any> 1024,
    NUMBER_4096 = <any> 4096,
    NUMBER_8192 = <any> 8192,
    NUMBER_16384 = <any> 16384,
    NUMBER_32768 = <any> 32768,
    NUMBER_62464 = <any> 62464
}
/**
 * 
 * @export
 * @interface PropertyInfo
 */
export interface PropertyInfo {
    /**
     * 
     * @type {string}
     * @memberof PropertyInfo
     */
    name?: string;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Module}
     * @memberof PropertyInfo
     */
    module?: Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof PropertyInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof PropertyInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    propertyType?: Type;
    /**
     * 
     * @type {PropertyAttributes}
     * @memberof PropertyInfo
     */
    attributes?: PropertyAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    canRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    canWrite?: boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    getMethod?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    setMethod?: MethodInfo;
}
/**
 * 
 * @export
 * @interface ReferenceLinks
 */
export interface ReferenceLinks {
    /**
     * 
     * @type {{ [key: string]: ModelObject; }}
     * @memberof ReferenceLinks
     */
    links?: { [key: string]: ModelObject; };
}
/**
 * 
 * @export
 * @interface RefreshApiKeyDto
 */
export interface RefreshApiKeyDto {
    /**
     * 
     * @type {number}
     * @memberof RefreshApiKeyDto
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof RefreshApiKeyDto
     */
    validDays?: number;
}
/**
 * 
 * @export
 * @interface RefreshTokenModel
 */
export interface RefreshTokenModel {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenModel
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenResultModel
 */
export interface RefreshTokenResultModel {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResultModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResultModel
     */
    encryptedAccessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResultModel
     */
    refreshToken?: string;
    /**
     * 
     * @type {number}
     * @memberof RefreshTokenResultModel
     */
    expireInSeconds?: number;
}
/**
 * 
 * @export
 * @interface RegisterInput
 */
export interface RegisterInput {
    /**
     * 
     * @type {string}
     * @memberof RegisterInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInput
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInput
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterInput
     */
    captchaResponse?: string;
}
/**
 * 
 * @export
 * @interface RegisterOutput
 */
export interface RegisterOutput {
    /**
     * 
     * @type {boolean}
     * @memberof RegisterOutput
     */
    canLogin?: boolean;
}
/**
 * 
 * @export
 * @interface ReportDataEntryDto
 */
export interface ReportDataEntryDto {
    /**
     * 
     * @type {number}
     * @memberof ReportDataEntryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportDataEntryDto
     */
    validUntil?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    templateAreaValues?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    optionValues?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    values?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDataEntryDto
     */
    reportId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDataEntryDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDataEntryDto
     */
    extensionData?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDataEntryDto
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDataEntryDto
     */
    creatorUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportDataEntryDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportDataEntryDto
     */
    lastModificationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReportDataEntryDto
     */
    lastModifierUserId?: number;
}
/**
 * 
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportDto
     */
    reportDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    reportTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    no?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    externalId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportDto
     */
    validUntil?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDto
     */
    isEditable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDto
     */
    useVersioning?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    templateId?: number;
    /**
     * 
     * @type {Array<ReportDataEntryDto>}
     * @memberof ReportDto
     */
    dataEntries?: Array<ReportDataEntryDto>;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof ReportDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {number}
     * @memberof ReportDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface ReportDtoPagedResultDto
 */
export interface ReportDtoPagedResultDto {
    /**
     * 
     * @type {Array<ReportDto>}
     * @memberof ReportDtoPagedResultDto
     */
    items?: Array<ReportDto>;
    /**
     * 
     * @type {number}
     * @memberof ReportDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface ReportOptionDto
 */
export interface ReportOptionDto {
    /**
     * 
     * @type {number}
     * @memberof ReportOptionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    valuesFilter?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportOptionDto
     */
    isFilter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportOptionDto
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionDto
     */
    displayControlType?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOptionDto
     */
    templateId?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOptionDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface ReportOptionDtoPagedResultDto
 */
export interface ReportOptionDtoPagedResultDto {
    /**
     * 
     * @type {Array<ReportOptionDto>}
     * @memberof ReportOptionDtoPagedResultDto
     */
    items?: Array<ReportOptionDto>;
    /**
     * 
     * @type {number}
     * @memberof ReportOptionDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface ReportTemplateDto
 */
export interface ReportTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof ReportTemplateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    dataType?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    dataTypeProvider?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportTemplateDto
     */
    templateAreas?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ReportTemplateDto
     */
    defaultTemplateAreaValues?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ReportTemplateDto
     */
    defaultOptionValues?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof ReportTemplateDto
     */
    useVersioning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    templatePath?: string;
    /**
     * 
     * @type {Array<ReportOptionDto>}
     * @memberof ReportTemplateDto
     */
    options?: Array<ReportOptionDto>;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    extensionData?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof ReportTemplateDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplateDto
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportTemplateDto
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportTemplateDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    adminPassword: string;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordDto
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordInput
 */
export interface ResetPasswordInput {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordInput
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    resetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    returnUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    singleSignIn?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    c?: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordOutput
 */
export interface ResetPasswordOutput {
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordOutput
     */
    canLogin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordOutput
     */
    userName?: string;
}
/**
 * 
 * @export
 * @interface ResolveTenantIdInput
 */
export interface ResolveTenantIdInput {
    /**
     * 
     * @type {string}
     * @memberof ResolveTenantIdInput
     */
    c?: string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof Resource
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    surname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    isActive?: boolean;
    /**
     * 
     * @type {ModelObject}
     * @memberof Resource
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    extensionData?: string;
    /**
     * 
     * @type {Array<TimeTrackingSession>}
     * @memberof Resource
     */
    assignedSessions?: Array<TimeTrackingSession>;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface ResourceDto
 */
export interface ResourceDto {
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    designation?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    surname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {ModelObject}
     * @memberof ResourceDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    extensionData?: string;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    normalizedName?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    grantedPermissions?: Array<string>;
}
/**
 * 
 * @export
 * @interface RoleDtoListResultDto
 */
export interface RoleDtoListResultDto {
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleDtoListResultDto
     */
    items?: Array<RoleDto>;
}
/**
 * 
 * @export
 * @interface RoleDtoPagedResultDto
 */
export interface RoleDtoPagedResultDto {
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleDtoPagedResultDto
     */
    items?: Array<RoleDto>;
    /**
     * 
     * @type {number}
     * @memberof RoleDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface RoleEditDto
 */
export interface RoleEditDto {
    /**
     * 
     * @type {number}
     * @memberof RoleEditDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleEditDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleEditDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof RoleEditDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleEditDto
     */
    isStatic?: boolean;
}
/**
 * 
 * @export
 * @interface RoleListDto
 */
export interface RoleListDto {
    /**
     * 
     * @type {number}
     * @memberof RoleListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleListDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleListDto
     */
    displayName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleListDto
     */
    isStatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleListDto
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RoleListDto
     */
    creationTime?: Date;
}
/**
 * 
 * @export
 * @interface RoleListDtoListResultDto
 */
export interface RoleListDtoListResultDto {
    /**
     * 
     * @type {Array<RoleListDto>}
     * @memberof RoleListDtoListResultDto
     */
    items?: Array<RoleListDto>;
}
/**
 * 
 * @export
 * @interface RuntimeFieldHandle
 */
export interface RuntimeFieldHandle {
    /**
     * 
     * @type {IntPtr}
     * @memberof RuntimeFieldHandle
     */
    value?: IntPtr;
}
/**
 * 
 * @export
 * @interface RuntimeMethodHandle
 */
export interface RuntimeMethodHandle {
    /**
     * 
     * @type {IntPtr}
     * @memberof RuntimeMethodHandle
     */
    value?: IntPtr;
}
/**
 * 
 * @export
 * @interface RuntimeTypeHandle
 */
export interface RuntimeTypeHandle {
    /**
     * 
     * @type {IntPtr}
     * @memberof RuntimeTypeHandle
     */
    value?: IntPtr;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SecurityRuleSet {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface SendPasswordResetCodeInput
 */
export interface SendPasswordResetCodeInput {
    /**
     * 
     * @type {string}
     * @memberof SendPasswordResetCodeInput
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface ServiceActionDto
 */
export interface ServiceActionDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceActionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceActionDto
     */
    displayName?: string;
    /**
     * 
     * @type {LocalizableString}
     * @memberof ServiceActionDto
     */
    localizableDisplayName?: LocalizableString;
    /**
     * 
     * @type {string}
     * @memberof ServiceActionDto
     */
    category?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceActionDto
     */
    args?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ServiceConnection
 */
export interface ServiceConnection {
    /**
     * 
     * @type {number}
     * @memberof ServiceConnection
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ServiceConnection
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ServiceConnection
     */
    creatorUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ServiceConnection
     */
    lastModificationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ServiceConnection
     */
    lastModifierUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnection
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnection
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnection
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnection
     */
    providerTenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnection
     */
    serviceProviderName?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceConnection
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface ServiceConnectionDto
 */
export interface ServiceConnectionDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceConnectionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnectionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnectionDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnectionDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnectionDto
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceConnectionDto
     */
    serviceProviderName?: string;
}
/**
 * 
 * @export
 * @interface ServiceConnectionDtoPagedResultDto
 */
export interface ServiceConnectionDtoPagedResultDto {
    /**
     * 
     * @type {Array<ServiceConnectionDto>}
     * @memberof ServiceConnectionDtoPagedResultDto
     */
    items?: Array<ServiceConnectionDto>;
    /**
     * 
     * @type {number}
     * @memberof ServiceConnectionDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface ServiceFunctionProviderDto
 */
export interface ServiceFunctionProviderDto {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceFunctionProviderDto
     */
    isReadSupported?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceFunctionProviderDto
     */
    isWriteSupported?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceFunctionProviderDto
     */
    isDeleteSupported?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceProviderDto
 */
export interface ServiceProviderDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    providerName?: string;
    /**
     * 
     * @type {ServiceFunctionProviderDto}
     * @memberof ServiceProviderDto
     */
    jobProvider?: ServiceFunctionProviderDto;
    /**
     * 
     * @type {ServiceFunctionProviderDto}
     * @memberof ServiceProviderDto
     */
    jobTaskProvider?: ServiceFunctionProviderDto;
    /**
     * 
     * @type {ServiceFunctionProviderDto}
     * @memberof ServiceProviderDto
     */
    resourceProvider?: ServiceFunctionProviderDto;
    /**
     * 
     * @type {ServiceFunctionProviderDto}
     * @memberof ServiceProviderDto
     */
    categoryProvider?: ServiceFunctionProviderDto;
    /**
     * 
     * @type {ServiceFunctionProviderDto}
     * @memberof ServiceProviderDto
     */
    timeEntityProvider?: ServiceFunctionProviderDto;
    /**
     * 
     * @type {Array<ServiceActionDto>}
     * @memberof ServiceProviderDto
     */
    serviceActions?: Array<ServiceActionDto>;
    /**
     * 
     * @type {Array<ServiceConnectionDto>}
     * @memberof ServiceProviderDto
     */
    serviceConnections?: Array<ServiceConnectionDto>;
}
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Setting
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    creatorUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Setting
     */
    lastModificationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    lastModifierUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface SettingDefinition
 */
export interface SettingDefinition {
    /**
     * 
     * @type {string}
     * @memberof SettingDefinition
     */
    name?: string;
    /**
     * 
     * @type {ILocalizableString}
     * @memberof SettingDefinition
     */
    displayName?: ILocalizableString;
    /**
     * 
     * @type {ILocalizableString}
     * @memberof SettingDefinition
     */
    description?: ILocalizableString;
    /**
     * 
     * @type {SettingScopes}
     * @memberof SettingDefinition
     */
    scopes?: SettingScopes;
    /**
     * 
     * @type {boolean}
     * @memberof SettingDefinition
     */
    isInherited?: boolean;
    /**
     * 
     * @type {SettingDefinitionGroup}
     * @memberof SettingDefinition
     */
    group?: SettingDefinitionGroup;
    /**
     * 
     * @type {string}
     * @memberof SettingDefinition
     */
    defaultValue?: string;
    /**
     * 
     * @type {ISettingClientVisibilityProvider}
     * @memberof SettingDefinition
     */
    clientVisibilityProvider?: ISettingClientVisibilityProvider;
    /**
     * 
     * @type {ModelObject}
     * @memberof SettingDefinition
     */
    customData?: ModelObject;
    /**
     * 
     * @type {boolean}
     * @memberof SettingDefinition
     */
    isEncrypted?: boolean;
}
/**
 * 
 * @export
 * @interface SettingDefinitionGroup
 */
export interface SettingDefinitionGroup {
    /**
     * 
     * @type {string}
     * @memberof SettingDefinitionGroup
     */
    name?: string;
    /**
     * 
     * @type {ILocalizableString}
     * @memberof SettingDefinitionGroup
     */
    displayName?: ILocalizableString;
    /**
     * 
     * @type {SettingDefinitionGroup}
     * @memberof SettingDefinitionGroup
     */
    parent?: SettingDefinitionGroup;
    /**
     * 
     * @type {Array<SettingDefinitionGroup>}
     * @memberof SettingDefinitionGroup
     */
    children?: Array<SettingDefinitionGroup>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SettingScopes {
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_4 = <any> 4,
    NUMBER_7 = <any> 7
}
/**
 * 
 * @export
 * @interface StructLayoutAttribute
 */
export interface StructLayoutAttribute {
    /**
     * 
     * @type {ModelObject}
     * @memberof StructLayoutAttribute
     */
    typeId?: ModelObject;
    /**
     * 
     * @type {LayoutKind}
     * @memberof StructLayoutAttribute
     */
    value?: LayoutKind;
}
/**
 * 
 * @export
 * @interface SynchronizationConfigurationEntryDto
 */
export interface SynchronizationConfigurationEntryDto {
    /**
     * 
     * @type {number}
     * @memberof SynchronizationConfigurationEntryDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SynchronizationConfigurationEntryDto
     */
    sourceServiceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof SynchronizationConfigurationEntryDto
     */
    sourceServiceProvider?: string;
    /**
     * 
     * @type {number}
     * @memberof SynchronizationConfigurationEntryDto
     */
    targetServiceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof SynchronizationConfigurationEntryDto
     */
    targetServiceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof SynchronizationConfigurationEntryDto
     */
    entityType?: string;
    /**
     * 
     * @type {string}
     * @memberof SynchronizationConfigurationEntryDto
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface TeamProjectReference
 */
export interface TeamProjectReference {
    /**
     * 
     * @type {string}
     * @memberof TeamProjectReference
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamProjectReference
     */
    abbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamProjectReference
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamProjectReference
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamProjectReference
     */
    url?: string;
    /**
     * 
     * @type {ProjectState}
     * @memberof TeamProjectReference
     */
    state?: ProjectState;
    /**
     * 
     * @type {number}
     * @memberof TeamProjectReference
     */
    revision?: number;
    /**
     * 
     * @type {ProjectVisibility}
     * @memberof TeamProjectReference
     */
    visibility?: ProjectVisibility;
    /**
     * 
     * @type {string}
     * @memberof TeamProjectReference
     */
    defaultTeamImageUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof TeamProjectReference
     */
    lastUpdateTime?: Date;
}
/**
 * 
 * @export
 * @interface TemplateData
 */
export interface TemplateData {
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    rules?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    description?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TenantAvailabilityState {
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 * 
 * @export
 * @interface TenantDataDto
 */
export interface TenantDataDto {
    /**
     * 
     * @type {number}
     * @memberof TenantDataDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDataDto
     */
    tenancyName: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDataDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof TenantDataDto
     */
    users?: Array<UserDto>;
}
/**
 * 
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * 
     * @type {number}
     * @memberof TenantDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    tenancyName: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDto
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface TenantDtoPagedResultDto
 */
export interface TenantDtoPagedResultDto {
    /**
     * 
     * @type {Array<TenantDto>}
     * @memberof TenantDtoPagedResultDto
     */
    items?: Array<TenantDto>;
    /**
     * 
     * @type {number}
     * @memberof TenantDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface TenantListDto
 */
export interface TenantListDto {
    /**
     * 
     * @type {number}
     * @memberof TenantListDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    tenancyName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    editionDisplayName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TenantListDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TenantListDto
     */
    editionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    isInTrialPeriod?: boolean;
}
/**
 * 
 * @export
 * @interface TenantListDtoPagedResultDto
 */
export interface TenantListDtoPagedResultDto {
    /**
     * 
     * @type {Array<TenantListDto>}
     * @memberof TenantListDtoPagedResultDto
     */
    items?: Array<TenantListDto>;
    /**
     * 
     * @type {number}
     * @memberof TenantListDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface TenantLoginInfoDto
 */
export interface TenantLoginInfoDto {
    /**
     * 
     * @type {number}
     * @memberof TenantLoginInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantLoginInfoDto
     */
    tenancyName?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantLoginInfoDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TimeEntity
 */
export interface TimeEntity {
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntity
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof TimeEntity
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    serviceProvider?: string;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TimeEntity
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {number}
     * @memberof TimeEntity
     */
    amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntity
     */
    hasNegativeAmount?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TimeEntity
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeEntity
     */
    endTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntity
     */
    useDuration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    description2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntity
     */
    isPlanningEntity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntity
     */
    isEditable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    categoryId?: string;
    /**
     * 
     * @type {Category}
     * @memberof TimeEntity
     */
    categoryFk?: Category;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    categoryReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    customerId?: string;
    /**
     * 
     * @type {Customer}
     * @memberof TimeEntity
     */
    customerFk?: Customer;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    customerReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    jobId?: string;
    /**
     * 
     * @type {Job}
     * @memberof TimeEntity
     */
    jobFk?: Job;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    jobReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    jobTaskId?: string;
    /**
     * 
     * @type {JobTask}
     * @memberof TimeEntity
     */
    jobTaskFk?: JobTask;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    jobTaskReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    resourceId?: string;
    /**
     * 
     * @type {Resource}
     * @memberof TimeEntity
     */
    resourceFk?: Resource;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    resourceReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    sessionId?: string;
    /**
     * 
     * @type {TimeTrackingSession}
     * @memberof TimeEntity
     */
    sessionFk?: TimeTrackingSession;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntity
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof TimeEntity
     */
    userFk?: User;
    /**
     * 
     * @type {ModelObject}
     * @memberof TimeEntity
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    extensionData?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeEntity
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TimeEntity
     */
    creatorUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeEntity
     */
    tenantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntity
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    templateId?: string;
    /**
     * 
     * @type {TimeEntity}
     * @memberof TimeEntity
     */
    templateFk?: TimeEntity;
    /**
     * 
     * @type {string}
     * @memberof TimeEntity
     */
    templateReference?: string;
}
/**
 * 
 * @export
 * @interface TimeEntityDto
 */
export interface TimeEntityDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntityDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    duration?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntityDto
     */
    amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntityDto
     */
    hasNegativeAmount?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TimeEntityDto
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeEntityDto
     */
    endTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntityDto
     */
    useDuration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    description1?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    description2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntityDto
     */
    isPlanningEntity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntityDto
     */
    isEditable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    categoryId?: string;
    /**
     * 
     * @type {Category}
     * @memberof TimeEntityDto
     */
    categoryFk?: Category;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    categoryReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    customerId?: string;
    /**
     * 
     * @type {Customer}
     * @memberof TimeEntityDto
     */
    customerFk?: Customer;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    customerReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    jobId?: string;
    /**
     * 
     * @type {Job}
     * @memberof TimeEntityDto
     */
    jobFk?: Job;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    jobReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    jobTaskId?: string;
    /**
     * 
     * @type {JobTask}
     * @memberof TimeEntityDto
     */
    jobTaskFk?: JobTask;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    jobTaskReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    resourceId?: string;
    /**
     * 
     * @type {Resource}
     * @memberof TimeEntityDto
     */
    resourceFk?: Resource;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    resourceReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    sessionId?: string;
    /**
     * 
     * @type {TimeTrackingSession}
     * @memberof TimeEntityDto
     */
    sessionFk?: TimeTrackingSession;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    templateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntityDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntityDto
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof TimeEntityDto
     */
    userFk?: User;
    /**
     * 
     * @type {ModelObject}
     * @memberof TimeEntityDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof TimeEntityDto
     */
    extensionData?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeEntityDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TimeEntityDto
     */
    creatorUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeEntityDto
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    ticks?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    days?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    milliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalDays?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMilliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalSeconds?: number;
}
/**
 * 
 * @export
 * @interface TimeTrackingSession
 */
export interface TimeTrackingSession {
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeTrackingSession
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {ServiceConnection}
     * @memberof TimeTrackingSession
     */
    serviceConnectionFk?: ServiceConnection;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeTrackingSession
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TimeTrackingSession
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    jobId?: string;
    /**
     * 
     * @type {Job}
     * @memberof TimeTrackingSession
     */
    jobFk?: Job;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    jobReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    jobTaskId?: string;
    /**
     * 
     * @type {JobTask}
     * @memberof TimeTrackingSession
     */
    jobTaskFk?: JobTask;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    jobTaskReference?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    defaultCategoryId?: string;
    /**
     * 
     * @type {Category}
     * @memberof TimeTrackingSession
     */
    defaultCategoryFk?: Category;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    defaultCategoryReference?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof TimeTrackingSession
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    categoryReferences?: string;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof TimeTrackingSession
     */
    participants?: Array<Resource>;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    participantReferences?: string;
    /**
     * 
     * @type {Array<TimeEntity>}
     * @memberof TimeTrackingSession
     */
    timeEntities?: Array<TimeEntity>;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    timeEntityReferences?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeTrackingSession
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof TimeTrackingSession
     */
    userFk?: User;
    /**
     * 
     * @type {ModelObject}
     * @memberof TimeTrackingSession
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSession
     */
    extensionData?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeTrackingSession
     */
    tenantId?: number;
}
/**
 * 
 * @export
 * @interface TimeTrackingSessionDto
 */
export interface TimeTrackingSessionDto {
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeTrackingSessionDto
     */
    serviceConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    serviceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeTrackingSessionDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TimeTrackingSessionDto
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    jobTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    defaultCategoryId?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof TimeTrackingSessionDto
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof TimeTrackingSessionDto
     */
    participants?: Array<Resource>;
    /**
     * 
     * @type {Array<TimeEntity>}
     * @memberof TimeTrackingSessionDto
     */
    timeEntities?: Array<TimeEntity>;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    timeEntityReferences?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    userId?: string;
    /**
     * 
     * @type {ModelObject}
     * @memberof TimeTrackingSessionDto
     */
    extensions?: ModelObject;
    /**
     * 
     * @type {string}
     * @memberof TimeTrackingSessionDto
     */
    extensionData?: string;
}
/**
 * 
 * @export
 * @interface TimeTrackingSessionDtoPagedResultDto
 */
export interface TimeTrackingSessionDtoPagedResultDto {
    /**
     * 
     * @type {Array<TimeTrackingSessionDto>}
     * @memberof TimeTrackingSessionDtoPagedResultDto
     */
    items?: Array<TimeTrackingSessionDto>;
    /**
     * 
     * @type {number}
     * @memberof TimeTrackingSessionDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TreeNodeStructureType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    name?: string;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Type
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    metadataToken?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isInterface?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof Type
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    assemblyQualifiedName?: string;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    fullName?: string;
    /**
     * 
     * @type {Assembly}
     * @memberof Type
     */
    assembly?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof Type
     */
    module?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNested?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    declaringType?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof Type
     */
    declaringMethod?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    underlyingSystemType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isConstructedGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericTypeParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericMethodParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isGenericTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSZArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isVariableBoundArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isByRefLike?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isFunctionPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isUnmanagedFunctionPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    hasElementType?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Type
     */
    genericTypeArguments?: Array<Type>;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    genericParameterPosition?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof Type
     */
    genericParameterAttributes?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof Type
     */
    attributes?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isImport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSealed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedFamANDAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedFamORAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNestedPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isNotPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAutoLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isExplicitLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isLayoutSequential?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAnsiClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isAutoClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isUnicodeClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isCOMObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isContextful?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isEnum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isMarshalByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isPrimitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isValueType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSignatureType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof Type
     */
    structLayoutAttribute?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof Type
     */
    typeInitializer?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof Type
     */
    typeHandle?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    guid?: string;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    baseType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isSerializable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    isVisible?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeAttributes {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_16 = <any> 16,
    NUMBER_24 = <any> 24,
    NUMBER_32 = <any> 32,
    NUMBER_128 = <any> 128,
    NUMBER_256 = <any> 256,
    NUMBER_1024 = <any> 1024,
    NUMBER_2048 = <any> 2048,
    NUMBER_4096 = <any> 4096,
    NUMBER_8192 = <any> 8192,
    NUMBER_16384 = <any> 16384,
    NUMBER_65536 = <any> 65536,
    NUMBER_131072 = <any> 131072,
    NUMBER_196608 = <any> 196608,
    NUMBER_262144 = <any> 262144,
    NUMBER_264192 = <any> 264192,
    NUMBER_1048576 = <any> 1048576,
    NUMBER_12582912 = <any> 12582912
}
/**
 * 
 * @export
 * @interface TypeInfo
 */
export interface TypeInfo {
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    name?: string;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof TypeInfo
     */
    customAttributes?: Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isCollectible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    metadataToken?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isInterface?: boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof TypeInfo
     */
    memberType?: MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    assemblyQualifiedName?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    fullName?: string;
    /**
     * 
     * @type {Assembly}
     * @memberof TypeInfo
     */
    assembly?: Assembly;
    /**
     * 
     * @type {Module}
     * @memberof TypeInfo
     */
    module?: Module;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNested?: boolean;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    declaringType?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof TypeInfo
     */
    declaringMethod?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    reflectedType?: Type;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    underlyingSystemType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isConstructedGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericTypeParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericMethodParameter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isGenericTypeDefinition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSZArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isVariableBoundArray?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isByRefLike?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isFunctionPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isUnmanagedFunctionPointer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    hasElementType?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    genericTypeArguments?: Array<Type>;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    genericParameterPosition?: number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof TypeInfo
     */
    genericParameterAttributes?: GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof TypeInfo
     */
    attributes?: TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAbstract?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isImport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSealed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSpecialName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedAssembly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedFamANDAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedFamily?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedFamORAssem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNestedPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isNotPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAutoLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isExplicitLayout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isLayoutSequential?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAnsiClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isAutoClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isUnicodeClass?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isCOMObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isContextful?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isEnum?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isMarshalByRef?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isPrimitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isValueType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSignatureType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSecurityCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSecuritySafeCritical?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSecurityTransparent?: boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof TypeInfo
     */
    structLayoutAttribute?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof TypeInfo
     */
    typeInitializer?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof TypeInfo
     */
    typeHandle?: RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    guid?: string;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    baseType?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isSerializable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    containsGenericParameters?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    isVisible?: boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    genericTypeParameters?: Array<Type>;
    /**
     * 
     * @type {Array<ConstructorInfo>}
     * @memberof TypeInfo
     */
    declaredConstructors?: Array<ConstructorInfo>;
    /**
     * 
     * @type {Array<EventInfo>}
     * @memberof TypeInfo
     */
    declaredEvents?: Array<EventInfo>;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof TypeInfo
     */
    declaredFields?: Array<FieldInfo>;
    /**
     * 
     * @type {Array<MemberInfo>}
     * @memberof TypeInfo
     */
    declaredMembers?: Array<MemberInfo>;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof TypeInfo
     */
    declaredMethods?: Array<MethodInfo>;
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof TypeInfo
     */
    declaredNestedTypes?: Array<TypeInfo>;
    /**
     * 
     * @type {Array<PropertyInfo>}
     * @memberof TypeInfo
     */
    declaredProperties?: Array<PropertyInfo>;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    implementedInterfaces?: Array<Type>;
}
/**
 * 
 * @export
 * @interface UpdateAttachmentDto
 */
export interface UpdateAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    targetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    mimeType?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateAttachmentDto
     */
    validTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttachmentDto
     */
    accessRules?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    creatorUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastModificationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    lastModifierUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    deleterUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    deletionTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    authenticationSource?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userName: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    tenantId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailConfirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordResetCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lockoutEndDateUtc?: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    accessFailedCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isLockoutEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isPhoneNumberConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    securityStamp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isTwoFactorEnabled?: boolean;
    /**
     * 
     * @type {Array<UserLogin>}
     * @memberof User
     */
    logins?: Array<UserLogin>;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof User
     */
    roles?: Array<UserRole>;
    /**
     * 
     * @type {Array<UserClaim>}
     * @memberof User
     */
    claims?: Array<UserClaim>;
    /**
     * 
     * @type {Array<UserPermissionSetting>}
     * @memberof User
     */
    permissions?: Array<UserPermissionSetting>;
    /**
     * 
     * @type {Array<Setting>}
     * @memberof User
     */
    settings?: Array<Setting>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isEmailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedUserName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    concurrencyStamp?: string;
    /**
     * 
     * @type {Array<UserToken>}
     * @memberof User
     */
    tokens?: Array<UserToken>;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    deleterUser?: User;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    creatorUser?: User;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    lastModifierUser?: User;
}
/**
 * 
 * @export
 * @interface UserClaim
 */
export interface UserClaim {
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserClaim
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    creatorUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserClaim
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserClaim
     */
    claimValue?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    emailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    fullName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastLoginTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    creationTime?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    roleNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserDtoPagedResultDto
 */
export interface UserDtoPagedResultDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UserDtoPagedResultDto
     */
    items?: Array<UserDto>;
    /**
     * 
     * @type {number}
     * @memberof UserDtoPagedResultDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface UserLogin
 */
export interface UserLogin {
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserLogin
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    loginProvider: string;
    /**
     * 
     * @type {string}
     * @memberof UserLogin
     */
    providerKey: string;
}
/**
 * 
 * @export
 * @interface UserLoginInfoDto
 */
export interface UserLoginInfoDto {
    /**
     * 
     * @type {number}
     * @memberof UserLoginInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoDto
     */
    emailAddress?: string;
}
/**
 * 
 * @export
 * @interface UserPermissionSetting
 */
export interface UserPermissionSetting {
    /**
     * 
     * @type {number}
     * @memberof UserPermissionSetting
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserPermissionSetting
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserPermissionSetting
     */
    creatorUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPermissionSetting
     */
    tenantId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionSetting
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermissionSetting
     */
    isGranted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserPermissionSetting
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserRole
     */
    creationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    creatorUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    roleId?: number;
}
/**
 * 
 * @export
 * @interface UserToken
 */
export interface UserToken {
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    tenantId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    loginProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    value?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserToken
     */
    expireDate?: Date;
}
/**
 * 
 * @export
 * @interface WorkItem
 */
export interface WorkItem {
    /**
     * 
     * @type {string}
     * @memberof WorkItem
     */
    url?: string;
    /**
     * 
     * @type {ReferenceLinks}
     * @memberof WorkItem
     */
    links?: ReferenceLinks;
    /**
     * 
     * @type {number}
     * @memberof WorkItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkItem
     */
    rev?: number;
    /**
     * 
     * @type {{ [key: string]: ModelObject; }}
     * @memberof WorkItem
     */
    fields?: { [key: string]: ModelObject; };
    /**
     * 
     * @type {Array<WorkItemRelation>}
     * @memberof WorkItem
     */
    relations?: Array<WorkItemRelation>;
    /**
     * 
     * @type {WorkItemCommentVersionRef}
     * @memberof WorkItem
     */
    commentVersionRef?: WorkItemCommentVersionRef;
}
/**
 * 
 * @export
 * @interface WorkItemClassificationNode
 */
export interface WorkItemClassificationNode {
    /**
     * 
     * @type {string}
     * @memberof WorkItemClassificationNode
     */
    url?: string;
    /**
     * 
     * @type {ReferenceLinks}
     * @memberof WorkItemClassificationNode
     */
    links?: ReferenceLinks;
    /**
     * 
     * @type {number}
     * @memberof WorkItemClassificationNode
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkItemClassificationNode
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemClassificationNode
     */
    name?: string;
    /**
     * 
     * @type {TreeNodeStructureType}
     * @memberof WorkItemClassificationNode
     */
    structureType?: TreeNodeStructureType;
    /**
     * 
     * @type {boolean}
     * @memberof WorkItemClassificationNode
     */
    hasChildren?: boolean;
    /**
     * 
     * @type {Array<WorkItemClassificationNode>}
     * @memberof WorkItemClassificationNode
     */
    children?: Array<WorkItemClassificationNode>;
    /**
     * 
     * @type {{ [key: string]: ModelObject; }}
     * @memberof WorkItemClassificationNode
     */
    attributes?: { [key: string]: ModelObject; };
    /**
     * 
     * @type {string}
     * @memberof WorkItemClassificationNode
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface WorkItemCommentVersionRef
 */
export interface WorkItemCommentVersionRef {
    /**
     * 
     * @type {string}
     * @memberof WorkItemCommentVersionRef
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkItemCommentVersionRef
     */
    commentId?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkItemCommentVersionRef
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkItemCommentVersionRef
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkItemCommentVersionRef
     */
    createdInRevision?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkItemCommentVersionRef
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface WorkItemJobMapping
 */
export interface WorkItemJobMapping {
    /**
     * 
     * @type {string}
     * @memberof WorkItemJobMapping
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemJobMapping
     */
    projectName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkItemJobMapping
     */
    areaNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkItemJobMapping
     */
    jobNo?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemJobMapping
     */
    defaultJobTaskBaseNo?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemJobMapping
     */
    defaultCategoryNo?: string;
    /**
     * 
     * @type {Array<WorkItemTypeMapping>}
     * @memberof WorkItemJobMapping
     */
    workItemTypeMappings?: Array<WorkItemTypeMapping>;
}
/**
 * 
 * @export
 * @interface WorkItemRelation
 */
export interface WorkItemRelation {
    /**
     * 
     * @type {string}
     * @memberof WorkItemRelation
     */
    rel?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemRelation
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemRelation
     */
    title?: string;
    /**
     * 
     * @type {{ [key: string]: ModelObject; }}
     * @memberof WorkItemRelation
     */
    attributes?: { [key: string]: ModelObject; };
}
/**
 * 
 * @export
 * @interface WorkItemTypeMapping
 */
export interface WorkItemTypeMapping {
    /**
     * 
     * @type {string}
     * @memberof WorkItemTypeMapping
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemTypeMapping
     */
    jobTaskBaseNo?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItemTypeMapping
     */
    workTypeCode?: string;
}
/**
 * AccountApi - fetch parameter creator
 * @export
 */
export const AccountApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IsTenantAvailableInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountIsTenantAvailablePost(body?: IsTenantAvailableInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Account/IsTenantAvailable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"IsTenantAvailableInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountRegisterPost(body?: RegisterInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Account/Register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountResetPasswordPost(body?: ResetPasswordInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Account/ResetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResetPasswordInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResolveTenantIdInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountResolveTenantIdPost(body?: ResolveTenantIdInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Account/ResolveTenantId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResolveTenantIdInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendPasswordResetCodeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountSendPasswordResetCodePost(body?: SendPasswordResetCodeInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Account/SendPasswordResetCode`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SendPasswordResetCodeInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IsTenantAvailableInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountIsTenantAvailablePost(body?: IsTenantAvailableInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IsTenantAvailableOutput> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiServicesAppAccountIsTenantAvailablePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RegisterInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountRegisterPost(body?: RegisterInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegisterOutput> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiServicesAppAccountRegisterPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResetPasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountResetPasswordPost(body?: ResetPasswordInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResetPasswordOutput> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiServicesAppAccountResetPasswordPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResolveTenantIdInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountResolveTenantIdPost(body?: ResolveTenantIdInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiServicesAppAccountResolveTenantIdPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SendPasswordResetCodeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountSendPasswordResetCodePost(body?: SendPasswordResetCodeInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiServicesAppAccountSendPasswordResetCodePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {IsTenantAvailableInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountIsTenantAvailablePost(body?: IsTenantAvailableInput, options?: any) {
            return AccountApiFp(configuration).apiServicesAppAccountIsTenantAvailablePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RegisterInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountRegisterPost(body?: RegisterInput, options?: any) {
            return AccountApiFp(configuration).apiServicesAppAccountRegisterPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResetPasswordInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountResetPasswordPost(body?: ResetPasswordInput, options?: any) {
            return AccountApiFp(configuration).apiServicesAppAccountResetPasswordPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResolveTenantIdInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountResolveTenantIdPost(body?: ResolveTenantIdInput, options?: any) {
            return AccountApiFp(configuration).apiServicesAppAccountResolveTenantIdPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SendPasswordResetCodeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAccountSendPasswordResetCodePost(body?: SendPasswordResetCodeInput, options?: any) {
            return AccountApiFp(configuration).apiServicesAppAccountSendPasswordResetCodePost(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {IsTenantAvailableInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiServicesAppAccountIsTenantAvailablePost(body?: IsTenantAvailableInput, options?: any) {
        return AccountApiFp(this.configuration).apiServicesAppAccountIsTenantAvailablePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RegisterInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiServicesAppAccountRegisterPost(body?: RegisterInput, options?: any) {
        return AccountApiFp(this.configuration).apiServicesAppAccountRegisterPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResetPasswordInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiServicesAppAccountResetPasswordPost(body?: ResetPasswordInput, options?: any) {
        return AccountApiFp(this.configuration).apiServicesAppAccountResetPasswordPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResolveTenantIdInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiServicesAppAccountResolveTenantIdPost(body?: ResolveTenantIdInput, options?: any) {
        return AccountApiFp(this.configuration).apiServicesAppAccountResolveTenantIdPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SendPasswordResetCodeInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiServicesAppAccountSendPasswordResetCodePost(body?: SendPasswordResetCodeInput, options?: any) {
        return AccountApiFp(this.configuration).apiServicesAppAccountSendPasswordResetCodePost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AdministrationApi - fetch parameter creator
 * @export
 */
export const AdministrationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserInput} [body] 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationAddUserPost(body?: CreateUserInput, tenantId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Administration/AddUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationGetCoreAdminDataGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Administration/GetCoreAdminData`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailConfiguration} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationUpdateEmailSettingsPut(body?: EmailConfiguration, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Administration/UpdateEmailSettings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmailConfiguration" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdministrationApi - functional programming interface
 * @export
 */
export const AdministrationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserInput} [body] 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationAddUserPost(body?: CreateUserInput, tenantId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).apiServicesAppAdministrationAddUserPost(body, tenantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationGetCoreAdminDataGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdministrationDataDto> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).apiServicesAppAdministrationGetCoreAdminDataGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {EmailConfiguration} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationUpdateEmailSettingsPut(body?: EmailConfiguration, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).apiServicesAppAdministrationUpdateEmailSettingsPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdministrationApi - factory interface
 * @export
 */
export const AdministrationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateUserInput} [body] 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationAddUserPost(body?: CreateUserInput, tenantId?: number, options?: any) {
            return AdministrationApiFp(configuration).apiServicesAppAdministrationAddUserPost(body, tenantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationGetCoreAdminDataGet(options?: any) {
            return AdministrationApiFp(configuration).apiServicesAppAdministrationGetCoreAdminDataGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {EmailConfiguration} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAdministrationUpdateEmailSettingsPut(body?: EmailConfiguration, options?: any) {
            return AdministrationApiFp(configuration).apiServicesAppAdministrationUpdateEmailSettingsPut(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdministrationApi - object-oriented interface
 * @export
 * @class AdministrationApi
 * @extends {BaseAPI}
 */
export class AdministrationApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserInput} [body] 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public apiServicesAppAdministrationAddUserPost(body?: CreateUserInput, tenantId?: number, options?: any) {
        return AdministrationApiFp(this.configuration).apiServicesAppAdministrationAddUserPost(body, tenantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public apiServicesAppAdministrationGetCoreAdminDataGet(options?: any) {
        return AdministrationApiFp(this.configuration).apiServicesAppAdministrationGetCoreAdminDataGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {EmailConfiguration} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public apiServicesAppAdministrationUpdateEmailSettingsPut(body?: EmailConfiguration, options?: any) {
        return AdministrationApiFp(this.configuration).apiServicesAppAdministrationUpdateEmailSettingsPut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AttachmentApi - fetch parameter creator
 * @export
 */
export const AttachmentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAttachmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentCreatePost(body?: CreateAttachmentDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Attachment/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateAttachmentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentDeleteDelete(id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Attachment/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetAllForEntityGet(entityType: string, entityId: string, options: any = {}): FetchArgs {
            // verify required parameter 'entityType' is not null or undefined
            if (entityType === null || entityType === undefined) {
                throw new RequiredError('entityType','Required parameter entityType was null or undefined when calling apiServicesAppAttachmentGetAllForEntityGet.');
            }
            // verify required parameter 'entityId' is not null or undefined
            if (entityId === null || entityId === undefined) {
                throw new RequiredError('entityId','Required parameter entityId was null or undefined when calling apiServicesAppAttachmentGetAllForEntityGet.');
            }
            const localVarPath = `/api/services/app/Attachment/GetAllForEntity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Attachment/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetForAllEntitiesInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetForAllEntitiesGet(body?: GetForAllEntitiesInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Attachment/GetForAllEntities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetForAllEntitiesInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetGet(id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Attachment/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAttachmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentUpdatePut(body?: UpdateAttachmentDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Attachment/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateAttachmentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAttachmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentCreatePost(body?: CreateAttachmentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentDto> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentDeleteDelete(id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetAllForEntityGet(entityType: string, entityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AttachmentDto>> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentGetAllForEntityGet(entityType, entityId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentDtoPagedResultDto> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentGetAllGet(sorting, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetForAllEntitiesInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetForAllEntitiesGet(body?: GetForAllEntitiesInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AttachmentDto>> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentGetForAllEntitiesGet(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetGet(id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentDto> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateAttachmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentUpdatePut(body?: UpdateAttachmentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentDto> {
            const localVarFetchArgs = AttachmentApiFetchParamCreator(configuration).apiServicesAppAttachmentUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateAttachmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentCreatePost(body?: CreateAttachmentDto, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentDeleteDelete(id?: string, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetAllForEntityGet(entityType: string, entityId: string, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentGetAllForEntityGet(entityType, entityId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentGetAllGet(sorting, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetForAllEntitiesInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetForAllEntitiesGet(body?: GetForAllEntitiesInput, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentGetForAllEntitiesGet(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentGetGet(id?: string, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateAttachmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppAttachmentUpdatePut(body?: UpdateAttachmentDto, options?: any) {
            return AttachmentApiFp(configuration).apiServicesAppAttachmentUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * 
     * @param {CreateAttachmentDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentCreatePost(body?: CreateAttachmentDto, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentDeleteDelete(id?: string, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} entityType 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentGetAllForEntityGet(entityType: string, entityId: string, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentGetAllForEntityGet(entityType, entityId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentGetAllGet(sorting, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {GetForAllEntitiesInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentGetForAllEntitiesGet(body?: GetForAllEntitiesInput, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentGetForAllEntitiesGet(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentGetGet(id?: string, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateAttachmentDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public apiServicesAppAttachmentUpdatePut(body?: UpdateAttachmentDto, options?: any) {
        return AttachmentApiFp(this.configuration).apiServicesAppAttachmentUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AuthenticationApi - fetch parameter creator
 * @export
 */
export const AuthenticationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationBcAuthorizeApiGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/bc/AuthorizeApi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [state] 
         * @param {string} [idToken] 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/dev-ops/Authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['idToken'] = idToken;
            }

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['errorDescription'] = errorDescription;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthorizeApiGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/dev-ops/AuthorizeApi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthorizeGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/dev-ops/Authorize`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [state] 
         * @param {string} [idToken] 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/graph/Authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['idToken'] = idToken;
            }

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['errorDescription'] = errorDescription;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthorizeApiGet(identifier?: string, value?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/graph/AuthorizeApi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (value !== undefined) {
                localVarQueryParameter['Value'] = value;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthorizeGet(identifier?: string, value?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/graph/Authorize`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (value !== undefined) {
                localVarQueryParameter['Value'] = value;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphRefreshLoginGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/graph/RefreshLogin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphTestGraphGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/authentication/graph/TestGraph`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationBcAuthorizeApiGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthorizeApiResult> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationBcAuthorizeApiGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [state] 
         * @param {string} [idToken] 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationDevOpsAuthenticateGet(code, state, idToken, error, errorDescription, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthorizeApiGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthorizeApiResult> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationDevOpsAuthorizeApiGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthorizeGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationDevOpsAuthorizeGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [state] 
         * @param {string} [idToken] 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationGraphAuthenticateGet(code, state, idToken, error, errorDescription, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthorizeApiGet(identifier?: string, value?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthorizeApiResult> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationGraphAuthorizeApiGet(identifier, value, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthorizeGet(identifier?: string, value?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationGraphAuthorizeGet(identifier, value, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphRefreshLoginGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationGraphRefreshLoginGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphTestGraphGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).apiAuthenticationGraphTestGraphGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationBcAuthorizeApiGet(options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationBcAuthorizeApiGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [state] 
         * @param {string} [idToken] 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationDevOpsAuthenticateGet(code, state, idToken, error, errorDescription, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthorizeApiGet(options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationDevOpsAuthorizeApiGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationDevOpsAuthorizeGet(options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationDevOpsAuthorizeGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [state] 
         * @param {string} [idToken] 
         * @param {string} [error] 
         * @param {string} [errorDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationGraphAuthenticateGet(code, state, idToken, error, errorDescription, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthorizeApiGet(identifier?: string, value?: string, options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationGraphAuthorizeApiGet(identifier, value, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphAuthorizeGet(identifier?: string, value?: string, options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationGraphAuthorizeGet(identifier, value, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphRefreshLoginGet(options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationGraphRefreshLoginGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationGraphTestGraphGet(options?: any) {
            return AuthenticationApiFp(configuration).apiAuthenticationGraphTestGraphGet(options)(fetch, basePath);
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationBcAuthorizeApiGet(options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationBcAuthorizeApiGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [code] 
     * @param {string} [state] 
     * @param {string} [idToken] 
     * @param {string} [error] 
     * @param {string} [errorDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationDevOpsAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationDevOpsAuthenticateGet(code, state, idToken, error, errorDescription, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationDevOpsAuthorizeApiGet(options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationDevOpsAuthorizeApiGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationDevOpsAuthorizeGet(options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationDevOpsAuthorizeGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [code] 
     * @param {string} [state] 
     * @param {string} [idToken] 
     * @param {string} [error] 
     * @param {string} [errorDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationGraphAuthenticateGet(code?: string, state?: string, idToken?: string, error?: string, errorDescription?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationGraphAuthenticateGet(code, state, idToken, error, errorDescription, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [identifier] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationGraphAuthorizeApiGet(identifier?: string, value?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationGraphAuthorizeApiGet(identifier, value, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [identifier] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationGraphAuthorizeGet(identifier?: string, value?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationGraphAuthorizeGet(identifier, value, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationGraphRefreshLoginGet(options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationGraphRefreshLoginGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationGraphTestGraphGet(options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationGraphTestGraphGet(options)(this.fetch, this.basePath);
    }

}
/**
 * CategoryApi - fetch parameter creator
 * @export
 */
export const CategoryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryCreatePost(body?: CategoryDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryDeleteDelete(body?: CategoryDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetGet(body?: CategoryDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryUpdatePut(body?: CategoryDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Category/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryCreatePost(body?: CategoryDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryDto> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryDeleteDelete(body?: CategoryDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CategoryDto>> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CategoryDto>> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryGetAllGet(serviceProviderName, serviceConnectionId, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryDto> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetGet(body?: CategoryDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryDto> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryUpdatePut(body?: CategoryDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryDto> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).apiServicesAppCategoryUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryCreatePost(body?: CategoryDto, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryDeleteDelete(body?: CategoryDto, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryGetAllGet(serviceProviderName, serviceConnectionId, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryGetGet(body?: CategoryDto, includeFields?: Array<string>, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CategoryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCategoryUpdatePut(body?: CategoryDto, options?: any) {
            return CategoryApiFp(configuration).apiServicesAppCategoryUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {CategoryDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryCreatePost(body?: CategoryDto, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CategoryDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryDeleteDelete(body?: CategoryDto, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryGetAllGet(serviceProviderName, serviceConnectionId, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CategoryDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryGetGet(body?: CategoryDto, includeFields?: Array<string>, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CategoryDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiServicesAppCategoryUpdatePut(body?: CategoryDto, options?: any) {
        return CategoryApiFp(this.configuration).apiServicesAppCategoryUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ChartsApi - fetch parameter creator
 * @export
 */
export const ChartsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [chartName] 
         * @param {ChartingTimeInterval} [interval] 
         * @param {boolean} [userDataOnly] 
         * @param {string} [serviceProvider] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [args] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppChartsGetChartDataGet(chartName?: string, interval?: ChartingTimeInterval, userDataOnly?: boolean, serviceProvider?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, args?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Charts/GetChartData`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (chartName !== undefined) {
                localVarQueryParameter['chartName'] = chartName;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (userDataOnly !== undefined) {
                localVarQueryParameter['userDataOnly'] = userDataOnly;
            }

            if (serviceProvider !== undefined) {
                localVarQueryParameter['serviceProvider'] = serviceProvider;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (args) {
                localVarQueryParameter['args'] = args;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartsApi - functional programming interface
 * @export
 */
export const ChartsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [chartName] 
         * @param {ChartingTimeInterval} [interval] 
         * @param {boolean} [userDataOnly] 
         * @param {string} [serviceProvider] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [args] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppChartsGetChartDataGet(chartName?: string, interval?: ChartingTimeInterval, userDataOnly?: boolean, serviceProvider?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, args?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ChartsApiFetchParamCreator(configuration).apiServicesAppChartsGetChartDataGet(chartName, interval, userDataOnly, serviceProvider, serviceConnectionId, internalFilters, externalFilters, args, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ChartsApi - factory interface
 * @export
 */
export const ChartsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [chartName] 
         * @param {ChartingTimeInterval} [interval] 
         * @param {boolean} [userDataOnly] 
         * @param {string} [serviceProvider] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [args] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppChartsGetChartDataGet(chartName?: string, interval?: ChartingTimeInterval, userDataOnly?: boolean, serviceProvider?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, args?: Array<string>, options?: any) {
            return ChartsApiFp(configuration).apiServicesAppChartsGetChartDataGet(chartName, interval, userDataOnly, serviceProvider, serviceConnectionId, internalFilters, externalFilters, args, options)(fetch, basePath);
        },
    };
};

/**
 * ChartsApi - object-oriented interface
 * @export
 * @class ChartsApi
 * @extends {BaseAPI}
 */
export class ChartsApi extends BaseAPI {
    /**
     * 
     * @param {string} [chartName] 
     * @param {ChartingTimeInterval} [interval] 
     * @param {boolean} [userDataOnly] 
     * @param {string} [serviceProvider] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [args] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public apiServicesAppChartsGetChartDataGet(chartName?: string, interval?: ChartingTimeInterval, userDataOnly?: boolean, serviceProvider?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, args?: Array<string>, options?: any) {
        return ChartsApiFp(this.configuration).apiServicesAppChartsGetChartDataGet(chartName, interval, userDataOnly, serviceProvider, serviceConnectionId, internalFilters, externalFilters, args, options)(this.fetch, this.basePath);
    }

}
/**
 * ConfigurationApi - fetch parameter creator
 * @export
 */
export const ConfigurationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeUiThemeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppConfigurationChangeUiThemePost(body?: ChangeUiThemeInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Configuration/ChangeUiTheme`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangeUiThemeInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeUiThemeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppConfigurationChangeUiThemePost(body?: ChangeUiThemeInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ConfigurationApiFetchParamCreator(configuration).apiServicesAppConfigurationChangeUiThemePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ChangeUiThemeInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppConfigurationChangeUiThemePost(body?: ChangeUiThemeInput, options?: any) {
            return ConfigurationApiFp(configuration).apiServicesAppConfigurationChangeUiThemePost(body, options)(fetch, basePath);
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {ChangeUiThemeInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public apiServicesAppConfigurationChangeUiThemePost(body?: ChangeUiThemeInput, options?: any) {
        return ConfigurationApiFp(this.configuration).apiServicesAppConfigurationChangeUiThemePost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CustomerApi - fetch parameter creator
 * @export
 */
export const CustomerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerCreatePost(body?: CustomerDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerDeleteDelete(body?: CustomerDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetGet(body?: CustomerDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerUpdatePut(body?: CustomerDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Customer/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerCreatePost(body?: CustomerDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerDto> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerDeleteDelete(body?: CustomerDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerDto>> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerDto>> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerGetAllGet(serviceProviderName, serviceConnectionId, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerDto> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetGet(body?: CustomerDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerDto> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerUpdatePut(body?: CustomerDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerDto> {
            const localVarFetchArgs = CustomerApiFetchParamCreator(configuration).apiServicesAppCustomerUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerCreatePost(body?: CustomerDto, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerDeleteDelete(body?: CustomerDto, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerGetAllGet(serviceProviderName, serviceConnectionId, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerGetGet(body?: CustomerDto, includeFields?: Array<string>, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CustomerDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppCustomerUpdatePut(body?: CustomerDto, options?: any) {
            return CustomerApiFp(configuration).apiServicesAppCustomerUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {CustomerDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerCreatePost(body?: CustomerDto, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CustomerDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerDeleteDelete(body?: CustomerDto, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerGetAllGet(serviceProviderName, serviceConnectionId, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CustomerDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerGetGet(body?: CustomerDto, includeFields?: Array<string>, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CustomerDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiServicesAppCustomerUpdatePut(body?: CustomerDto, options?: any) {
        return CustomerApiFp(this.configuration).apiServicesAppCustomerUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * DashboardDataApi - fetch parameter creator
 * @export
 */
export const DashboardDataApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/DashboardData/GetCurrentWorkState`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount?: number, skipCount?: number, filter?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/DashboardData/GetCurrentWorkStateWithJobData`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardDataApi - functional programming interface
 * @export
 */
export const DashboardDataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkItem>> {
            const localVarFetchArgs = DashboardDataApiFetchParamCreator(configuration).apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount?: number, skipCount?: number, filter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MappedWorkItem>> {
            const localVarFetchArgs = DashboardDataApiFetchParamCreator(configuration).apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount, skipCount, filter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DashboardDataApi - factory interface
 * @export
 */
export const DashboardDataApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId?: string, options?: any) {
            return DashboardDataApiFp(configuration).apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount?: number, skipCount?: number, filter?: string, options?: any) {
            return DashboardDataApiFp(configuration).apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount, skipCount, filter, options)(fetch, basePath);
        },
    };
};

/**
 * DashboardDataApi - object-oriented interface
 * @export
 * @class DashboardDataApi
 * @extends {BaseAPI}
 */
export class DashboardDataApi extends BaseAPI {
    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardDataApi
     */
    public apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId?: string, options?: any) {
        return DashboardDataApiFp(this.configuration).apiServicesDevOpsDashboardDataGetCurrentWorkStateGet(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [maxResultCount] 
     * @param {number} [skipCount] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardDataApi
     */
    public apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount?: number, skipCount?: number, filter?: string, options?: any) {
        return DashboardDataApiFp(this.configuration).apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(maxResultCount, skipCount, filter, options)(this.fetch, this.basePath);
    }

}
/**
 * DataExchangeApi - fetch parameter creator
 * @export
 */
export const DataExchangeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolWithEntriesGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataExchange/GetProtocolWithEntries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [exchangeType] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolsGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataExchange/GetProtocols`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (exchangeType !== undefined) {
                localVarQueryParameter['ExchangeType'] = exchangeType;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [exchangeType] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataExchange/GetProtocolsWithEntries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (exchangeType !== undefined) {
                localVarQueryParameter['ExchangeType'] = exchangeType;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataExchangeApi - functional programming interface
 * @export
 */
export const DataExchangeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolWithEntriesGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataExchangeProtocol> {
            const localVarFetchArgs = DataExchangeApiFetchParamCreator(configuration).apiServicesAppDataExchangeGetProtocolWithEntriesGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [exchangeType] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolsGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataExchangeProtocolPagedResultDto> {
            const localVarFetchArgs = DataExchangeApiFetchParamCreator(configuration).apiServicesAppDataExchangeGetProtocolsGet(exchangeType, filter, sorting, maxResultCount, skipCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [exchangeType] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataExchangeProtocolPagedResultDto> {
            const localVarFetchArgs = DataExchangeApiFetchParamCreator(configuration).apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType, filter, sorting, maxResultCount, skipCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DataExchangeApi - factory interface
 * @export
 */
export const DataExchangeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolWithEntriesGet(id?: number, options?: any) {
            return DataExchangeApiFp(configuration).apiServicesAppDataExchangeGetProtocolWithEntriesGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [exchangeType] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolsGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
            return DataExchangeApiFp(configuration).apiServicesAppDataExchangeGetProtocolsGet(exchangeType, filter, sorting, maxResultCount, skipCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [exchangeType] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
            return DataExchangeApiFp(configuration).apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType, filter, sorting, maxResultCount, skipCount, options)(fetch, basePath);
        },
    };
};

/**
 * DataExchangeApi - object-oriented interface
 * @export
 * @class DataExchangeApi
 * @extends {BaseAPI}
 */
export class DataExchangeApi extends BaseAPI {
    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public apiServicesAppDataExchangeGetProtocolWithEntriesGet(id?: number, options?: any) {
        return DataExchangeApiFp(this.configuration).apiServicesAppDataExchangeGetProtocolWithEntriesGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [exchangeType] 
     * @param {string} [filter] 
     * @param {string} [sorting] 
     * @param {number} [maxResultCount] 
     * @param {number} [skipCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public apiServicesAppDataExchangeGetProtocolsGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
        return DataExchangeApiFp(this.configuration).apiServicesAppDataExchangeGetProtocolsGet(exchangeType, filter, sorting, maxResultCount, skipCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [exchangeType] 
     * @param {string} [filter] 
     * @param {string} [sorting] 
     * @param {number} [maxResultCount] 
     * @param {number} [skipCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataExchangeApi
     */
    public apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType?: string, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
        return DataExchangeApiFp(this.configuration).apiServicesAppDataExchangeGetProtocolsWithEntriesGet(exchangeType, filter, sorting, maxResultCount, skipCount, options)(this.fetch, this.basePath);
    }

}
/**
 * DataMappingApi - fetch parameter creator
 * @export
 */
export const DataMappingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateMultiMappingInputDto>} [body] 
         * @param {string} [mappingName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingCreateMultiMappingPost(body?: Array<CreateMultiMappingInputDto>, mappingName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/CreateMultiMapping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mappingName !== undefined) {
                localVarQueryParameter['mappingName'] = mappingName;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CreateMultiMappingInputDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataMappingDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingCreatePost(body?: DataMappingDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DataMappingDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [mappingName] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetAllFilteredGet(mappingName?: string, userId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/GetAllFiltered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mappingName !== undefined) {
                localVarQueryParameter['mappingName'] = mappingName;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataMappingDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingUpdatePut(body?: DataMappingDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/DataMapping/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DataMappingDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataMappingApi - functional programming interface
 * @export
 */
export const DataMappingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateMultiMappingInputDto>} [body] 
         * @param {string} [mappingName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingCreateMultiMappingPost(body?: Array<CreateMultiMappingInputDto>, mappingName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DataMappingDto>> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingCreateMultiMappingPost(body, mappingName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DataMappingDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingCreatePost(body?: DataMappingDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMappingDto> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [mappingName] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetAllFilteredGet(mappingName?: string, userId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DataMappingDto>> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingGetAllFilteredGet(mappingName, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMappingDtoPagedResultDto> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingGetAllGet(sorting, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMappingDto> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DataMappingDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingUpdatePut(body?: DataMappingDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMappingDto> {
            const localVarFetchArgs = DataMappingApiFetchParamCreator(configuration).apiServicesAppDataMappingUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DataMappingApi - factory interface
 * @export
 */
export const DataMappingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Array<CreateMultiMappingInputDto>} [body] 
         * @param {string} [mappingName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingCreateMultiMappingPost(body?: Array<CreateMultiMappingInputDto>, mappingName?: string, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingCreateMultiMappingPost(body, mappingName, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DataMappingDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingCreatePost(body?: DataMappingDto, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingDeleteDelete(id?: number, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [mappingName] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetAllFilteredGet(mappingName?: string, userId?: number, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingGetAllFilteredGet(mappingName, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingGetAllGet(sorting, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingGetGet(id?: number, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DataMappingDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppDataMappingUpdatePut(body?: DataMappingDto, options?: any) {
            return DataMappingApiFp(configuration).apiServicesAppDataMappingUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * DataMappingApi - object-oriented interface
 * @export
 * @class DataMappingApi
 * @extends {BaseAPI}
 */
export class DataMappingApi extends BaseAPI {
    /**
     * 
     * @param {Array<CreateMultiMappingInputDto>} [body] 
     * @param {string} [mappingName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingCreateMultiMappingPost(body?: Array<CreateMultiMappingInputDto>, mappingName?: string, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingCreateMultiMappingPost(body, mappingName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DataMappingDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingCreatePost(body?: DataMappingDto, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingDeleteDelete(id?: number, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [mappingName] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingGetAllFilteredGet(mappingName?: string, userId?: number, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingGetAllFilteredGet(mappingName, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingGetAllGet(sorting, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingGetGet(id?: number, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DataMappingDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataMappingApi
     */
    public apiServicesAppDataMappingUpdatePut(body?: DataMappingDto, options?: any) {
        return DataMappingApiFp(this.configuration).apiServicesAppDataMappingUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobApi - fetch parameter creator
 * @export
 */
export const JobApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobCreatePost(body?: JobDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobDeleteDelete(body?: JobDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetGet(body?: JobDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobUpdatePut(body?: JobDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Job/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobCreatePost(body?: JobDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobDto> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobDeleteDelete(body?: JobDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobDto>> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobDto>> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobDto> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetGet(body?: JobDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobDto> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobUpdatePut(body?: JobDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobDto> {
            const localVarFetchArgs = JobApiFetchParamCreator(configuration).apiServicesAppJobUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobCreatePost(body?: JobDto, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobDeleteDelete(body?: JobDto, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobGetGet(body?: JobDto, includeFields?: Array<string>, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobUpdatePut(body?: JobDto, options?: any) {
            return JobApiFp(configuration).apiServicesAppJobUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
    /**
     * 
     * @param {JobDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobCreatePost(body?: JobDto, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobDeleteDelete(body?: JobDto, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobGetGet(body?: JobDto, includeFields?: Array<string>, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public apiServicesAppJobUpdatePut(body?: JobDto, options?: any) {
        return JobApiFp(this.configuration).apiServicesAppJobUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobJournalBatchApi - fetch parameter creator
 * @export
 */
export const JobJournalBatchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchCreatePost(body?: JobJournalBatchDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobJournalBatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchDeleteDelete(body?: JobJournalBatchDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobJournalBatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetAllGet(providerName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetByIdGet(id?: string, providerName?: string, serviceConnectionId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetGet(body?: JobJournalBatchDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobJournalBatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchUpdatePut(body?: JobJournalBatchDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/bc/delux/JobJournalBatch/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobJournalBatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobJournalBatchApi - functional programming interface
 * @export
 */
export const JobJournalBatchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchCreatePost(body?: JobJournalBatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobJournalBatchDto> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchDeleteDelete(body?: JobJournalBatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobJournalBatchDto>> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetAllGet(providerName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobJournalBatchDto>> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchGetAllGet(providerName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetByIdGet(id?: string, providerName?: string, serviceConnectionId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobJournalBatchDto> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchGetByIdGet(id, providerName, serviceConnectionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetGet(body?: JobJournalBatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobJournalBatchDto> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchGetGet(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchUpdatePut(body?: JobJournalBatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobJournalBatchDto> {
            const localVarFetchArgs = JobJournalBatchApiFetchParamCreator(configuration).apiServicesBcDeluxJobJournalBatchUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobJournalBatchApi - factory interface
 * @export
 */
export const JobJournalBatchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchCreatePost(body?: JobJournalBatchDto, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchDeleteDelete(body?: JobJournalBatchDto, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id?: string, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetAllGet(providerName?: string, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchGetAllGet(providerName, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetByIdGet(id?: string, providerName?: string, serviceConnectionId?: number, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchGetByIdGet(id, providerName, serviceConnectionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchGetGet(body?: JobJournalBatchDto, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchGetGet(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobJournalBatchDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesBcDeluxJobJournalBatchUpdatePut(body?: JobJournalBatchDto, options?: any) {
            return JobJournalBatchApiFp(configuration).apiServicesBcDeluxJobJournalBatchUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * JobJournalBatchApi - object-oriented interface
 * @export
 * @class JobJournalBatchApi
 * @extends {BaseAPI}
 */
export class JobJournalBatchApi extends BaseAPI {
    /**
     * 
     * @param {JobJournalBatchDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchCreatePost(body?: JobJournalBatchDto, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobJournalBatchDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchDeleteDelete(body?: JobJournalBatchDto, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id?: string, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchGetAllByIdGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [providerName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchGetAllGet(providerName?: string, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchGetAllGet(providerName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [providerName] 
     * @param {number} [serviceConnectionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchGetByIdGet(id?: string, providerName?: string, serviceConnectionId?: number, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchGetByIdGet(id, providerName, serviceConnectionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobJournalBatchDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchGetGet(body?: JobJournalBatchDto, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchGetGet(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobJournalBatchDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobJournalBatchApi
     */
    public apiServicesBcDeluxJobJournalBatchUpdatePut(body?: JobJournalBatchDto, options?: any) {
        return JobJournalBatchApiFp(this.configuration).apiServicesBcDeluxJobJournalBatchUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobTaskApi - fetch parameter creator
 * @export
 */
export const JobTaskApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskCreatePost(body?: JobTaskDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskDeleteDelete(body?: JobTaskDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/GetAllByJob`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetGet(body?: JobTaskDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskUpdatePut(body?: JobTaskDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/JobTask/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobTaskApi - functional programming interface
 * @export
 */
export const JobTaskApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskCreatePost(body?: JobTaskDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskDto> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskDeleteDelete(body?: JobTaskDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTaskDto>> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTaskDto>> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskGetAllByJobGet(jobId, serviceProviderName, serviceConnectionId, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTaskDto>> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskDto> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetGet(body?: JobTaskDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskDto> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskUpdatePut(body?: JobTaskDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskDto> {
            const localVarFetchArgs = JobTaskApiFetchParamCreator(configuration).apiServicesAppJobTaskUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobTaskApi - factory interface
 * @export
 */
export const JobTaskApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskCreatePost(body?: JobTaskDto, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskDeleteDelete(body?: JobTaskDto, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskGetAllByJobGet(jobId, serviceProviderName, serviceConnectionId, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskGetGet(body?: JobTaskDto, includeFields?: Array<string>, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTaskDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppJobTaskUpdatePut(body?: JobTaskDto, options?: any) {
            return JobTaskApiFp(configuration).apiServicesAppJobTaskUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * JobTaskApi - object-oriented interface
 * @export
 * @class JobTaskApi
 * @extends {BaseAPI}
 */
export class JobTaskApi extends BaseAPI {
    /**
     * 
     * @param {JobTaskDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskCreatePost(body?: JobTaskDto, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTaskDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskDeleteDelete(body?: JobTaskDto, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [jobId] 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskGetAllByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskGetAllByJobGet(jobId, serviceProviderName, serviceConnectionId, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTaskDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskGetGet(body?: JobTaskDto, includeFields?: Array<string>, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTaskDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskApi
     */
    public apiServicesAppJobTaskUpdatePut(body?: JobTaskDto, options?: any) {
        return JobTaskApiFp(this.configuration).apiServicesAppJobTaskUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobTaskTransferLineApi - fetch parameter creator
 * @export
 */
export const JobTaskTransferLineApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body?: JobTaskTransferLineDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskTransferLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body?: JobTaskTransferLineDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskTransferLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body?: JobTaskTransferLineDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskTransferLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body?: JobTaskTransferLineDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTaskTransferLine/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTaskTransferLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobTaskTransferLineApi - functional programming interface
 * @export
 */
export const JobTaskTransferLineApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body?: JobTaskTransferLineDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskTransferLineDto> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body?: JobTaskTransferLineDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTaskTransferLineDto>> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTaskTransferLineDto>> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskTransferLineDto> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body?: JobTaskTransferLineDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskTransferLineDto> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body?: JobTaskTransferLineDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTaskTransferLineDto> {
            const localVarFetchArgs = JobTaskTransferLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobTaskTransferLineApi - factory interface
 * @export
 */
export const JobTaskTransferLineApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body?: JobTaskTransferLineDto, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body?: JobTaskTransferLineDto, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body?: JobTaskTransferLineDto, includeFields?: Array<string>, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTaskTransferLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body?: JobTaskTransferLineDto, options?: any) {
            return JobTaskTransferLineApiFp(configuration).apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * JobTaskTransferLineApi - object-oriented interface
 * @export
 * @class JobTaskTransferLineApi
 * @extends {BaseAPI}
 */
export class JobTaskTransferLineApi extends BaseAPI {
    /**
     * 
     * @param {JobTaskTransferLineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body?: JobTaskTransferLineDto, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTaskTransferLineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body?: JobTaskTransferLineDto, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTaskTransferLineDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body?: JobTaskTransferLineDto, includeFields?: Array<string>, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTaskTransferLineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTaskTransferLineApi
     */
    public apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body?: JobTaskTransferLineDto, options?: any) {
        return JobTaskTransferLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTaskTransferLineUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobTypeApi - fetch parameter creator
 * @export
 */
export const JobTypeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeCreatePost(body?: JobTypeDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeDeleteDelete(body?: JobTypeDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/FindByJob`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetGet(body?: JobTypeDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeUpdatePut(body?: JobTypeDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobType/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobTypeApi - functional programming interface
 * @export
 */
export const JobTypeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeCreatePost(body?: JobTypeDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeDto> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeDeleteDelete(body?: JobTypeDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypeDto>> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId, serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypeDto>> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypeDto>> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeDto> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetGet(body?: JobTypeDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeDto> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeUpdatePut(body?: JobTypeDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeDto> {
            const localVarFetchArgs = JobTypeApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobTypeApi - factory interface
 * @export
 */
export const JobTypeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeCreatePost(body?: JobTypeDto, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeDeleteDelete(body?: JobTypeDto, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId, serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeGetGet(body?: JobTypeDto, includeFields?: Array<string>, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeUpdatePut(body?: JobTypeDto, options?: any) {
            return JobTypeApiFp(configuration).apiServicesModulesSdtPlmJobTypeUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * JobTypeApi - object-oriented interface
 * @export
 * @class JobTypeApi
 * @extends {BaseAPI}
 */
export class JobTypeApi extends BaseAPI {
    /**
     * 
     * @param {JobTypeDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeCreatePost(body?: JobTypeDto, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypeDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeDeleteDelete(body?: JobTypeDto, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [jobId] 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId?: string, serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeFindByJobGet(jobId, serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypeDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeGetGet(body?: JobTypeDto, includeFields?: Array<string>, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypeDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeApi
     */
    public apiServicesModulesSdtPlmJobTypeUpdatePut(body?: JobTypeDto, options?: any) {
        return JobTypeApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobTypeLineApi - fetch parameter creator
 * @export
 */
export const JobTypeLineApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineCreatePost(body?: JobTypeLineDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body?: JobTypeLineDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetGet(body?: JobTypeLineDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineUpdatePut(body?: JobTypeLineDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/JobTypeLine/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypeLineDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobTypeLineApi - functional programming interface
 * @export
 */
export const JobTypeLineApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineCreatePost(body?: JobTypeLineDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeLineDto> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body?: JobTypeLineDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypeLineDto>> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypeLineDto>> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeLineDto> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetGet(body?: JobTypeLineDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeLineDto> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineUpdatePut(body?: JobTypeLineDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypeLineDto> {
            const localVarFetchArgs = JobTypeLineApiFetchParamCreator(configuration).apiServicesModulesSdtPlmJobTypeLineUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobTypeLineApi - factory interface
 * @export
 */
export const JobTypeLineApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineCreatePost(body?: JobTypeLineDto, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body?: JobTypeLineDto, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineGetGet(body?: JobTypeLineDto, includeFields?: Array<string>, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypeLineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmJobTypeLineUpdatePut(body?: JobTypeLineDto, options?: any) {
            return JobTypeLineApiFp(configuration).apiServicesModulesSdtPlmJobTypeLineUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * JobTypeLineApi - object-oriented interface
 * @export
 * @class JobTypeLineApi
 * @extends {BaseAPI}
 */
export class JobTypeLineApi extends BaseAPI {
    /**
     * 
     * @param {JobTypeLineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineCreatePost(body?: JobTypeLineDto, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypeLineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body?: JobTypeLineDto, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypeLineDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineGetGet(body?: JobTypeLineDto, includeFields?: Array<string>, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypeLineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobTypeLineApi
     */
    public apiServicesModulesSdtPlmJobTypeLineUpdatePut(body?: JobTypeLineDto, options?: any) {
        return JobTypeLineApiFp(this.configuration).apiServicesModulesSdtPlmJobTypeLineUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PredictionServiceConfigurationApi - fetch parameter creator
 * @export
 */
export const PredictionServiceConfigurationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PredictionServiceConfigurationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationCreatePost(body?: PredictionServiceConfigurationDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/PredictionServiceConfiguration/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PredictionServiceConfigurationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/PredictionServiceConfiguration/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/PredictionServiceConfiguration/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/PredictionServiceConfiguration/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PredictionServiceConfigurationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationUpdatePut(body?: PredictionServiceConfigurationDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/PredictionServiceConfiguration/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PredictionServiceConfigurationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictionServiceConfigurationApi - functional programming interface
 * @export
 */
export const PredictionServiceConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PredictionServiceConfigurationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationCreatePost(body?: PredictionServiceConfigurationDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PredictionServiceConfigurationDto> {
            const localVarFetchArgs = PredictionServiceConfigurationApiFetchParamCreator(configuration).apiServicesAppPredictionServiceConfigurationCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PredictionServiceConfigurationApiFetchParamCreator(configuration).apiServicesAppPredictionServiceConfigurationDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PredictionServiceConfigurationDtoPagedResultDto> {
            const localVarFetchArgs = PredictionServiceConfigurationApiFetchParamCreator(configuration).apiServicesAppPredictionServiceConfigurationGetAllGet(sorting, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PredictionServiceConfigurationDto> {
            const localVarFetchArgs = PredictionServiceConfigurationApiFetchParamCreator(configuration).apiServicesAppPredictionServiceConfigurationGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PredictionServiceConfigurationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationUpdatePut(body?: PredictionServiceConfigurationDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PredictionServiceConfigurationDto> {
            const localVarFetchArgs = PredictionServiceConfigurationApiFetchParamCreator(configuration).apiServicesAppPredictionServiceConfigurationUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PredictionServiceConfigurationApi - factory interface
 * @export
 */
export const PredictionServiceConfigurationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PredictionServiceConfigurationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationCreatePost(body?: PredictionServiceConfigurationDto, options?: any) {
            return PredictionServiceConfigurationApiFp(configuration).apiServicesAppPredictionServiceConfigurationCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationDeleteDelete(id?: number, options?: any) {
            return PredictionServiceConfigurationApiFp(configuration).apiServicesAppPredictionServiceConfigurationDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return PredictionServiceConfigurationApiFp(configuration).apiServicesAppPredictionServiceConfigurationGetAllGet(sorting, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationGetGet(id?: number, options?: any) {
            return PredictionServiceConfigurationApiFp(configuration).apiServicesAppPredictionServiceConfigurationGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PredictionServiceConfigurationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServiceConfigurationUpdatePut(body?: PredictionServiceConfigurationDto, options?: any) {
            return PredictionServiceConfigurationApiFp(configuration).apiServicesAppPredictionServiceConfigurationUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * PredictionServiceConfigurationApi - object-oriented interface
 * @export
 * @class PredictionServiceConfigurationApi
 * @extends {BaseAPI}
 */
export class PredictionServiceConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {PredictionServiceConfigurationDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceConfigurationApi
     */
    public apiServicesAppPredictionServiceConfigurationCreatePost(body?: PredictionServiceConfigurationDto, options?: any) {
        return PredictionServiceConfigurationApiFp(this.configuration).apiServicesAppPredictionServiceConfigurationCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceConfigurationApi
     */
    public apiServicesAppPredictionServiceConfigurationDeleteDelete(id?: number, options?: any) {
        return PredictionServiceConfigurationApiFp(this.configuration).apiServicesAppPredictionServiceConfigurationDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceConfigurationApi
     */
    public apiServicesAppPredictionServiceConfigurationGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return PredictionServiceConfigurationApiFp(this.configuration).apiServicesAppPredictionServiceConfigurationGetAllGet(sorting, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceConfigurationApi
     */
    public apiServicesAppPredictionServiceConfigurationGetGet(id?: number, options?: any) {
        return PredictionServiceConfigurationApiFp(this.configuration).apiServicesAppPredictionServiceConfigurationGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PredictionServiceConfigurationDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceConfigurationApi
     */
    public apiServicesAppPredictionServiceConfigurationUpdatePut(body?: PredictionServiceConfigurationDto, options?: any) {
        return PredictionServiceConfigurationApiFp(this.configuration).apiServicesAppPredictionServiceConfigurationUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PredictionServicesApi - fetch parameter creator
 * @export
 */
export const PredictionServicesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [predictionServiceName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/PredictionServices/GetPrediction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (predictionServiceName !== undefined) {
                localVarQueryParameter['predictionServiceName'] = predictionServiceName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictionServicesApi - functional programming interface
 * @export
 */
export const PredictionServicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [predictionServiceName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GroupBasedPredictionResultDto>> {
            const localVarFetchArgs = PredictionServicesApiFetchParamCreator(configuration).apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PredictionServicesApi - factory interface
 * @export
 */
export const PredictionServicesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [predictionServiceName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName?: string, options?: any) {
            return PredictionServicesApiFp(configuration).apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName, options)(fetch, basePath);
        },
    };
};

/**
 * PredictionServicesApi - object-oriented interface
 * @export
 * @class PredictionServicesApi
 * @extends {BaseAPI}
 */
export class PredictionServicesApi extends BaseAPI {
    /**
     * 
     * @param {string} [predictionServiceName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServicesApi
     */
    public apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName?: string, options?: any) {
        return PredictionServicesApiFp(this.configuration).apiServicesAppPredictionServicesGetPredictionGet(predictionServiceName, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportOptionsApi - fetch parameter creator
 * @export
 */
export const ReportOptionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsCreatePost(body?: ReportOptionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportOptions/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportOptionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportOptions/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportOptions/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportOptions/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsUpdatePut(body?: ReportOptionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportOptions/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportOptionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportOptionsApi - functional programming interface
 * @export
 */
export const ReportOptionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsCreatePost(body?: ReportOptionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportOptionDto> {
            const localVarFetchArgs = ReportOptionsApiFetchParamCreator(configuration).apiServicesAppReportOptionsCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReportOptionsApiFetchParamCreator(configuration).apiServicesAppReportOptionsDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportOptionDtoPagedResultDto> {
            const localVarFetchArgs = ReportOptionsApiFetchParamCreator(configuration).apiServicesAppReportOptionsGetAllGet(sorting, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportOptionDto> {
            const localVarFetchArgs = ReportOptionsApiFetchParamCreator(configuration).apiServicesAppReportOptionsGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsUpdatePut(body?: ReportOptionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportOptionDto> {
            const localVarFetchArgs = ReportOptionsApiFetchParamCreator(configuration).apiServicesAppReportOptionsUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportOptionsApi - factory interface
 * @export
 */
export const ReportOptionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ReportOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsCreatePost(body?: ReportOptionDto, options?: any) {
            return ReportOptionsApiFp(configuration).apiServicesAppReportOptionsCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsDeleteDelete(id?: number, options?: any) {
            return ReportOptionsApiFp(configuration).apiServicesAppReportOptionsDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return ReportOptionsApiFp(configuration).apiServicesAppReportOptionsGetAllGet(sorting, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsGetGet(id?: number, options?: any) {
            return ReportOptionsApiFp(configuration).apiServicesAppReportOptionsGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportOptionsUpdatePut(body?: ReportOptionDto, options?: any) {
            return ReportOptionsApiFp(configuration).apiServicesAppReportOptionsUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportOptionsApi - object-oriented interface
 * @export
 * @class ReportOptionsApi
 * @extends {BaseAPI}
 */
export class ReportOptionsApi extends BaseAPI {
    /**
     * 
     * @param {ReportOptionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportOptionsApi
     */
    public apiServicesAppReportOptionsCreatePost(body?: ReportOptionDto, options?: any) {
        return ReportOptionsApiFp(this.configuration).apiServicesAppReportOptionsCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportOptionsApi
     */
    public apiServicesAppReportOptionsDeleteDelete(id?: number, options?: any) {
        return ReportOptionsApiFp(this.configuration).apiServicesAppReportOptionsDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportOptionsApi
     */
    public apiServicesAppReportOptionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return ReportOptionsApiFp(this.configuration).apiServicesAppReportOptionsGetAllGet(sorting, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportOptionsApi
     */
    public apiServicesAppReportOptionsGetGet(id?: number, options?: any) {
        return ReportOptionsApiFp(this.configuration).apiServicesAppReportOptionsGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportOptionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportOptionsApi
     */
    public apiServicesAppReportOptionsUpdatePut(body?: ReportOptionDto, options?: any) {
        return ReportOptionsApiFp(this.configuration).apiServicesAppReportOptionsUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportTemplatesApi - fetch parameter creator
 * @export
 */
export const ReportTemplatesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportTemplateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesCreatePost(body?: ReportTemplateDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportTemplates/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportTemplateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportTemplates/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportTemplates/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportTemplates/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [reportTemplateId] 
         * @param {number} [reportId] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId?: number, reportId?: number, serviceConnectionId?: number, serviceProviderName?: string, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, fromDate?: Date, toDate?: Date, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportTemplates/GetReportData`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reportTemplateId !== undefined) {
                localVarQueryParameter['ReportTemplateId'] = reportTemplateId;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['ReportId'] = reportId;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['ServiceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['ServiceProviderName'] = serviceProviderName;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['InternalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['ExternalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['IncludeFields'] = includeFields;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any).toISOString();
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportTemplateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesUpdatePut(body?: ReportTemplateDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ReportTemplates/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportTemplateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportTemplatesApi - functional programming interface
 * @export
 */
export const ReportTemplatesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportTemplateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesCreatePost(body?: ReportTemplateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportTemplateDto> {
            const localVarFetchArgs = ReportTemplatesApiFetchParamCreator(configuration).apiServicesAppReportTemplatesCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReportTemplatesApiFetchParamCreator(configuration).apiServicesAppReportTemplatesDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReportTemplateDto>> {
            const localVarFetchArgs = ReportTemplatesApiFetchParamCreator(configuration).apiServicesAppReportTemplatesGetAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportTemplateDto> {
            const localVarFetchArgs = ReportTemplatesApiFetchParamCreator(configuration).apiServicesAppReportTemplatesGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [reportTemplateId] 
         * @param {number} [reportId] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId?: number, reportId?: number, serviceConnectionId?: number, serviceProviderName?: string, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, fromDate?: Date, toDate?: Date, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ModelObject>> {
            const localVarFetchArgs = ReportTemplatesApiFetchParamCreator(configuration).apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId, reportId, serviceConnectionId, serviceProviderName, internalFilters, externalFilters, includeFields, fromDate, toDate, filter, sorting, maxResultCount, skipCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportTemplateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesUpdatePut(body?: ReportTemplateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportTemplateDto> {
            const localVarFetchArgs = ReportTemplatesApiFetchParamCreator(configuration).apiServicesAppReportTemplatesUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportTemplatesApi - factory interface
 * @export
 */
export const ReportTemplatesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ReportTemplateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesCreatePost(body?: ReportTemplateDto, options?: any) {
            return ReportTemplatesApiFp(configuration).apiServicesAppReportTemplatesCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesDeleteDelete(id?: number, options?: any) {
            return ReportTemplatesApiFp(configuration).apiServicesAppReportTemplatesDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetAllGet(options?: any) {
            return ReportTemplatesApiFp(configuration).apiServicesAppReportTemplatesGetAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetGet(id?: number, options?: any) {
            return ReportTemplatesApiFp(configuration).apiServicesAppReportTemplatesGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [reportTemplateId] 
         * @param {number} [reportId] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId?: number, reportId?: number, serviceConnectionId?: number, serviceProviderName?: string, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, fromDate?: Date, toDate?: Date, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
            return ReportTemplatesApiFp(configuration).apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId, reportId, serviceConnectionId, serviceProviderName, internalFilters, externalFilters, includeFields, fromDate, toDate, filter, sorting, maxResultCount, skipCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportTemplateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportTemplatesUpdatePut(body?: ReportTemplateDto, options?: any) {
            return ReportTemplatesApiFp(configuration).apiServicesAppReportTemplatesUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportTemplatesApi - object-oriented interface
 * @export
 * @class ReportTemplatesApi
 * @extends {BaseAPI}
 */
export class ReportTemplatesApi extends BaseAPI {
    /**
     * 
     * @param {ReportTemplateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplatesApi
     */
    public apiServicesAppReportTemplatesCreatePost(body?: ReportTemplateDto, options?: any) {
        return ReportTemplatesApiFp(this.configuration).apiServicesAppReportTemplatesCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplatesApi
     */
    public apiServicesAppReportTemplatesDeleteDelete(id?: number, options?: any) {
        return ReportTemplatesApiFp(this.configuration).apiServicesAppReportTemplatesDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplatesApi
     */
    public apiServicesAppReportTemplatesGetAllGet(options?: any) {
        return ReportTemplatesApiFp(this.configuration).apiServicesAppReportTemplatesGetAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplatesApi
     */
    public apiServicesAppReportTemplatesGetGet(id?: number, options?: any) {
        return ReportTemplatesApiFp(this.configuration).apiServicesAppReportTemplatesGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [reportTemplateId] 
     * @param {number} [reportId] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {string} [filter] 
     * @param {string} [sorting] 
     * @param {number} [maxResultCount] 
     * @param {number} [skipCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplatesApi
     */
    public apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId?: number, reportId?: number, serviceConnectionId?: number, serviceProviderName?: string, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, fromDate?: Date, toDate?: Date, filter?: string, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
        return ReportTemplatesApiFp(this.configuration).apiServicesAppReportTemplatesGetReportDataGet(reportTemplateId, reportId, serviceConnectionId, serviceProviderName, internalFilters, externalFilters, includeFields, fromDate, toDate, filter, sorting, maxResultCount, skipCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportTemplateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplatesApi
     */
    public apiServicesAppReportTemplatesUpdatePut(body?: ReportTemplateDto, options?: any) {
        return ReportTemplatesApiFp(this.configuration).apiServicesAppReportTemplatesUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportsApi - fetch parameter creator
 * @export
 */
export const ReportsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateReportInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsCreatePost(body?: CreateReportInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Reports/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateReportInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsDeleteDelete(reportId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Reports/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filters] 
         * @param {string} [entityFilters] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsGetAllGet(filters?: string, entityFilters?: string, skip?: number, take?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Reports/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (entityFilters !== undefined) {
                localVarQueryParameter['entityFilters'] = entityFilters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Reports/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportDataEntryDto} [body] 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsUpdatePut(body?: ReportDataEntryDto, reportId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Reports/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportDataEntryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateReportInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsCreatePost(body?: CreateReportInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = ReportsApiFetchParamCreator(configuration).apiServicesAppReportsCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsDeleteDelete(reportId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReportsApiFetchParamCreator(configuration).apiServicesAppReportsDeleteDelete(reportId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filters] 
         * @param {string} [entityFilters] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsGetAllGet(filters?: string, entityFilters?: string, skip?: number, take?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportDtoPagedResultDto> {
            const localVarFetchArgs = ReportsApiFetchParamCreator(configuration).apiServicesAppReportsGetAllGet(filters, entityFilters, skip, take, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReportDto> {
            const localVarFetchArgs = ReportsApiFetchParamCreator(configuration).apiServicesAppReportsGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ReportDataEntryDto} [body] 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsUpdatePut(body?: ReportDataEntryDto, reportId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = ReportsApiFetchParamCreator(configuration).apiServicesAppReportsUpdatePut(body, reportId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateReportInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsCreatePost(body?: CreateReportInput, options?: any) {
            return ReportsApiFp(configuration).apiServicesAppReportsCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsDeleteDelete(reportId?: number, options?: any) {
            return ReportsApiFp(configuration).apiServicesAppReportsDeleteDelete(reportId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [filters] 
         * @param {string} [entityFilters] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsGetAllGet(filters?: string, entityFilters?: string, skip?: number, take?: number, options?: any) {
            return ReportsApiFp(configuration).apiServicesAppReportsGetAllGet(filters, entityFilters, skip, take, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsGetGet(id?: number, options?: any) {
            return ReportsApiFp(configuration).apiServicesAppReportsGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ReportDataEntryDto} [body] 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppReportsUpdatePut(body?: ReportDataEntryDto, reportId?: number, options?: any) {
            return ReportsApiFp(configuration).apiServicesAppReportsUpdatePut(body, reportId, options)(fetch, basePath);
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {CreateReportInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiServicesAppReportsCreatePost(body?: CreateReportInput, options?: any) {
        return ReportsApiFp(this.configuration).apiServicesAppReportsCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [reportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiServicesAppReportsDeleteDelete(reportId?: number, options?: any) {
        return ReportsApiFp(this.configuration).apiServicesAppReportsDeleteDelete(reportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [filters] 
     * @param {string} [entityFilters] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiServicesAppReportsGetAllGet(filters?: string, entityFilters?: string, skip?: number, take?: number, options?: any) {
        return ReportsApiFp(this.configuration).apiServicesAppReportsGetAllGet(filters, entityFilters, skip, take, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiServicesAppReportsGetGet(id?: number, options?: any) {
        return ReportsApiFp(this.configuration).apiServicesAppReportsGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ReportDataEntryDto} [body] 
     * @param {number} [reportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiServicesAppReportsUpdatePut(body?: ReportDataEntryDto, reportId?: number, options?: any) {
        return ReportsApiFp(this.configuration).apiServicesAppReportsUpdatePut(body, reportId, options)(this.fetch, this.basePath);
    }

}
/**
 * ResourceApi - fetch parameter creator
 * @export
 */
export const ResourceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceCreatePost(body?: ResourceDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResourceDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceDeleteDelete(body?: ResourceDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResourceDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (serviceProviderName !== undefined) {
                localVarQueryParameter['serviceProviderName'] = serviceProviderName;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetGet(body?: ResourceDto, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResourceDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceUpdatePut(body?: ResourceDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Resource/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResourceDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceApi - functional programming interface
 * @export
 */
export const ResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceCreatePost(body?: ResourceDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResourceDto> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceDeleteDelete(body?: ResourceDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ResourceDto>> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceGetAllByIdGet(id, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ResourceDto>> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResourceDto> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetGet(body?: ResourceDto, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResourceDto> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceGetGet(body, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceUpdatePut(body?: ResourceDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResourceDto> {
            const localVarFetchArgs = ResourceApiFetchParamCreator(configuration).apiServicesAppResourceUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ResourceApi - factory interface
 * @export
 */
export const ResourceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceCreatePost(body?: ResourceDto, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceDeleteDelete(body?: ResourceDto, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceGetAllByIdGet(id, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [serviceProviderName] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {string} [serviceProviderName] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceGetGet(body?: ResourceDto, includeFields?: Array<string>, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceGetGet(body, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResourceDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppResourceUpdatePut(body?: ResourceDto, options?: any) {
            return ResourceApiFp(configuration).apiServicesAppResourceUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * ResourceApi - object-oriented interface
 * @export
 * @class ResourceApi
 * @extends {BaseAPI}
 */
export class ResourceApi extends BaseAPI {
    /**
     * 
     * @param {ResourceDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceCreatePost(body?: ResourceDto, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResourceDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceDeleteDelete(body?: ResourceDto, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceGetAllByIdGet(id?: string, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceGetAllByIdGet(id, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [serviceProviderName] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceGetAllGet(serviceProviderName?: string, serviceConnectionId?: number, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceGetAllGet(serviceProviderName, serviceConnectionId, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {string} [serviceProviderName] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceGetByIdGet(id?: string, serviceConnectionId?: number, serviceProviderName?: string, includeFields?: Array<string>, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceGetByIdGet(id, serviceConnectionId, serviceProviderName, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResourceDto} [body] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceGetGet(body?: ResourceDto, includeFields?: Array<string>, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceGetGet(body, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResourceDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public apiServicesAppResourceUpdatePut(body?: ResourceDto, options?: any) {
        return ResourceApiFp(this.configuration).apiServicesAppResourceUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * RoleApi - fetch parameter creator
 * @export
 */
export const RoleApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleCreatePost(body?: CreateRoleDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateRoleDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetAllGet(keyword?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['Keyword'] = keyword;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetAllPermissionsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/GetAllPermissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetRoleForEditGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/GetRoleForEdit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [permission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetRolesGet(permission?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/GetRoles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (permission !== undefined) {
                localVarQueryParameter['Permission'] = permission;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoleDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleUpdatePut(body?: RoleDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Role/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RoleDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleCreatePost(body?: CreateRoleDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDto> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetAllGet(keyword?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDtoPagedResultDto> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleGetAllGet(keyword, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetAllPermissionsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PermissionDtoListResultDto> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleGetAllPermissionsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDto> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetRoleForEditGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetRoleForEditOutput> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleGetRoleForEditGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [permission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetRolesGet(permission?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleListDtoListResultDto> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleGetRolesGet(permission, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RoleDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleUpdatePut(body?: RoleDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDto> {
            const localVarFetchArgs = RoleApiFetchParamCreator(configuration).apiServicesAppRoleUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateRoleDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleCreatePost(body?: CreateRoleDto, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleDeleteDelete(id?: number, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetAllGet(keyword?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleGetAllGet(keyword, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetAllPermissionsGet(options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleGetAllPermissionsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetGet(id?: number, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetRoleForEditGet(id?: number, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleGetRoleForEditGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [permission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleGetRolesGet(permission?: string, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleGetRolesGet(permission, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RoleDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppRoleUpdatePut(body?: RoleDto, options?: any) {
            return RoleApiFp(configuration).apiServicesAppRoleUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoleDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleCreatePost(body?: CreateRoleDto, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleDeleteDelete(id?: number, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [keyword] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleGetAllGet(keyword?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleGetAllGet(keyword, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleGetAllPermissionsGet(options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleGetAllPermissionsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleGetGet(id?: number, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleGetRoleForEditGet(id?: number, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleGetRoleForEditGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [permission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleGetRolesGet(permission?: string, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleGetRolesGet(permission, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RoleDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiServicesAppRoleUpdatePut(body?: RoleDto, options?: any) {
        return RoleApiFp(this.configuration).apiServicesAppRoleUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SdtJobAttachmentApi - fetch parameter creator
 * @export
 */
export const SdtJobAttachmentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachFileToJobInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body?: AttachFileToJobInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/SdtJobAttachment/AttachFileToJob`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AttachFileToJobInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SdtJobAttachmentApi - functional programming interface
 * @export
 */
export const SdtJobAttachmentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachFileToJobInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body?: AttachFileToJobInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SdtJobAttachmentApiFetchParamCreator(configuration).apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SdtJobAttachmentApi - factory interface
 * @export
 */
export const SdtJobAttachmentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AttachFileToJobInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body?: AttachFileToJobInput, options?: any) {
            return SdtJobAttachmentApiFp(configuration).apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * SdtJobAttachmentApi - object-oriented interface
 * @export
 * @class SdtJobAttachmentApi
 * @extends {BaseAPI}
 */
export class SdtJobAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {AttachFileToJobInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdtJobAttachmentApi
     */
    public apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body?: AttachFileToJobInput, options?: any) {
        return SdtJobAttachmentApiFp(this.configuration).apiServicesModulesSdtPlmSdtJobAttachmentAttachFileToJobPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SdtPlmDataExportApi - fetch parameter creator
 * @export
 */
export const SdtPlmDataExportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [localServiceConnectionId] 
         * @param {number} [remoteServiceConnectionId] 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId?: number, remoteServiceConnectionId?: number, jobId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/SdtPlmDataExport/ExportJob`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (localServiceConnectionId !== undefined) {
                localVarQueryParameter['localServiceConnectionId'] = localServiceConnectionId;
            }

            if (remoteServiceConnectionId !== undefined) {
                localVarQueryParameter['remoteServiceConnectionId'] = remoteServiceConnectionId;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/SdtPlmDataExport/GetJobExchangeState`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SdtPlmDataExportApi - functional programming interface
 * @export
 */
export const SdtPlmDataExportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [localServiceConnectionId] 
         * @param {number} [remoteServiceConnectionId] 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId?: number, remoteServiceConnectionId?: number, jobId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = SdtPlmDataExportApiFetchParamCreator(configuration).apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId, remoteServiceConnectionId, jobId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PlmDataExchangeStateDtoPagedResultDto> {
            const localVarFetchArgs = SdtPlmDataExportApiFetchParamCreator(configuration).apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SdtPlmDataExportApi - factory interface
 * @export
 */
export const SdtPlmDataExportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [localServiceConnectionId] 
         * @param {number} [remoteServiceConnectionId] 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId?: number, remoteServiceConnectionId?: number, jobId?: string, options?: any) {
            return SdtPlmDataExportApiFp(configuration).apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId, remoteServiceConnectionId, jobId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take?: number, options?: any) {
            return SdtPlmDataExportApiFp(configuration).apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take, options)(fetch, basePath);
        },
    };
};

/**
 * SdtPlmDataExportApi - object-oriented interface
 * @export
 * @class SdtPlmDataExportApi
 * @extends {BaseAPI}
 */
export class SdtPlmDataExportApi extends BaseAPI {
    /**
     * 
     * @param {number} [localServiceConnectionId] 
     * @param {number} [remoteServiceConnectionId] 
     * @param {string} [jobId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdtPlmDataExportApi
     */
    public apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId?: number, remoteServiceConnectionId?: number, jobId?: string, options?: any) {
        return SdtPlmDataExportApiFp(this.configuration).apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(localServiceConnectionId, remoteServiceConnectionId, jobId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdtPlmDataExportApi
     */
    public apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take?: number, options?: any) {
        return SdtPlmDataExportApiFp(this.configuration).apiServicesModulesSdtPlmSdtPlmDataExportGetJobExchangeStateGet(take, options)(this.fetch, this.basePath);
    }

}
/**
 * SdtProxyApi - fetch parameter creator
 * @export
 */
export const SdtProxyApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/SdtProxy/ProxyRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SdtProxyApi - functional programming interface
 * @export
 */
export const SdtProxyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SdtProxyApiFetchParamCreator(configuration).apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SdtProxyApi - factory interface
 * @export
 */
export const SdtProxyApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url?: string, options?: any) {
            return SdtProxyApiFp(configuration).apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url, options)(fetch, basePath);
        },
    };
};

/**
 * SdtProxyApi - object-oriented interface
 * @export
 * @class SdtProxyApi
 * @extends {BaseAPI}
 */
export class SdtProxyApi extends BaseAPI {
    /**
     * 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdtProxyApi
     */
    public apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url?: string, options?: any) {
        return SdtProxyApiFp(this.configuration).apiServicesModulesSdtPlmSdtProxyProxyRequestGet(url, options)(this.fetch, this.basePath);
    }

}
/**
 * SdtTemplateApi - fetch parameter creator
 * @export
 */
export const SdtTemplateApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFromTemplateInputDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body?: CreateFromTemplateInputDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/SdtTemplate/CreateFromTemplate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateFromTemplateInputDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/sdt-plm/SdtTemplate/GetDistinctTemplateFields`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SdtTemplateApi - functional programming interface
 * @export
 */
export const SdtTemplateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFromTemplateInputDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body?: CreateFromTemplateInputDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SdtTemplateApiFetchParamCreator(configuration).apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TemplateData>> {
            const localVarFetchArgs = SdtTemplateApiFetchParamCreator(configuration).apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SdtTemplateApi - factory interface
 * @export
 */
export const SdtTemplateApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateFromTemplateInputDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body?: CreateFromTemplateInputDto, options?: any) {
            return SdtTemplateApiFp(configuration).apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId?: string, options?: any) {
            return SdtTemplateApiFp(configuration).apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId, options)(fetch, basePath);
        },
    };
};

/**
 * SdtTemplateApi - object-oriented interface
 * @export
 * @class SdtTemplateApi
 * @extends {BaseAPI}
 */
export class SdtTemplateApi extends BaseAPI {
    /**
     * 
     * @param {CreateFromTemplateInputDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdtTemplateApi
     */
    public apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body?: CreateFromTemplateInputDto, options?: any) {
        return SdtTemplateApiFp(this.configuration).apiServicesModulesSdtPlmSdtTemplateCreateFromTemplatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [jobId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdtTemplateApi
     */
    public apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId?: string, options?: any) {
        return SdtTemplateApiFp(this.configuration).apiServicesModulesSdtPlmSdtTemplateGetDistinctTemplateFieldsGet(jobId, options)(this.fetch, this.basePath);
    }

}
/**
 * ServiceActionApi - fetch parameter creator
 * @export
 */
export const ServiceActionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ModelObject} [body] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceActionExecuteActionPost(body?: ModelObject, name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceAction/ExecuteAction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceActionGetAllGet(providerName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceAction/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceActionApi - functional programming interface
 * @export
 */
export const ServiceActionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ModelObject} [body] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceActionExecuteActionPost(body?: ModelObject, name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = ServiceActionApiFetchParamCreator(configuration).apiServicesAppServiceActionExecuteActionPost(body, name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceActionGetAllGet(providerName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GetAllActionsItemDto>> {
            const localVarFetchArgs = ServiceActionApiFetchParamCreator(configuration).apiServicesAppServiceActionGetAllGet(providerName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ServiceActionApi - factory interface
 * @export
 */
export const ServiceActionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ModelObject} [body] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceActionExecuteActionPost(body?: ModelObject, name?: string, options?: any) {
            return ServiceActionApiFp(configuration).apiServicesAppServiceActionExecuteActionPost(body, name, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceActionGetAllGet(providerName?: string, options?: any) {
            return ServiceActionApiFp(configuration).apiServicesAppServiceActionGetAllGet(providerName, options)(fetch, basePath);
        },
    };
};

/**
 * ServiceActionApi - object-oriented interface
 * @export
 * @class ServiceActionApi
 * @extends {BaseAPI}
 */
export class ServiceActionApi extends BaseAPI {
    /**
     * 
     * @param {ModelObject} [body] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceActionApi
     */
    public apiServicesAppServiceActionExecuteActionPost(body?: ModelObject, name?: string, options?: any) {
        return ServiceActionApiFp(this.configuration).apiServicesAppServiceActionExecuteActionPost(body, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [providerName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceActionApi
     */
    public apiServicesAppServiceActionGetAllGet(providerName?: string, options?: any) {
        return ServiceActionApiFp(this.configuration).apiServicesAppServiceActionGetAllGet(providerName, options)(this.fetch, this.basePath);
    }

}
/**
 * ServiceConnectionApi - fetch parameter creator
 * @export
 */
export const ServiceConnectionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ServiceConnectionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionCreatePost(body?: ServiceConnectionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceConnection/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceConnectionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceConnection/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceConnection/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceConnection/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceConnectionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionUpdatePut(body?: ServiceConnectionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceConnection/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceConnectionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceConnectionApi - functional programming interface
 * @export
 */
export const ServiceConnectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ServiceConnectionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionCreatePost(body?: ServiceConnectionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceConnectionDto> {
            const localVarFetchArgs = ServiceConnectionApiFetchParamCreator(configuration).apiServicesAppServiceConnectionCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ServiceConnectionApiFetchParamCreator(configuration).apiServicesAppServiceConnectionDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceConnectionDtoPagedResultDto> {
            const localVarFetchArgs = ServiceConnectionApiFetchParamCreator(configuration).apiServicesAppServiceConnectionGetAllGet(sorting, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceConnectionDto> {
            const localVarFetchArgs = ServiceConnectionApiFetchParamCreator(configuration).apiServicesAppServiceConnectionGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceConnectionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionUpdatePut(body?: ServiceConnectionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceConnectionDto> {
            const localVarFetchArgs = ServiceConnectionApiFetchParamCreator(configuration).apiServicesAppServiceConnectionUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ServiceConnectionApi - factory interface
 * @export
 */
export const ServiceConnectionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ServiceConnectionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionCreatePost(body?: ServiceConnectionDto, options?: any) {
            return ServiceConnectionApiFp(configuration).apiServicesAppServiceConnectionCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionDeleteDelete(id?: number, options?: any) {
            return ServiceConnectionApiFp(configuration).apiServicesAppServiceConnectionDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return ServiceConnectionApiFp(configuration).apiServicesAppServiceConnectionGetAllGet(sorting, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionGetGet(id?: number, options?: any) {
            return ServiceConnectionApiFp(configuration).apiServicesAppServiceConnectionGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceConnectionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceConnectionUpdatePut(body?: ServiceConnectionDto, options?: any) {
            return ServiceConnectionApiFp(configuration).apiServicesAppServiceConnectionUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * ServiceConnectionApi - object-oriented interface
 * @export
 * @class ServiceConnectionApi
 * @extends {BaseAPI}
 */
export class ServiceConnectionApi extends BaseAPI {
    /**
     * 
     * @param {ServiceConnectionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceConnectionApi
     */
    public apiServicesAppServiceConnectionCreatePost(body?: ServiceConnectionDto, options?: any) {
        return ServiceConnectionApiFp(this.configuration).apiServicesAppServiceConnectionCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceConnectionApi
     */
    public apiServicesAppServiceConnectionDeleteDelete(id?: number, options?: any) {
        return ServiceConnectionApiFp(this.configuration).apiServicesAppServiceConnectionDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceConnectionApi
     */
    public apiServicesAppServiceConnectionGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return ServiceConnectionApiFp(this.configuration).apiServicesAppServiceConnectionGetAllGet(sorting, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceConnectionApi
     */
    public apiServicesAppServiceConnectionGetGet(id?: number, options?: any) {
        return ServiceConnectionApiFp(this.configuration).apiServicesAppServiceConnectionGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceConnectionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceConnectionApi
     */
    public apiServicesAppServiceConnectionUpdatePut(body?: ServiceConnectionDto, options?: any) {
        return ServiceConnectionApiFp(this.configuration).apiServicesAppServiceConnectionUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ServiceProvidersApi - fetch parameter creator
 * @export
 */
export const ServiceProvidersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceProvidersGetServiceProviderNamesGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceProviders/GetServiceProviderNames`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyPrimary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/ServiceProviders/GetServiceProviders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (onlyPrimary !== undefined) {
                localVarQueryParameter['onlyPrimary'] = onlyPrimary;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceProvidersApi - functional programming interface
 * @export
 */
export const ServiceProvidersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceProvidersGetServiceProviderNamesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).apiServicesAppServiceProvidersGetServiceProviderNamesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {boolean} [onlyPrimary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ServiceProviderDto>> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ServiceProvidersApi - factory interface
 * @export
 */
export const ServiceProvidersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceProvidersGetServiceProviderNamesGet(options?: any) {
            return ServiceProvidersApiFp(configuration).apiServicesAppServiceProvidersGetServiceProviderNamesGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [onlyPrimary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary?: boolean, options?: any) {
            return ServiceProvidersApiFp(configuration).apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary, options)(fetch, basePath);
        },
    };
};

/**
 * ServiceProvidersApi - object-oriented interface
 * @export
 * @class ServiceProvidersApi
 * @extends {BaseAPI}
 */
export class ServiceProvidersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public apiServicesAppServiceProvidersGetServiceProviderNamesGet(options?: any) {
        return ServiceProvidersApiFp(this.configuration).apiServicesAppServiceProvidersGetServiceProviderNamesGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {boolean} [onlyPrimary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary?: boolean, options?: any) {
        return ServiceProvidersApiFp(this.configuration).apiServicesAppServiceProvidersGetServiceProvidersGet(onlyPrimary, options)(this.fetch, this.basePath);
    }

}
/**
 * SessionApi - fetch parameter creator
 * @export
 */
export const SessionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSessionGetCurrentLoginInformationsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Session/GetCurrentLoginInformations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSessionGetCurrentLoginInformationsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetCurrentLoginInformationsOutput> {
            const localVarFetchArgs = SessionApiFetchParamCreator(configuration).apiServicesAppSessionGetCurrentLoginInformationsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSessionGetCurrentLoginInformationsGet(options?: any) {
            return SessionApiFp(configuration).apiServicesAppSessionGetCurrentLoginInformationsGet(options)(fetch, basePath);
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiServicesAppSessionGetCurrentLoginInformationsGet(options?: any) {
        return SessionApiFp(this.configuration).apiServicesAppSessionGetCurrentLoginInformationsGet(options)(this.fetch, this.basePath);
    }

}
/**
 * SettingsApi - fetch parameter creator
 * @export
 */
export const SettingsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetApplicationSettingGet(name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetApplicationSetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetApplicationSettingsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetApplicationSettings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetSettingDefinitionGet(name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetSettingDefinition`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetSettingDefinitionsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetSettingDefinitions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetTenantSettingGet(name?: string, tenantId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetTenantSetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetTenantSettingsGet(tenantId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetTenantSettings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetUserSettingGet(name?: string, tenantId?: number, userId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetUserSetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetUserSettingsGet(tenantId?: number, userId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/GetUserSettings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateApplicationSettingPut(name?: string, value?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/UpdateApplicationSetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateTenantSettingPut(tenantId?: number, name?: string, value?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/UpdateTenantSetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateUserSettingPut(tenantId?: number, userId?: number, name?: string, value?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Settings/UpdateUserSetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetApplicationSettingGet(name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetApplicationSettingGet(name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetApplicationSettingsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ISettingValue>> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetApplicationSettingsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetSettingDefinitionGet(name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SettingDefinition> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetSettingDefinitionGet(name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetSettingDefinitionsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SettingDefinition>> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetSettingDefinitionsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetTenantSettingGet(name?: string, tenantId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetTenantSettingGet(name, tenantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetTenantSettingsGet(tenantId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ISettingValue>> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetTenantSettingsGet(tenantId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetUserSettingGet(name?: string, tenantId?: number, userId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetUserSettingGet(name, tenantId, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetUserSettingsGet(tenantId?: number, userId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ISettingValue>> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsGetUserSettingsGet(tenantId, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateApplicationSettingPut(name?: string, value?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsUpdateApplicationSettingPut(name, value, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateTenantSettingPut(tenantId?: number, name?: string, value?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsUpdateTenantSettingPut(tenantId, name, value, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateUserSettingPut(tenantId?: number, userId?: number, name?: string, value?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SettingsApiFetchParamCreator(configuration).apiServicesAppSettingsUpdateUserSettingPut(tenantId, userId, name, value, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetApplicationSettingGet(name?: string, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetApplicationSettingGet(name, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetApplicationSettingsGet(options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetApplicationSettingsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetSettingDefinitionGet(name?: string, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetSettingDefinitionGet(name, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetSettingDefinitionsGet(options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetSettingDefinitionsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetTenantSettingGet(name?: string, tenantId?: number, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetTenantSettingGet(name, tenantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetTenantSettingsGet(tenantId?: number, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetTenantSettingsGet(tenantId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetUserSettingGet(name?: string, tenantId?: number, userId?: number, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetUserSettingGet(name, tenantId, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsGetUserSettingsGet(tenantId?: number, userId?: number, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsGetUserSettingsGet(tenantId, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateApplicationSettingPut(name?: string, value?: string, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsUpdateApplicationSettingPut(name, value, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateTenantSettingPut(tenantId?: number, name?: string, value?: string, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsUpdateTenantSettingPut(tenantId, name, value, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {number} [userId] 
         * @param {string} [name] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSettingsUpdateUserSettingPut(tenantId?: number, userId?: number, name?: string, value?: string, options?: any) {
            return SettingsApiFp(configuration).apiServicesAppSettingsUpdateUserSettingPut(tenantId, userId, name, value, options)(fetch, basePath);
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetApplicationSettingGet(name?: string, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetApplicationSettingGet(name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetApplicationSettingsGet(options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetApplicationSettingsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetSettingDefinitionGet(name?: string, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetSettingDefinitionGet(name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetSettingDefinitionsGet(options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetSettingDefinitionsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetTenantSettingGet(name?: string, tenantId?: number, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetTenantSettingGet(name, tenantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetTenantSettingsGet(tenantId?: number, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetTenantSettingsGet(tenantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {number} [tenantId] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetUserSettingGet(name?: string, tenantId?: number, userId?: number, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetUserSettingGet(name, tenantId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [tenantId] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsGetUserSettingsGet(tenantId?: number, userId?: number, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsGetUserSettingsGet(tenantId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsUpdateApplicationSettingPut(name?: string, value?: string, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsUpdateApplicationSettingPut(name, value, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [tenantId] 
     * @param {string} [name] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsUpdateTenantSettingPut(tenantId?: number, name?: string, value?: string, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsUpdateTenantSettingPut(tenantId, name, value, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [tenantId] 
     * @param {number} [userId] 
     * @param {string} [name] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiServicesAppSettingsUpdateUserSettingPut(tenantId?: number, userId?: number, name?: string, value?: string, options?: any) {
        return SettingsApiFp(this.configuration).apiServicesAppSettingsUpdateUserSettingPut(tenantId, userId, name, value, options)(this.fetch, this.basePath);
    }

}
/**
 * SynchronizationConfiguationApi - fetch parameter creator
 * @export
 */
export const SynchronizationConfiguationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SynchronizationConfigurationEntryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body?: SynchronizationConfigurationEntryDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/SynchronizationConfiguation/CreateConfiguration`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SynchronizationConfigurationEntryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnectionId] 
         * @param {string} [sourceServiceProvider] 
         * @param {number} [targetServiceConnectionId] 
         * @param {string} [targetServiceProvider] 
         * @param {string} [entityType] 
         * @param {string} [userId] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId?: number, sourceServiceProvider?: string, targetServiceConnectionId?: number, targetServiceProvider?: string, entityType?: string, userId?: string, id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/SynchronizationConfiguation/DeleteConfiguration`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sourceServiceConnectionId !== undefined) {
                localVarQueryParameter['SourceServiceConnectionId'] = sourceServiceConnectionId;
            }

            if (sourceServiceProvider !== undefined) {
                localVarQueryParameter['SourceServiceProvider'] = sourceServiceProvider;
            }

            if (targetServiceConnectionId !== undefined) {
                localVarQueryParameter['TargetServiceConnectionId'] = targetServiceConnectionId;
            }

            if (targetServiceProvider !== undefined) {
                localVarQueryParameter['TargetServiceProvider'] = targetServiceProvider;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['EntityType'] = entityType;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/SynchronizationConfiguation/GetAllConfigurations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchronizationConfiguationApi - functional programming interface
 * @export
 */
export const SynchronizationConfiguationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SynchronizationConfigurationEntryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body?: SynchronizationConfigurationEntryDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SynchronizationConfigurationEntryDto> {
            const localVarFetchArgs = SynchronizationConfiguationApiFetchParamCreator(configuration).apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnectionId] 
         * @param {string} [sourceServiceProvider] 
         * @param {number} [targetServiceConnectionId] 
         * @param {string} [targetServiceProvider] 
         * @param {string} [entityType] 
         * @param {string} [userId] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId?: number, sourceServiceProvider?: string, targetServiceConnectionId?: number, targetServiceProvider?: string, entityType?: string, userId?: string, id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SynchronizationConfiguationApiFetchParamCreator(configuration).apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId, sourceServiceProvider, targetServiceConnectionId, targetServiceProvider, entityType, userId, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SynchronizationConfigurationEntryDto>> {
            const localVarFetchArgs = SynchronizationConfiguationApiFetchParamCreator(configuration).apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SynchronizationConfiguationApi - factory interface
 * @export
 */
export const SynchronizationConfiguationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {SynchronizationConfigurationEntryDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body?: SynchronizationConfigurationEntryDto, options?: any) {
            return SynchronizationConfiguationApiFp(configuration).apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [sourceServiceConnectionId] 
         * @param {string} [sourceServiceProvider] 
         * @param {number} [targetServiceConnectionId] 
         * @param {string} [targetServiceProvider] 
         * @param {string} [entityType] 
         * @param {string} [userId] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId?: number, sourceServiceProvider?: string, targetServiceConnectionId?: number, targetServiceProvider?: string, entityType?: string, userId?: string, id?: number, options?: any) {
            return SynchronizationConfiguationApiFp(configuration).apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId, sourceServiceProvider, targetServiceConnectionId, targetServiceProvider, entityType, userId, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options?: any) {
            return SynchronizationConfiguationApiFp(configuration).apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options)(fetch, basePath);
        },
    };
};

/**
 * SynchronizationConfiguationApi - object-oriented interface
 * @export
 * @class SynchronizationConfiguationApi
 * @extends {BaseAPI}
 */
export class SynchronizationConfiguationApi extends BaseAPI {
    /**
     * 
     * @param {SynchronizationConfigurationEntryDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationConfiguationApi
     */
    public apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body?: SynchronizationConfigurationEntryDto, options?: any) {
        return SynchronizationConfiguationApiFp(this.configuration).apiServicesAppSynchronizationConfiguationCreateConfigurationPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [sourceServiceConnectionId] 
     * @param {string} [sourceServiceProvider] 
     * @param {number} [targetServiceConnectionId] 
     * @param {string} [targetServiceProvider] 
     * @param {string} [entityType] 
     * @param {string} [userId] 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationConfiguationApi
     */
    public apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId?: number, sourceServiceProvider?: string, targetServiceConnectionId?: number, targetServiceProvider?: string, entityType?: string, userId?: string, id?: number, options?: any) {
        return SynchronizationConfiguationApiFp(this.configuration).apiServicesAppSynchronizationConfiguationDeleteConfigurationDelete(sourceServiceConnectionId, sourceServiceProvider, targetServiceConnectionId, targetServiceProvider, entityType, userId, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationConfiguationApi
     */
    public apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options?: any) {
        return SynchronizationConfiguationApiFp(this.configuration).apiServicesAppSynchronizationConfiguationGetAllConfigurationsGet(options)(this.fetch, this.basePath);
    }

}
/**
 * TenantApi - fetch parameter creator
 * @export
 */
export const TenantApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantCreatePost(body?: CreateTenantDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Tenant/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateTenantDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Tenant/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {boolean} [isActive] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Tenant/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['Keyword'] = keyword;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Tenant/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {Date} [creationDateStart] 
         * @param {Date} [creationDateEnd] 
         * @param {number} [editionId] 
         * @param {boolean} [editionIdSpecified] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetTenantsGet(filter?: string, creationDateStart?: Date, creationDateEnd?: Date, editionId?: number, editionIdSpecified?: boolean, sorting?: string, maxResultCount?: number, skipCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Tenant/GetTenants`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (creationDateStart !== undefined) {
                localVarQueryParameter['CreationDateStart'] = (creationDateStart as any).toISOString();
            }

            if (creationDateEnd !== undefined) {
                localVarQueryParameter['CreationDateEnd'] = (creationDateEnd as any).toISOString();
            }

            if (editionId !== undefined) {
                localVarQueryParameter['EditionId'] = editionId;
            }

            if (editionIdSpecified !== undefined) {
                localVarQueryParameter['EditionIdSpecified'] = editionIdSpecified;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantUpdatePut(body?: TenantDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/Tenant/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TenantDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantCreatePost(body?: CreateTenantDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantDto> {
            const localVarFetchArgs = TenantApiFetchParamCreator(configuration).apiServicesAppTenantCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TenantApiFetchParamCreator(configuration).apiServicesAppTenantDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {boolean} [isActive] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantDtoPagedResultDto> {
            const localVarFetchArgs = TenantApiFetchParamCreator(configuration).apiServicesAppTenantGetAllGet(keyword, isActive, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantDto> {
            const localVarFetchArgs = TenantApiFetchParamCreator(configuration).apiServicesAppTenantGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {Date} [creationDateStart] 
         * @param {Date} [creationDateEnd] 
         * @param {number} [editionId] 
         * @param {boolean} [editionIdSpecified] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetTenantsGet(filter?: string, creationDateStart?: Date, creationDateEnd?: Date, editionId?: number, editionIdSpecified?: boolean, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantListDtoPagedResultDto> {
            const localVarFetchArgs = TenantApiFetchParamCreator(configuration).apiServicesAppTenantGetTenantsGet(filter, creationDateStart, creationDateEnd, editionId, editionIdSpecified, sorting, maxResultCount, skipCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantUpdatePut(body?: TenantDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantDto> {
            const localVarFetchArgs = TenantApiFetchParamCreator(configuration).apiServicesAppTenantUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantCreatePost(body?: CreateTenantDto, options?: any) {
            return TenantApiFp(configuration).apiServicesAppTenantCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantDeleteDelete(id?: number, options?: any) {
            return TenantApiFp(configuration).apiServicesAppTenantDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {boolean} [isActive] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options?: any) {
            return TenantApiFp(configuration).apiServicesAppTenantGetAllGet(keyword, isActive, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetGet(id?: number, options?: any) {
            return TenantApiFp(configuration).apiServicesAppTenantGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {Date} [creationDateStart] 
         * @param {Date} [creationDateEnd] 
         * @param {number} [editionId] 
         * @param {boolean} [editionIdSpecified] 
         * @param {string} [sorting] 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantGetTenantsGet(filter?: string, creationDateStart?: Date, creationDateEnd?: Date, editionId?: number, editionIdSpecified?: boolean, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
            return TenantApiFp(configuration).apiServicesAppTenantGetTenantsGet(filter, creationDateStart, creationDateEnd, editionId, editionIdSpecified, sorting, maxResultCount, skipCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTenantUpdatePut(body?: TenantDto, options?: any) {
            return TenantApiFp(configuration).apiServicesAppTenantUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {CreateTenantDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiServicesAppTenantCreatePost(body?: CreateTenantDto, options?: any) {
        return TenantApiFp(this.configuration).apiServicesAppTenantCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiServicesAppTenantDeleteDelete(id?: number, options?: any) {
        return TenantApiFp(this.configuration).apiServicesAppTenantDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [keyword] 
     * @param {boolean} [isActive] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiServicesAppTenantGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options?: any) {
        return TenantApiFp(this.configuration).apiServicesAppTenantGetAllGet(keyword, isActive, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiServicesAppTenantGetGet(id?: number, options?: any) {
        return TenantApiFp(this.configuration).apiServicesAppTenantGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {Date} [creationDateStart] 
     * @param {Date} [creationDateEnd] 
     * @param {number} [editionId] 
     * @param {boolean} [editionIdSpecified] 
     * @param {string} [sorting] 
     * @param {number} [maxResultCount] 
     * @param {number} [skipCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiServicesAppTenantGetTenantsGet(filter?: string, creationDateStart?: Date, creationDateEnd?: Date, editionId?: number, editionIdSpecified?: boolean, sorting?: string, maxResultCount?: number, skipCount?: number, options?: any) {
        return TenantApiFp(this.configuration).apiServicesAppTenantGetTenantsGet(filter, creationDateStart, creationDateEnd, editionId, editionIdSpecified, sorting, maxResultCount, skipCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TenantDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiServicesAppTenantUpdatePut(body?: TenantDto, options?: any) {
        return TenantApiFp(this.configuration).apiServicesAppTenantUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TimeEntitiesApi - fetch parameter creator
 * @export
 */
export const TimeEntitiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TimeEntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body?: TimeEntityDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeEntities/CreateOrUpdateEntity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TimeEntityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeEntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesDeleteDelete(body?: TimeEntityDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeEntities/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TimeEntityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id?: string, providerName?: string, serviceConnectionId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeEntities/DeleteEntityById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName?: string, serviceConnectionId?: number, fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeEntities/GetAllEntities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesGetEntityGet(id?: string, providerName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeEntities/GetEntity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/TimeEntities/GetAllById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/TimeEntities/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            if (internalFilters !== undefined) {
                localVarQueryParameter['internalFilters'] = internalFilters;
            }

            if (externalFilters !== undefined) {
                localVarQueryParameter['externalFilters'] = externalFilters;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetByIdGet(id?: string, serviceConnectionId?: number, includeFields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/TimeEntities/GetById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceConnectionId !== undefined) {
                localVarQueryParameter['serviceConnectionId'] = serviceConnectionId;
            }

            if (includeFields) {
                localVarQueryParameter['includeFields'] = includeFields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeEntitiesApi - functional programming interface
 * @export
 */
export const TimeEntitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TimeEntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body?: TimeEntityDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeEntityDto> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TimeEntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesDeleteDelete(body?: TimeEntityDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesAppTimeEntitiesDeleteDelete(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id?: string, providerName?: string, serviceConnectionId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id, providerName, serviceConnectionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName?: string, serviceConnectionId?: number, fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TimeEntityDto>> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName, serviceConnectionId, fromDate, toDate, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesGetEntityGet(id?: string, providerName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeEntityDto> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesAppTimeEntitiesGetEntityGet(id, providerName, serviceConnectionId, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TimeEntity>> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TimeEntity>> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate, toDate, internalFilters, externalFilters, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetByIdGet(id?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeEntity> {
            const localVarFetchArgs = TimeEntitiesApiFetchParamCreator(configuration).apiServicesModulesZdiTimeEntitiesGetByIdGet(id, serviceConnectionId, includeFields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TimeEntitiesApi - factory interface
 * @export
 */
export const TimeEntitiesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {TimeEntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body?: TimeEntityDto, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TimeEntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesDeleteDelete(body?: TimeEntityDto, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesAppTimeEntitiesDeleteDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id?: string, providerName?: string, serviceConnectionId?: number, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id, providerName, serviceConnectionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName?: string, serviceConnectionId?: number, fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName, serviceConnectionId, fromDate, toDate, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [providerName] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeEntitiesGetEntityGet(id?: string, providerName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesAppTimeEntitiesGetEntityGet(id, providerName, serviceConnectionId, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id?: string, includeFields?: Array<string>, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {string} [internalFilters] 
         * @param {string} [externalFilters] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate, toDate, internalFilters, externalFilters, includeFields, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [serviceConnectionId] 
         * @param {Array<string>} [includeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiTimeEntitiesGetByIdGet(id?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
            return TimeEntitiesApiFp(configuration).apiServicesModulesZdiTimeEntitiesGetByIdGet(id, serviceConnectionId, includeFields, options)(fetch, basePath);
        },
    };
};

/**
 * TimeEntitiesApi - object-oriented interface
 * @export
 * @class TimeEntitiesApi
 * @extends {BaseAPI}
 */
export class TimeEntitiesApi extends BaseAPI {
    /**
     * 
     * @param {TimeEntityDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body?: TimeEntityDto, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TimeEntityDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesAppTimeEntitiesDeleteDelete(body?: TimeEntityDto, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesAppTimeEntitiesDeleteDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [providerName] 
     * @param {number} [serviceConnectionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id?: string, providerName?: string, serviceConnectionId?: number, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesAppTimeEntitiesDeleteEntityByIdDelete(id, providerName, serviceConnectionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [providerName] 
     * @param {number} [serviceConnectionId] 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName?: string, serviceConnectionId?: number, fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesAppTimeEntitiesGetAllEntitiesGet(providerName, serviceConnectionId, fromDate, toDate, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [providerName] 
     * @param {number} [serviceConnectionId] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesAppTimeEntitiesGetEntityGet(id?: string, providerName?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesAppTimeEntitiesGetEntityGet(id, providerName, serviceConnectionId, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id?: string, includeFields?: Array<string>, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesModulesZdiTimeEntitiesGetAllByIdGet(id, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {string} [internalFilters] 
     * @param {string} [externalFilters] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate?: Date, toDate?: Date, internalFilters?: string, externalFilters?: string, includeFields?: Array<string>, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesModulesZdiTimeEntitiesGetAllGet(fromDate, toDate, internalFilters, externalFilters, includeFields, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [serviceConnectionId] 
     * @param {Array<string>} [includeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeEntitiesApi
     */
    public apiServicesModulesZdiTimeEntitiesGetByIdGet(id?: string, serviceConnectionId?: number, includeFields?: Array<string>, options?: any) {
        return TimeEntitiesApiFp(this.configuration).apiServicesModulesZdiTimeEntitiesGetByIdGet(id, serviceConnectionId, includeFields, options)(this.fetch, this.basePath);
    }

}
/**
 * TimeTrackingSessionsApi - fetch parameter creator
 * @export
 */
export const TimeTrackingSessionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/CreateFromTimeEntity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeTrackingSessionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsCreatePost(body?: TimeTrackingSessionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TimeTrackingSessionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsDeleteDelete(id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsGetGet(id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {string} [state] 
         * @param {string} [history] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId?: string, state?: string, history?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/TrackTimer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (history !== undefined) {
                localVarQueryParameter['history'] = history;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeTrackingSessionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsUpdatePut(body?: TimeTrackingSessionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/TimeTrackingSessions/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TimeTrackingSessionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeTrackingSessionsApi - functional programming interface
 * @export
 */
export const TimeTrackingSessionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TimeTrackingSessionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsCreatePost(body?: TimeTrackingSessionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeTrackingSessionDto> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsDeleteDelete(id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeTrackingSessionDtoPagedResultDto> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsGetAllGet(sorting, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsGetGet(id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeTrackingSessionDto> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {string} [state] 
         * @param {string} [history] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId?: string, state?: string, history?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId, state, history, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TimeTrackingSessionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsUpdatePut(body?: TimeTrackingSessionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TimeTrackingSessionDto> {
            const localVarFetchArgs = TimeTrackingSessionsApiFetchParamCreator(configuration).apiServicesAppTimeTrackingSessionsUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TimeTrackingSessionsApi - factory interface
 * @export
 */
export const TimeTrackingSessionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id?: string, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TimeTrackingSessionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsCreatePost(body?: TimeTrackingSessionDto, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsDeleteDelete(id?: string, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsGetAllGet(sorting, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsGetGet(id?: string, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {string} [state] 
         * @param {string} [history] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId?: string, state?: string, history?: string, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId, state, history, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TimeTrackingSessionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppTimeTrackingSessionsUpdatePut(body?: TimeTrackingSessionDto, options?: any) {
            return TimeTrackingSessionsApiFp(configuration).apiServicesAppTimeTrackingSessionsUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * TimeTrackingSessionsApi - object-oriented interface
 * @export
 * @class TimeTrackingSessionsApi
 * @extends {BaseAPI}
 */
export class TimeTrackingSessionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id?: string, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TimeTrackingSessionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsCreatePost(body?: TimeTrackingSessionDto, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsDeleteDelete(id?: string, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsGetAllGet(sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsGetAllGet(sorting, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsGetGet(id?: string, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {string} [state] 
     * @param {string} [history] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId?: string, state?: string, history?: string, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId, state, history, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TimeTrackingSessionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackingSessionsApi
     */
    public apiServicesAppTimeTrackingSessionsUpdatePut(body?: TimeTrackingSessionDto, options?: any) {
        return TimeTrackingSessionsApiFp(this.configuration).apiServicesAppTimeTrackingSessionsUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TokenAuthApi - fetch parameter creator
 * @export
 */
export const TokenAuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthenticateModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthAuthenticatePost(body?: AuthenticateModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/TokenAuth/Authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthenticateModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExternalAuthenticateModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthExternalAuthenticatePost(body?: ExternalAuthenticateModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/TokenAuth/ExternalAuthenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExternalAuthenticateModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthGetExternalAuthenticationProvidersGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/TokenAuth/GetExternalAuthenticationProviders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthRefreshTokenPost(body?: RefreshTokenModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/TokenAuth/RefreshToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RefreshTokenModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenAuthApi - functional programming interface
 * @export
 */
export const TokenAuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthenticateModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthAuthenticatePost(body?: AuthenticateModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticateResultModel> {
            const localVarFetchArgs = TokenAuthApiFetchParamCreator(configuration).apiTokenAuthAuthenticatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ExternalAuthenticateModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthExternalAuthenticatePost(body?: ExternalAuthenticateModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExternalAuthenticateResultModel> {
            const localVarFetchArgs = TokenAuthApiFetchParamCreator(configuration).apiTokenAuthExternalAuthenticatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthGetExternalAuthenticationProvidersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExternalLoginProviderInfoModel>> {
            const localVarFetchArgs = TokenAuthApiFetchParamCreator(configuration).apiTokenAuthGetExternalAuthenticationProvidersGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RefreshTokenModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthRefreshTokenPost(body?: RefreshTokenModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RefreshTokenResultModel> {
            const localVarFetchArgs = TokenAuthApiFetchParamCreator(configuration).apiTokenAuthRefreshTokenPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TokenAuthApi - factory interface
 * @export
 */
export const TokenAuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AuthenticateModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthAuthenticatePost(body?: AuthenticateModel, options?: any) {
            return TokenAuthApiFp(configuration).apiTokenAuthAuthenticatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ExternalAuthenticateModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthExternalAuthenticatePost(body?: ExternalAuthenticateModel, options?: any) {
            return TokenAuthApiFp(configuration).apiTokenAuthExternalAuthenticatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthGetExternalAuthenticationProvidersGet(options?: any) {
            return TokenAuthApiFp(configuration).apiTokenAuthGetExternalAuthenticationProvidersGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {RefreshTokenModel} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenAuthRefreshTokenPost(body?: RefreshTokenModel, options?: any) {
            return TokenAuthApiFp(configuration).apiTokenAuthRefreshTokenPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * TokenAuthApi - object-oriented interface
 * @export
 * @class TokenAuthApi
 * @extends {BaseAPI}
 */
export class TokenAuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthenticateModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenAuthApi
     */
    public apiTokenAuthAuthenticatePost(body?: AuthenticateModel, options?: any) {
        return TokenAuthApiFp(this.configuration).apiTokenAuthAuthenticatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ExternalAuthenticateModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenAuthApi
     */
    public apiTokenAuthExternalAuthenticatePost(body?: ExternalAuthenticateModel, options?: any) {
        return TokenAuthApiFp(this.configuration).apiTokenAuthExternalAuthenticatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenAuthApi
     */
    public apiTokenAuthGetExternalAuthenticationProvidersGet(options?: any) {
        return TokenAuthApiFp(this.configuration).apiTokenAuthGetExternalAuthenticationProvidersGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RefreshTokenModel} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenAuthApi
     */
    public apiTokenAuthRefreshTokenPost(body?: RefreshTokenModel, options?: any) {
        return TokenAuthApiFp(this.configuration).apiTokenAuthRefreshTokenPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Int64EntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserActivatePost(body?: Int64EntityDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/Activate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Int64EntityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeUserLanguageDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserChangeLanguagePost(body?: ChangeUserLanguageDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/ChangeLanguage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangeUserLanguageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserChangePasswordPost(body?: ChangePasswordDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/ChangePassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangePasswordDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserCreatePost(body?: CreateUserDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/Create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Int64EntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserDeActivatePost(body?: Int64EntityDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/DeActivate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Int64EntityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserDeleteDelete(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/Delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {boolean} [isActive] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/GetAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['Keyword'] = keyword;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetGet(id?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/Get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetRolesGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/GetRoles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshApiKeyDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserRefreshApiKeyPost(body?: RefreshApiKeyDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/RefreshApiKey`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RefreshApiKeyDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserResetPasswordPost(body?: ResetPasswordDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/ResetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResetPasswordDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserUpdatePut(body?: UserDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/User/Update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Int64EntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserActivatePost(body?: Int64EntityDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserActivatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ChangeUserLanguageDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserChangeLanguagePost(body?: ChangeUserLanguageDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserChangeLanguagePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserChangePasswordPost(body?: ChangePasswordDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserChangePasswordPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateUserDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserCreatePost(body?: CreateUserDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserCreatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Int64EntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserDeActivatePost(body?: Int64EntityDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserDeActivatePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserDeleteDelete(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserDeleteDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {boolean} [isActive] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDtoPagedResultDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserGetAllGet(keyword, isActive, skipCount, maxResultCount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetGet(id?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserGetGet(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetRolesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDtoListResultDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserGetRolesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RefreshApiKeyDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserRefreshApiKeyPost(body?: RefreshApiKeyDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserRefreshApiKeyPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserResetPasswordPost(body?: ResetPasswordDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserResetPasswordPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UserDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserUpdatePut(body?: UserDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).apiServicesAppUserUpdatePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Int64EntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserActivatePost(body?: Int64EntityDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserActivatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ChangeUserLanguageDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserChangeLanguagePost(body?: ChangeUserLanguageDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserChangeLanguagePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ChangePasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserChangePasswordPost(body?: ChangePasswordDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserChangePasswordPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateUserDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserCreatePost(body?: CreateUserDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserCreatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Int64EntityDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserDeActivatePost(body?: Int64EntityDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserDeActivatePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserDeleteDelete(id?: number, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserDeleteDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {boolean} [isActive] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserGetAllGet(keyword, isActive, skipCount, maxResultCount, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetGet(id?: number, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserGetGet(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserGetRolesGet(options?: any) {
            return UserApiFp(configuration).apiServicesAppUserGetRolesGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {RefreshApiKeyDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserRefreshApiKeyPost(body?: RefreshApiKeyDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserRefreshApiKeyPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResetPasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserResetPasswordPost(body?: ResetPasswordDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserResetPasswordPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UserDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppUserUpdatePut(body?: UserDto, options?: any) {
            return UserApiFp(configuration).apiServicesAppUserUpdatePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {Int64EntityDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserActivatePost(body?: Int64EntityDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserActivatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ChangeUserLanguageDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserChangeLanguagePost(body?: ChangeUserLanguageDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserChangeLanguagePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ChangePasswordDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserChangePasswordPost(body?: ChangePasswordDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserChangePasswordPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateUserDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserCreatePost(body?: CreateUserDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserCreatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Int64EntityDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserDeActivatePost(body?: Int64EntityDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserDeActivatePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserDeleteDelete(id?: number, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserDeleteDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [keyword] 
     * @param {boolean} [isActive] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserGetAllGet(keyword?: string, isActive?: boolean, skipCount?: number, maxResultCount?: number, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserGetAllGet(keyword, isActive, skipCount, maxResultCount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserGetGet(id?: number, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserGetGet(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserGetRolesGet(options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserGetRolesGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RefreshApiKeyDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserRefreshApiKeyPost(body?: RefreshApiKeyDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserRefreshApiKeyPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResetPasswordDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserResetPasswordPost(body?: ResetPasswordDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserResetPasswordPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UserDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiServicesAppUserUpdatePut(body?: UserDto, options?: any) {
        return UserApiFp(this.configuration).apiServicesAppUserUpdatePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * WebHooksApi - fetch parameter creator
 * @export
 */
export const WebHooksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/app/WebHooks/RefreshWebHookSubscriptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} providerName 
         * @param {string} serviceConnectionId 
         * @param {string} entityType 
         * @param {string} userIdentifier 
         * @param {string} [validationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName: string, serviceConnectionId: string, entityType: string, userIdentifier: string, validationToken?: string, options: any = {}): FetchArgs {
            // verify required parameter 'providerName' is not null or undefined
            if (providerName === null || providerName === undefined) {
                throw new RequiredError('providerName','Required parameter providerName was null or undefined when calling apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost.');
            }
            // verify required parameter 'serviceConnectionId' is not null or undefined
            if (serviceConnectionId === null || serviceConnectionId === undefined) {
                throw new RequiredError('serviceConnectionId','Required parameter serviceConnectionId was null or undefined when calling apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost.');
            }
            // verify required parameter 'entityType' is not null or undefined
            if (entityType === null || entityType === undefined) {
                throw new RequiredError('entityType','Required parameter entityType was null or undefined when calling apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost.');
            }
            // verify required parameter 'userIdentifier' is not null or undefined
            if (userIdentifier === null || userIdentifier === undefined) {
                throw new RequiredError('userIdentifier','Required parameter userIdentifier was null or undefined when calling apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost.');
            }
            const localVarPath = `/api/webhooks/Incoming/{providerName}/{serviceConnectionId}/{entityType}/{userIdentifier}`
                .replace(`{${"providerName"}}`, encodeURIComponent(String(providerName)))
                .replace(`{${"serviceConnectionId"}}`, encodeURIComponent(String(serviceConnectionId)))
                .replace(`{${"entityType"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"userIdentifier"}}`, encodeURIComponent(String(userIdentifier)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (validationToken !== undefined) {
                localVarQueryParameter['validationToken'] = validationToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebHooksApi - functional programming interface
 * @export
 */
export const WebHooksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WebHooksApiFetchParamCreator(configuration).apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} providerName 
         * @param {string} serviceConnectionId 
         * @param {string} entityType 
         * @param {string} userIdentifier 
         * @param {string} [validationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName: string, serviceConnectionId: string, entityType: string, userIdentifier: string, validationToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WebHooksApiFetchParamCreator(configuration).apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName, serviceConnectionId, entityType, userIdentifier, validationToken, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WebHooksApi - factory interface
 * @export
 */
export const WebHooksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options?: any) {
            return WebHooksApiFp(configuration).apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} providerName 
         * @param {string} serviceConnectionId 
         * @param {string} entityType 
         * @param {string} userIdentifier 
         * @param {string} [validationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName: string, serviceConnectionId: string, entityType: string, userIdentifier: string, validationToken?: string, options?: any) {
            return WebHooksApiFp(configuration).apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName, serviceConnectionId, entityType, userIdentifier, validationToken, options)(fetch, basePath);
        },
    };
};

/**
 * WebHooksApi - object-oriented interface
 * @export
 * @class WebHooksApi
 * @extends {BaseAPI}
 */
export class WebHooksApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebHooksApi
     */
    public apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options?: any) {
        return WebHooksApiFp(this.configuration).apiServicesAppWebHooksRefreshWebHookSubscriptionsPost(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} providerName 
     * @param {string} serviceConnectionId 
     * @param {string} entityType 
     * @param {string} userIdentifier 
     * @param {string} [validationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebHooksApi
     */
    public apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName: string, serviceConnectionId: string, entityType: string, userIdentifier: string, validationToken?: string, options?: any) {
        return WebHooksApiFp(this.configuration).apiWebhooksIncomingProviderNameServiceConnectionIdEntityTypeUserIdentifierPost(providerName, serviceConnectionId, entityType, userIdentifier, validationToken, options)(this.fetch, this.basePath);
    }

}
/**
 * WorkItemSyncApi - fetch parameter creator
 * @export
 */
export const WorkItemSyncApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkItemJobMapping} [body] 
         * @param {string} [id] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body?: WorkItemJobMapping, id?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/CreateOrUpdateJobMapping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (sourceServiceConnection !== undefined) {
                localVarQueryParameter['sourceServiceConnection'] = sourceServiceConnection;
            }

            if (targetServiceConnection !== undefined) {
                localVarQueryParameter['targetServiceConnection'] = targetServiceConnection;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkItemJobMapping" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [resourceId] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email?: string, resourceId?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/CreateOrUpdateResourceMapping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (sourceServiceConnection !== undefined) {
                localVarQueryParameter['sourceServiceConnection'] = sourceServiceConnection;
            }

            if (targetServiceConnection !== undefined) {
                localVarQueryParameter['targetServiceConnection'] = targetServiceConnection;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [devOpsState] 
         * @param {string} [taskState] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState?: string, taskState?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/CreateOrUpdateWorkItemStateMapping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (devOpsState !== undefined) {
                localVarQueryParameter['devOpsState'] = devOpsState;
            }

            if (taskState !== undefined) {
                localVarQueryParameter['taskState'] = taskState;
            }

            if (sourceServiceConnection !== undefined) {
                localVarQueryParameter['sourceServiceConnection'] = sourceServiceConnection;
            }

            if (targetServiceConnection !== undefined) {
                localVarQueryParameter['targetServiceConnection'] = targetServiceConnection;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [mappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/DeleteJobMapping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/DeleteMapping`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mappingId !== undefined) {
                localVarQueryParameter['mappingId'] = mappingId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncExecuteSyncPost(body?: ModelObject, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/ExecuteSync`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/GetJobMappings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sourceServiceConnection !== undefined) {
                localVarQueryParameter['sourceServiceConnection'] = sourceServiceConnection;
            }

            if (targetServiceConnection !== undefined) {
                localVarQueryParameter['targetServiceConnection'] = targetServiceConnection;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/GetJobTaskStates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/GetResourceMappings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sourceServiceConnection !== undefined) {
                localVarQueryParameter['sourceServiceConnection'] = sourceServiceConnection;
            }

            if (targetServiceConnection !== undefined) {
                localVarQueryParameter['targetServiceConnection'] = targetServiceConnection;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount?: number, skipCount?: number, filter?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/GetSyncProtocols`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItemSync/GetWorkItemStateMappings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sourceServiceConnection !== undefined) {
                localVarQueryParameter['sourceServiceConnection'] = sourceServiceConnection;
            }

            if (targetServiceConnection !== undefined) {
                localVarQueryParameter['targetServiceConnection'] = targetServiceConnection;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkItemSyncApi - functional programming interface
 * @export
 */
export const WorkItemSyncApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkItemJobMapping} [body] 
         * @param {string} [id] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body?: WorkItemJobMapping, id?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMapping> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body, id, sourceServiceConnection, targetServiceConnection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [resourceId] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email?: string, resourceId?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMapping> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email, resourceId, sourceServiceConnection, targetServiceConnection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [devOpsState] 
         * @param {string} [taskState] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState?: string, taskState?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataMapping> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState, taskState, sourceServiceConnection, targetServiceConnection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [mappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [mappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ModelObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncExecuteSyncPost(body?: ModelObject, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncExecuteSyncPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkItemJobMapping>> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection, targetServiceConnection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DataMapping>> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection, targetServiceConnection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount?: number, skipCount?: number, filter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataExchangeProtocolPagedResultDto> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount, skipCount, filter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DataMapping>> {
            const localVarFetchArgs = WorkItemSyncApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection, targetServiceConnection, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WorkItemSyncApi - factory interface
 * @export
 */
export const WorkItemSyncApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {WorkItemJobMapping} [body] 
         * @param {string} [id] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body?: WorkItemJobMapping, id?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body, id, sourceServiceConnection, targetServiceConnection, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [resourceId] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email?: string, resourceId?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email, resourceId, sourceServiceConnection, targetServiceConnection, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [devOpsState] 
         * @param {string} [taskState] 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState?: string, taskState?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState, taskState, sourceServiceConnection, targetServiceConnection, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [mappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId?: string, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [mappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ModelObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncExecuteSyncPost(body?: ModelObject, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncExecuteSyncPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection, targetServiceConnection, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection, targetServiceConnection, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [maxResultCount] 
         * @param {number} [skipCount] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount?: number, skipCount?: number, filter?: string, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount, skipCount, filter, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [sourceServiceConnection] 
         * @param {number} [targetServiceConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
            return WorkItemSyncApiFp(configuration).apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection, targetServiceConnection, options)(fetch, basePath);
        },
    };
};

/**
 * WorkItemSyncApi - object-oriented interface
 * @export
 * @class WorkItemSyncApi
 * @extends {BaseAPI}
 */
export class WorkItemSyncApi extends BaseAPI {
    /**
     * 
     * @param {WorkItemJobMapping} [body] 
     * @param {string} [id] 
     * @param {number} [sourceServiceConnection] 
     * @param {number} [targetServiceConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body?: WorkItemJobMapping, id?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(body, id, sourceServiceConnection, targetServiceConnection, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [email] 
     * @param {string} [resourceId] 
     * @param {number} [sourceServiceConnection] 
     * @param {number} [targetServiceConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email?: string, resourceId?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email, resourceId, sourceServiceConnection, targetServiceConnection, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [devOpsState] 
     * @param {string} [taskState] 
     * @param {number} [sourceServiceConnection] 
     * @param {number} [targetServiceConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState?: string, taskState?: string, sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState, taskState, sourceServiceConnection, targetServiceConnection, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [mappingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId?: string, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(mappingId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [mappingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncDeleteMappingDelete(mappingId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ModelObject} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncExecuteSyncPost(body?: ModelObject, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncExecuteSyncPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [sourceServiceConnection] 
     * @param {number} [targetServiceConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncGetJobMappingsGet(sourceServiceConnection, targetServiceConnection, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [sourceServiceConnection] 
     * @param {number} [targetServiceConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(sourceServiceConnection, targetServiceConnection, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [maxResultCount] 
     * @param {number} [skipCount] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount?: number, skipCount?: number, filter?: string, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(maxResultCount, skipCount, filter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [sourceServiceConnection] 
     * @param {number} [targetServiceConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemSyncApi
     */
    public apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection?: number, targetServiceConnection?: number, options?: any) {
        return WorkItemSyncApiFp(this.configuration).apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(sourceServiceConnection, targetServiceConnection, options)(this.fetch, this.basePath);
    }

}
/**
 * WorkItemsApi - fetch parameter creator
 * @export
 */
export const WorkItemsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetAreasGet(projectName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItems/GetAreas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectNameFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItems/GetProjects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (projectNameFilter !== undefined) {
                localVarQueryParameter['projectNameFilter'] = projectNameFilter;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectName] 
         * @param {string} [wiql] 
         * @param {Array<string>} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName?: string, wiql?: string, fields?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/dev-ops/WorkItems/GetWorkItems`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }

            if (wiql !== undefined) {
                localVarQueryParameter['wiql'] = wiql;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkItemsApi - functional programming interface
 * @export
 */
export const WorkItemsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetAreasGet(projectName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkItemClassificationNode>> {
            const localVarFetchArgs = WorkItemsApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemsGetAreasGet(projectName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [projectNameFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TeamProjectReference>> {
            const localVarFetchArgs = WorkItemsApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [projectName] 
         * @param {string} [wiql] 
         * @param {Array<string>} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName?: string, wiql?: string, fields?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WorkItem>> {
            const localVarFetchArgs = WorkItemsApiFetchParamCreator(configuration).apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName, wiql, fields, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WorkItemsApi - factory interface
 * @export
 */
export const WorkItemsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetAreasGet(projectName?: string, options?: any) {
            return WorkItemsApiFp(configuration).apiServicesDevOpsWorkItemsGetAreasGet(projectName, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [projectNameFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter?: string, options?: any) {
            return WorkItemsApiFp(configuration).apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [projectName] 
         * @param {string} [wiql] 
         * @param {Array<string>} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName?: string, wiql?: string, fields?: Array<string>, options?: any) {
            return WorkItemsApiFp(configuration).apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName, wiql, fields, options)(fetch, basePath);
        },
    };
};

/**
 * WorkItemsApi - object-oriented interface
 * @export
 * @class WorkItemsApi
 * @extends {BaseAPI}
 */
export class WorkItemsApi extends BaseAPI {
    /**
     * 
     * @param {string} [projectName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemsApi
     */
    public apiServicesDevOpsWorkItemsGetAreasGet(projectName?: string, options?: any) {
        return WorkItemsApiFp(this.configuration).apiServicesDevOpsWorkItemsGetAreasGet(projectName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [projectNameFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemsApi
     */
    public apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter?: string, options?: any) {
        return WorkItemsApiFp(this.configuration).apiServicesDevOpsWorkItemsGetProjectsGet(projectNameFilter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [projectName] 
     * @param {string} [wiql] 
     * @param {Array<string>} [fields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkItemsApi
     */
    public apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName?: string, wiql?: string, fields?: Array<string>, options?: any) {
        return WorkItemsApiFp(this.configuration).apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName, wiql, fields, options)(this.fetch, this.basePath);
    }

}
/**
 * ZdiExportApi - fetch parameter creator
 * @export
 */
export const ZdiExportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportGetExchangeStateGet(take?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/ZdiExport/GetExchangeState`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportReleaseReportPost(reportId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/ZdiExport/ReleaseReport`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataExchangeFeedbackInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body?: DataExchangeFeedbackInput, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/ZdiExport/SendDataExchangeFeedback`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DataExchangeFeedbackInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/ZdiExport/UnreleaseReport`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZdiExportApi - functional programming interface
 * @export
 */
export const ZdiExportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportGetExchangeStateGet(take?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DataExchangeStateDtoPagedResultDto> {
            const localVarFetchArgs = ZdiExportApiFetchParamCreator(configuration).apiServicesModulesZdiZdiExportGetExchangeStateGet(take, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportReleaseReportPost(reportId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = ZdiExportApiFetchParamCreator(configuration).apiServicesModulesZdiZdiExportReleaseReportPost(reportId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DataExchangeFeedbackInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body?: DataExchangeFeedbackInput, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ZdiExportApiFetchParamCreator(configuration).apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = ZdiExportApiFetchParamCreator(configuration).apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ZdiExportApi - factory interface
 * @export
 */
export const ZdiExportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportGetExchangeStateGet(take?: number, options?: any) {
            return ZdiExportApiFp(configuration).apiServicesModulesZdiZdiExportGetExchangeStateGet(take, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportReleaseReportPost(reportId?: number, options?: any) {
            return ZdiExportApiFp(configuration).apiServicesModulesZdiZdiExportReleaseReportPost(reportId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DataExchangeFeedbackInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body?: DataExchangeFeedbackInput, options?: any) {
            return ZdiExportApiFp(configuration).apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [reportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId?: number, options?: any) {
            return ZdiExportApiFp(configuration).apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId, options)(fetch, basePath);
        },
    };
};

/**
 * ZdiExportApi - object-oriented interface
 * @export
 * @class ZdiExportApi
 * @extends {BaseAPI}
 */
export class ZdiExportApi extends BaseAPI {
    /**
     * 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZdiExportApi
     */
    public apiServicesModulesZdiZdiExportGetExchangeStateGet(take?: number, options?: any) {
        return ZdiExportApiFp(this.configuration).apiServicesModulesZdiZdiExportGetExchangeStateGet(take, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [reportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZdiExportApi
     */
    public apiServicesModulesZdiZdiExportReleaseReportPost(reportId?: number, options?: any) {
        return ZdiExportApiFp(this.configuration).apiServicesModulesZdiZdiExportReleaseReportPost(reportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DataExchangeFeedbackInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZdiExportApi
     */
    public apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body?: DataExchangeFeedbackInput, options?: any) {
        return ZdiExportApiFp(this.configuration).apiServicesModulesZdiZdiExportSendDataExchangeFeedbackPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [reportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZdiExportApi
     */
    public apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId?: number, options?: any) {
        return ZdiExportApiFp(this.configuration).apiServicesModulesZdiZdiExportUnreleaseReportPost(reportId, options)(this.fetch, this.basePath);
    }

}
/**
 * ZdiFileExportApi - fetch parameter creator
 * @export
 */
export const ZdiFileExportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [protocolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportGetExportFileGet(protocolId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/services/modules/zdi/ZdiExport/GetExportFile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (protocolId !== undefined) {
                localVarQueryParameter['protocolId'] = protocolId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZdiFileExportApi - functional programming interface
 * @export
 */
export const ZdiFileExportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [protocolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportGetExportFileGet(protocolId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ZdiFileExportApiFetchParamCreator(configuration).apiServicesModulesZdiZdiExportGetExportFileGet(protocolId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ZdiFileExportApi - factory interface
 * @export
 */
export const ZdiFileExportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [protocolId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesModulesZdiZdiExportGetExportFileGet(protocolId?: number, options?: any) {
            return ZdiFileExportApiFp(configuration).apiServicesModulesZdiZdiExportGetExportFileGet(protocolId, options)(fetch, basePath);
        },
    };
};

/**
 * ZdiFileExportApi - object-oriented interface
 * @export
 * @class ZdiFileExportApi
 * @extends {BaseAPI}
 */
export class ZdiFileExportApi extends BaseAPI {
    /**
     * 
     * @param {number} [protocolId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZdiFileExportApi
     */
    public apiServicesModulesZdiZdiExportGetExportFileGet(protocolId?: number, options?: any) {
        return ZdiFileExportApiFp(this.configuration).apiServicesModulesZdiZdiExportGetExportFileGet(protocolId, options)(this.fetch, this.basePath);
    }

}
