import React, { useCallback, useMemo } from 'react';
import { PlmDataControlProps, RowSkeletons, isTemplateFilter, selectPlmControlSortProperty } from './components/PlmControlComponents';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import { FIELD_ID, FIELD_IS_EDITABLE, FIELD_IS_PLANNING_ENTITY, FIELD_IS_TEMPLATE, FIELD_JOB_ID, FIELD_JOB_TASK_ID, FIELD_LINE_NUMBER, FIELD_USE_DURATION } from '../../../../data/field-constants/GeneralConstants';
import { TimeEntitiesApi, TimeEntity, TimeEntityDto } from '../../../../client/http';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import filter from '../../../../util/FilterUtils';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import _ from 'lodash';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_EXTENSION_DATA, NEW_ENTITY_ID, getExtensionValue, setExtensionValue } from '../../../../util/EntityUtils';

export type PlmJobPlanningLineListProps = {
    jobId: string;
    jobTaskId: string;
}

export type PlmJobPlanningLineControlProps = PlmDataControlProps & {
    jobId: string;
    jobTaskId: string;
    jobPlanningLine: TimeEntityDto;
}

const spreadLineNumberInNewRow = (jpls: TimeEntity[]) => {

    const lastNo = _.max(jpls.map(j => parseInt(getExtensionValue(j, FIELD_LINE_NUMBER))));
    const result = { [FIELD_EXTENSION_DATA]: '' };
    setExtensionValue(result, FIELD_LINE_NUMBER, !!lastNo ? lastNo + 10000 : 10000);
    return result;
};

const spreadNewPlanningLineData = (jpls: TimeEntity[]) => {

    return {
        [FIELD_USE_DURATION]: true,
        ...spreadLineNumberInNewRow(jpls)
    }
};

const PlmJobPlanningLineControl: React.FC<PlmJobPlanningLineControlProps> = ({ jobId, jobTaskId, jobPlanningLine, refetch }) => {

    const { showTemplates } = usePlmControlContext();

    const [internalJobPlanningLine, , handleChange, handleAction] = useInternalPlmControlData<TimeEntityDto, TimeEntitiesApi>(
        jobPlanningLine,
        {
            [FIELD_JOB_ID]: jobId,
            [FIELD_JOB_TASK_ID]: jobTaskId,
            [FIELD_IS_EDITABLE]: true,
            [FIELD_IS_PLANNING_ENTITY]: true,
            [FIELD_IS_TEMPLATE]: showTemplates === true
        },
        'jobPlanningLines',
        c => new TimeEntitiesApi(c),
        c => c.apiServicesAppTimeEntitiesCreateOrUpdateEntityPost,
        c => c.apiServicesAppTimeEntitiesCreateOrUpdateEntityPost,
        c => c.apiServicesAppTimeEntitiesDeleteDelete,
        refetch
    );

    return <PlmColumnDataDisplay item={internalJobPlanningLine} section='jobPlanningLines' level={3} className="plm-job-type-line-item"
        onChange={handleChange} onAction={handleAction} />
}

const PlmJobPlanningLineList: React.FC<PlmJobPlanningLineListProps> = ({ jobId, jobTaskId }) => {

    const { controlState, readOnly, showTemplates, fetchFromAllProviders } = usePlmControlContext();

    const internalJobTaskPlanningLinesControlState = controlState?.jobPlanningLines?.filter?.internalFilters;
    const internalJobPlanningLineFilters = useMemo(() => [
        ...internalJobTaskPlanningLinesControlState,
        ...isTemplateFilter(showTemplates),
        filter(FIELD_IS_PLANNING_ENTITY).equals(true),
        filter(FIELD_IS_EDITABLE).equals(true),
        filter(FIELD_JOB_TASK_ID).equals(jobTaskId)
    ], [internalJobTaskPlanningLinesControlState, showTemplates, jobTaskId]);

    const externalJobPlanningLineFilters = controlState?.jobPlanningLines?.filter?.externalFilters;

    const getAll = useCallback((c: TimeEntitiesApi) => {
        return c.apiServicesAppTimeEntitiesGetAllEntitiesGet(
            fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
            undefined,
            undefined,
            JSON.stringify(internalJobPlanningLineFilters),
            JSON.stringify(externalJobPlanningLineFilters)
        )
    }, [fetchFromAllProviders, internalJobPlanningLineFilters, externalJobPlanningLineFilters]);

    const [jobPlanningLines, fetchJobPlanningLines, addRow, isLoading] = useInternalPlmListData<TimeEntityDto, TimeEntitiesApi>(
        c => new TimeEntitiesApi(c),
        getAll,
        'jobPlanningLines',
        spreadNewPlanningLineData
    );

    return <>

        <PlmColumnHeaderDisplay section='jobPlanningLines' level={2} onAction={addRow} readOnly={readOnly || jobId === NEW_ENTITY_ID || jobTaskId === NEW_ENTITY_ID}
            hasNoData={!isLoading && !jobPlanningLines?.length} noDataLabel="Keine Planzeilen vorhanden" />

        {jobPlanningLines?.map((jpl) => {
            return (
                <PlmJobPlanningLineControl jobId={jobId} jobTaskId={jobTaskId} jobPlanningLine={jpl} refetch={fetchJobPlanningLines} key={getIn(jpl, FIELD_ID)} />
            )
        })}

        {isLoading && <RowSkeletons level={2} />}
    </>
}

export default PlmJobPlanningLineList;