import React, { useMemo, useCallback } from 'react';
import { Grid, GridColumn, GridCustomCellProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { FileSystemEntry, SortState } from '../FileExplorerDialogTypes';
import { Error } from '@progress/kendo-react-labels';
import LoadingIndicator from '../../loading-indicator/LoadingIndicator';
import Icon from '../../icon/Icon';

interface FileSystemGridProps {
    entries: FileSystemEntry[];
    sortState: SortState;
    onSortChange: (state: SortState) => void;
    onSelect: (path: string | null) => void;
    onPreview: (file: FileSystemEntry) => void;
    onNavigate: (path: string) => void;
    loading: boolean;
    error: string | null;
    selectable?: 'directories' | 'files' | 'filesAndDirectories';
}

// Format size
const formatSize = (bytes: number | null): string => {
    if (bytes === null) return '-';
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(1)} ${units[unitIndex]}`;
};

const ItemTypeCell = (props: GridCustomCellProps) => {
    return <td {...props.tdProps} colSpan={1} style={{}}>
        {props.children === "true" ?
            <Icon name="icon-folder-invoices-3" size="24px" /> :
            <Icon name="icon-regular-file" size="24px" />
        }
    </td>;
};

export const FileSystemGrid: React.FC<FileSystemGridProps> = ({
    entries,
    sortState,
    onSortChange,
    onSelect,
    onPreview,
    onNavigate,
    loading,
    error
}) => {
    // Memoized cell templates
    const nameCell = useCallback((props: any) => {
        const entry: FileSystemEntry = props.dataItem;
        return (
            <td>
                <span className="k-icon k-i-{entry.isDirectory ? 'folder' : 'file'}" />
                <span style={{ marginLeft: '8px' }}>{entry.name}</span>
            </td>
        );
    }, []);

    const sizeCell = useCallback((props: any) => {
        const entry: FileSystemEntry = props.dataItem;
        if (entry.isDirectory) return <td>-</td>;

        return <td>{formatSize(entry.size)}</td>;
    }, []);

    const dateCell = useCallback((props: any) => {
        const entry: FileSystemEntry = props.dataItem;
        return <td>{`${entry.date} ${entry.time}`}</td>;
    }, []);

    // Handle sort changes
    const handleSortChange = (event: GridSortChangeEvent) => {
        let field: SortState['field'];
        let direction: SortState['direction'];

        if (!event.sort || event.sort.length === 0) {
            field = sortState.field;
            direction = sortState.direction === 'asc' ? 'desc' : 'asc';
        } else {
            field = event.sort[0].field as SortState['field'];
            direction = event.sort[0].dir === 'asc' ? 'asc' : 'desc';
        }
        onSortChange({ field, direction });
    };

    // Handle double click
    const handleDoubleClick = (event: any) => {
        const entry: FileSystemEntry = event.dataItem;
        if (entry.isDirectory) {
            onSelect(entry.path);
        } else {
            onSelect(entry.path);
        }
    };

    // Handle single click
    const handleClick = (event: any) => {
        const entry: FileSystemEntry = event.dataItem;
        if (!entry.isDirectory) {
            onPreview(entry);
        } else {
            onNavigate(entry.name);
        }
    };

    if (error) {
        return (
            <Error style={{ padding: '16px' }}>
                {error}
            </Error>
        );
    }

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <Grid
                data={entries}
                sort={[{
                    field: sortState.field,
                    dir: sortState.direction
                }]}
                onSortChange={handleSortChange}
                onRowDoubleClick={handleDoubleClick}
                onRowClick={handleClick}
                sortable={true}
                reorderable={false}
                fixedScroll={true}
                resizable={true}
                scrollable="scrollable"
                style={{ height: '100%' }}
            >
                <GridColumn
                    field="isDirectory"
                    title="&nbsp;"
                    filterable={false}
                    sortable={false}
                    editable={false}
                    cells={{
                        data: ItemTypeCell
                    }}
                    width="40px"
                />
                <GridColumn
                    field="name"
                    title="Name"
                    cell={nameCell}
                    width="450px"
                />
                <GridColumn
                    field="size"
                    title="Size"
                    cell={sizeCell}
                    width="100px"
                />
                <GridColumn
                    field="date"
                    title="Modified"
                    cell={dateCell}
                    width="150px"
                />
            </Grid>
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(255,255,255,0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoadingIndicator loadingIndicatorType='spinner' />
                </div>
            )}
        </div>
    );
};