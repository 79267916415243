import React, { useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';
import { FileSystemEntry } from '../FileExplorerDialogTypes';
import LoadingIndicator from '../../loading-indicator/LoadingIndicator';
import IconButton from '../../icon/IconButton';
import { L } from '../../../../abp/utils';

interface FilePreviewPaneProps {
    file: FileSystemEntry | null;
    onClose: () => void;
}

export const FilePreviewPane: React.FC<FilePreviewPaneProps> = ({
    file,
    onClose
}) => {
    const [preview, setPreview] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadPreview = async () => {
            if (!file) {
                setPreview(null);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const extension = file.name.split('.').pop()?.toLowerCase();

                // Handle different file types
                if (['jpg', 'jpeg', 'png', 'gif'].includes(extension || '')) {
                    const response = await fetch(file.path);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    setPreview(url);
                } else if (['txt', 'json', 'xml', 'md'].includes(extension || '')) {
                    const response = await fetch(file.path);
                    const text = await response.text();
                    setPreview(text);
                } else if (['pdf', 'html', 'svg', 'eps'].includes(extension || '')) {
                    setPreview(file.path);
                }
                else {
                    setError('Preview not available for this file type');
                }
            } catch (err) {
                setError('Failed to load preview');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        loadPreview();

        // Cleanup
        return () => {
            if (preview && preview.startsWith('blob:')) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [file, preview]);

    if (!file) {
        return (
            <div className="preview-pane preview-pane--empty">
                Select a file to preview
            </div>
        );
    }

    return (
        <div className="preview-pane">
            <div className="preview-pane-header">
                <h3>{file.name}</h3>
                <IconButton name="icon-cancel" onClick={onClose} title={L('Cancel')}/>
            </div>
            <div className="preview-pane-content">
                {loading && <LoadingIndicator loadingIndicatorType='spinner' />}
                {error && <Error>{error}</Error>}
                {(preview && !error && !loading) && (
                    preview.startsWith('blob:') ? (
                        <img
                            src={preview}
                            alt={file.name}
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    ) :
                        (preview.startsWith('http') ?
                            <iframe width="100%" height="100%" src={preview} title="Preview" /> :
                            (
                                <pre style={{
                                    whiteSpace: 'pre-wrap',
                                    overflow: 'auto',
                                    maxHeight: '100%'
                                }}>
                                    {preview}
                                </pre>
                            )
                        )

                )}
            </div>
        </div>
    );
};